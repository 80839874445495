import auth from './amplify';

/**
 * Check to see if credentials are valid and have not expired.
 * @param credentials
 * @returns {boolean}
 */
const isValidCredentials = (credentials) => {
  const credentialsExpired =
    !credentials?.expiration || new Date(credentials?.expiration)?.getTime() <= new Date().getTime();
  return !credentialsExpired && credentials?.accessKeyId && credentials?.secretAccessKey;
};

/**
 * Fetch the user credentials from local storage and refresh when expired.
 * When valid credentials are no longer present it will redirect the user to
 * the logout page.
 * @returns {Promise<any>}
 */
const currentCredentials = async () => {
  let credentials = JSON.parse(localStorage.getItem('auth') ?? '{}');
  if (!isValidCredentials(credentials)) {
    // Refresh expired credentials or returns an exception
    credentials = await auth.currentUserCredentials();
    // Redirect the user to logout when valid credentials have not been returned
    if (!isValidCredentials(credentials)) {
      location.href = '/auth/logged-out';
      throw new Error('Not authenticated during token refresh.');
    }
    // Store new credentials
    localStorage.setItem('auth', JSON.stringify(credentials));
  }
  return credentials;
};

export default currentCredentials;
