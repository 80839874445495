import * as mutations from '@/store/leaseholdSafety/mutations';

export const LEASEHOLD_SAFETY_UPDATE = 'leaseholdSafety/update';

export const updateLeaseholdSafety = (commit, leaseholdSafetyData) =>
  new Promise((resolve) => {
    if (leaseholdSafetyData) {
      commit(mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS, leaseholdSafetyData.remediation_works);
      commit(mutations.LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY, leaseholdSafetyData.remediation_works_copy);
      commit(mutations.LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE, leaseholdSafetyData.qualifying_lease);
      commit(mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE, leaseholdSafetyData.deed_of_certificate);
      commit(
        mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION,
        leaseholdSafetyData.deed_of_certificate_completion,
      );
      commit(mutations.LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY, leaseholdSafetyData.deed_of_certificate_copy);
      commit(mutations.LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION, leaseholdSafetyData.notified_of_sell_intention);
      commit(
        mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED,
        leaseholdSafetyData.landlords_certificate_received,
      );
      commit(mutations.LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY, leaseholdSafetyData.landlords_certificate_copy);
    }

    resolve();
  });
