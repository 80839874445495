export const LEASEHOLD_NOTICES_PREFIX = 'building/leaseholdNotices/';

export const LEASEHOLD_NOTICE_SET_SALE_NOTICE = 'LEASEHOLD_NOTICE_SET_SALE_NOTICE';
export const LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY = 'LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY';
export const LEASEHOLD_NOTICE_SET_GENERAL_NOTICE = 'LEASEHOLD_NOTICE_SET_GENERAL_NOTICE';
export const LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY = 'LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY';
export const LEASEHOLD_NOTICE_LOADED = 'LEASEHOLD_NOTICE_LOADED';

export const mutations = {
  [LEASEHOLD_NOTICE_SET_SALE_NOTICE](state, value) {
    state.saleNotice = value;
  },
  [LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY](state, value) {
    state.saleNoticeCopy = value;
  },
  [LEASEHOLD_NOTICE_SET_GENERAL_NOTICE](state, value) {
    state.generalNotice = value;
  },
  [LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY](state, value) {
    state.generalNoticeCopy = value;
  },
  [LEASEHOLD_NOTICE_LOADED](state) {
    state.loaded = true;
  },
};
