export const CONSENTS_PREFIX = 'building/consents/';
export const CONSENTS_SET_LEASE_TERMS_CHANGE = 'CONSENTS_SET_LEASE_TERMS_CHANGE';
export const CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT = 'CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT';
export const CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION = 'CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION';
export const CONSENTS_LOADED = 'CONSENTS_LOADED';

export const mutations = {
  [CONSENTS_SET_LEASE_TERMS_CHANGE](state, value) {
    state.leaseTermsChange = value;
  },
  [CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT](state, value) {
    state.leaseTermsChangeDocument = value;
  },
  [CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION](state, value) {
    state.leaseTermsChangeDescription = value;
  },
  [CONSENTS_LOADED](state) {
    state.loaded = true;
  },
};
