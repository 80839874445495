export const PARKING_DETAILS_PREFIX = 'building/parkingDetails/';
export const PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION = 'PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION';
export const PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE = 'PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE';
export const PARKING_DETAILS_LOADED = 'PARKING_DETAILS_LOADED';

export const mutations = {
  [PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION](state, value) {
    state.arrangementsDescription = value;
  },
  [PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE](state, value) {
    state.controlledParkingZone = value;
  },
  [PARKING_DETAILS_LOADED](state) {
    state.loaded = true;
  },
};
