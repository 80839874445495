import * as mutations from '@/store/otherCharges/mutations';

export const OTHER_CHARGES_UPDATE = 'otherCharges/update';

export const updateOtherCharges = (commit, otherChargesData) =>
  new Promise((resolve) => {
    if (otherChargesData) {
      commit(mutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES, otherChargesData.property_related_charges);
      commit(
        mutations.OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION,
        otherChargesData.property_related_charges_details,
      );
    }

    resolve();
  });
