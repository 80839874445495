export const ENFRANCHISEMENT_PREFIX = 'building/enfranchisement/';

export const ENFRANCHISEMENT_SET_OWNED_TWO_YEARS = 'ENFRANCHISEMENT_SET_OWNED_TWO_YEARS';
export const ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE = 'ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE';
export const ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY = 'ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY';
export const ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE = 'ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE';
export const ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY =
  'ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY';
export const ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES = 'ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES';
export const ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY = 'ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY';
export const ENFRANCHISEMENT_LOADED = 'enfranchisement_loaded';

export const mutations = {
  [ENFRANCHISEMENT_SET_OWNED_TWO_YEARS](state, value) {
    state.ownedTwoYears = value;
  },
  [ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE](state, value) {
    state.sellerPurchaseNotice = value;
  },
  [ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY](state, value) {
    state.sellerPurchaseNoticeCopy = value;
  },
  [ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE](state, value) {
    state.collectivePurchaseNotice = value;
  },
  [ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY](state, value) {
    state.collectivePurchaseNoticeCopy = value;
  },
  [ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES](state, value) {
    state.purchaseNoticeResponses = value;
  },
  [ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY](state, value) {
    state.purchaseNoticeResponsesCopy = value;
  },
  [ENFRANCHISEMENT_LOADED](state) {
    state.loaded = true;
  },
};
