export const RIGHTS_PREFIX = 'building/rights/';
export const RIGHTS_SET_SHARED_SERVICE_MAINTENANCE = 'RIGHTS_SET_SHARED_SERVICE_MAINTENANCE';
export const RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION = 'RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION';
export const RIGHTS_SET_NEIGHBOURING_RIGHTS = 'RIGHTS_SET_NEIGHBOURING_RIGHTS';
export const RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION = 'RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION';
export const RIGHTS_SET_ACCESS_PREVENTION = 'RIGHTS_SET_ACCESS_PREVENTION';
export const RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION = 'RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION';
export const RIGHTS_SET_RIGHTS_OF_LIGHT = 'RIGHTS_SET_RIGHTS_OF_LIGHT';
export const RIGHTS_SET_ADJOINING_PROPERTIES = 'RIGHTS_SET_ADJOINING_PROPERTIES';
export const RIGHTS_SET_CUSTOMARY_RIGHTS = 'RIGHTS_SET_CUSTOMARY_RIGHTS';
export const RIGHTS_SET_MINES_AND_MINERALS = 'RIGHTS_SET_MINES_AND_MINERALS';
export const RIGHTS_SET_CHANCEL_REPAIR_LIABILITY = 'RIGHTS_SET_CHANCEL_REPAIR_LIABILITY';
export const RIGHTS_SET_RESOURCE_RIGHTS = 'RIGHTS_SET_RESOURCE_RIGHTS';
export const RIGHTS_SET_ARRANGEMENTS_DESCRIPTION = 'RIGHTS_SET_ARRANGEMENTS_DESCRIPTION';
export const RIGHTS_SET_OTHER_ARRANGEMENTS = 'RIGHTS_SET_OTHER_ARRANGEMENTS';
export const RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION = 'RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION';
export const RIGHTS_SET_PIPES_CROSS_NEIGHBOURS = 'RIGHTS_SET_PIPES_CROSS_NEIGHBOURS';
export const RIGHTS_SET_NEIGHBOURS_PIPES_CROSS = 'RIGHTS_SET_NEIGHBOURS_PIPES_CROSS';
export const RIGHTS_SET_PIPES_AGREEMENT = 'RIGHTS_SET_PIPES_AGREEMENT';
export const RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION = 'RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION';
export const RIGHTS_SET_PIPES_AGREEMENT_COPY = 'RIGHTS_SET_PIPES_AGREEMENT_COPY';
export const RIGHTS_LOADED = 'RIGHTS_LOADED';

export const mutations = {
  [RIGHTS_SET_SHARED_SERVICE_MAINTENANCE](state, val) {
    state.sharedServiceMaintenance = val;
  },
  [RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION](state, val) {
    state.sharedServiceMaintenanceDescription = val;
  },
  [RIGHTS_SET_NEIGHBOURING_RIGHTS](state, val) {
    state.neighbouringRights = val;
  },
  [RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION](state, val) {
    state.neighbouringRightsDescription = val;
  },
  [RIGHTS_SET_ACCESS_PREVENTION](state, val) {
    state.accessPrevention = val;
  },
  [RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION](state, val) {
    state.accessPreventionDescription = val;
  },
  [RIGHTS_SET_RIGHTS_OF_LIGHT](state, val) {
    state.rightsOfLight = val;
  },
  [RIGHTS_SET_ADJOINING_PROPERTIES](state, val) {
    state.adjoiningProperties = val;
  },
  [RIGHTS_SET_CUSTOMARY_RIGHTS](state, val) {
    state.customaryRights = val;
  },
  [RIGHTS_SET_MINES_AND_MINERALS](state, val) {
    state.minesAndMinerals = val;
  },
  [RIGHTS_SET_CHANCEL_REPAIR_LIABILITY](state, val) {
    state.chancelRepairLiability = val;
  },
  [RIGHTS_SET_RESOURCE_RIGHTS](state, val) {
    state.resourceRights = val;
  },
  [RIGHTS_SET_ARRANGEMENTS_DESCRIPTION](state, val) {
    state.arrangementsDescription = val;
  },
  [RIGHTS_SET_OTHER_ARRANGEMENTS](state, val) {
    state.otherArrangements = val;
  },
  [RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION](state, val) {
    state.otherArrangementsDescription = val;
  },
  [RIGHTS_SET_PIPES_CROSS_NEIGHBOURS](state, val) {
    state.pipesCrossNeighbours = val;
  },
  [RIGHTS_SET_NEIGHBOURS_PIPES_CROSS](state, val) {
    state.neighboursPipesCross = val;
  },
  [RIGHTS_SET_PIPES_AGREEMENT](state, val) {
    state.pipesAgreement = val;
  },
  [RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION](state, val) {
    state.pipesAgreementDescription = val;
  },
  [RIGHTS_SET_PIPES_AGREEMENT_COPY](state, val) {
    state.pipesAgreementCopy = val;
  },
  [RIGHTS_LOADED](state) {
    state.loaded = true;
  },
};
