export const DOCUMENT_ADD = 'DOCUMENT_ADD';
export const DOCUMENT_REMOVE = 'DOCUMENT_REMOVE';
export const DOCUMENT_CLEAR_ALL = 'DOCUMENT_CLEAR_ALL';
export const DOCUMENT_SET_LOADING_STATUS = 'DOCUMENT_SET_LOADING_STATUS';
export const DOCUMENT_SET_SHOW_ERROR = 'DOCUMENT_SET_SHOW_ERROR';

export const mutations = {
  [DOCUMENT_ADD](state, value) {
    state.documents.push(value);
  },
  [DOCUMENT_REMOVE](state, documentId) {
    state.documents = state.documents.filter((document) => document.id !== documentId);
  },
  [DOCUMENT_CLEAR_ALL](state) {
    state.documents = [];
  },
  [DOCUMENT_SET_LOADING_STATUS](state, value) {
    state.loading = value;
  },
  [DOCUMENT_SET_SHOW_ERROR](state, value) {
    state.showError = value;
  },
};
