import * as mutations from '@/store/payment/mutations';

export const PAYMENT_UPDATE = 'payment/update';

export const updatePayments = (commit, paymentData) =>
  new Promise((resolve) => {
    if (paymentData) {
      commit(mutations.PAYMENTS_SET_CUSTOMER_FIRST_NAME, paymentData.customerFirstName);
      commit(mutations.PAYMENTS_SET_CUSTOMER_LAST_NAME, paymentData.customerLastName);
      commit(mutations.PAYMENTS_SET_BILLING_ADDRESS.ADDRESS1, paymentData.address1);
      commit(mutations.PAYMENTS_SET_BILLING_ADDRESS.CITY, paymentData.city);
      commit(mutations.PAYMENTS_SET_BILLING_ADDRESS.POSTAL_CODE, paymentData.postalCode);
    }

    resolve();
  });
