export const TRANSACTION_INFORMATION_PREFIX = 'building/transactionInformation/';
export const TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION = 'TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION';
export const TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS =
  'TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS';
export const TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION =
  'TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION';
export const TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES =
  'TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES';
export const TRANSACTION_INFORMATION_SET_BUILDING_TIDY = 'TRANSACTION_INFORMATION_SET_BUILDING_TIDY';
export const TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED =
  'TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED';
export const TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE = 'TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE';
export const TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING =
  'TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING';
export const TRANSACTION_INFORMATION_LOADED = 'TRANSACTION_INFORMATION_LOADED';

export const mutations = {
  [TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION](state, value) {
    state.sameDayCompletion = value;
  },
  [TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS](state, value) {
    state.sellerMovingDateRequirements = value;
  },
  [TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION](state, value) {
    state.sellerMovingDateRequirementsDescription = value;
  },
  [TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES](state, value) {
    state.salePriceExceedsMortgageCharges = value;
  },
  [TRANSACTION_INFORMATION_SET_BUILDING_TIDY](state, value) {
    state.buildingTidy = value;
  },
  [TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED](state, value) {
    state.emptyLightFittingsReplaced = value;
  },
  [TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE](state, value) {
    state.fittingRemovalCare = value;
  },
  [TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING](state, value) {
    state.securityDetailsProvidedAtBuilding = value;
  },
  [TRANSACTION_INFORMATION_LOADED](state) {
    state.loaded = true;
  },
};
