import * as mutations from '@/store/complaints/mutations';
import { updateComplaints } from '@/store/complaints/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    landlordComplaint: null,
    landlordComplaintDescription: null,
    sellerComplaint: null,
    sellerComplaintDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, complaintsData) => updateComplaints(commit, complaintsData),
  },
};
