import * as mutations from '@/store/maintenance/mutations';
import { updateMaintenance } from '@/store/maintenance/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    buildingInsurance: null,
    externalDecorated: null,
    internalDecorated: null,
    sellerMaintenanceContribution: null,
    decorationExpenses: null,
    decorationExpensesDescription: null,
    managementProblems: null,
    managementProblemsDescription: null,
    buildingSafetyRiskExistence: null,
    buildingSafetyRiskExistenceDescription: null,
    serviceChargeChallenge: null,
    serviceChargeChallengeDescription: null,
    serviceChargeCollectionDifficulties: null,
    serviceChargeCollectionDifficultiesDescription: null,
    serviceChargeOwing: null,
    serviceChargeOwingDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, maintenanceData) => updateMaintenance(commit, maintenanceData),
  },
};
