import * as mutations from '@/store/persons/mutations';
import {
  fetchSaleClients,
  fetchPurchaseClients,
  fetchRemortgageClients,
  fetchAdditionalToePersons,
  fetchRemovedToePersons,
  fetchOccupiers,
  fetchFirmPersons,
} from '@/store/persons/actions';
import interceptorResponse from '@/functions/interceptorResponse';

interceptorResponse();
export default {
  namespaced: true,
  state: {
    clients: [],
    occupiers: [],
    additionalToePersons: [],
    removedToePersons: [],
    firmPersons: [],
    clientDetailsUpdated: 0,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetchSaleClients: ({ commit }, clientIds) => fetchSaleClients(commit, clientIds),
    fetchPurchaseClients: ({ commit }, clientIds) => fetchPurchaseClients(commit, clientIds),
    fetchRemortgageClients: ({ commit }, clientIds) => fetchRemortgageClients(commit, clientIds),
    fetchAdditionalToePersons: ({ commit }, clientIds) => fetchAdditionalToePersons(commit, clientIds),
    fetchRemovedToePersons: ({ commit }, clientIds) => fetchRemovedToePersons(commit, clientIds),
    fetchOccupiers: ({ commit }, clientIds) => fetchOccupiers(commit, clientIds),
    fetchFirmPersons: ({ commit }, personIds) => fetchFirmPersons(commit, personIds),
  },
};
