export const MAINTENANCE_PREFIX = 'building/maintenance/';

export const MAINTENANCE_SET_BUILDING_INSURANCE = 'MAINTENANCE_SET_BUILDING_INSURANCE';
export const MAINTENANCE_SET_EXTERNAL_DECORATED = 'MAINTENANCE_SET_EXTERNAL_DECORATED';
export const MAINTENANCE_SET_INTERNAL_DECORATED = 'MAINTENANCE_SET_INTERNAL_DECORATED';
export const MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION = 'MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION';
export const MAINTENANCE_SET_DECORATION_EXPENSES = 'MAINTENANCE_SET_DECORATION_EXPENSES';
export const MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION = 'MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION';
export const MAINTENANCE_SET_MANAGEMENT_PROBLEMS = 'MAINTENANCE_SET_MANAGEMENT_PROBLEMS';
export const MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION = 'MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION';
export const MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE = 'MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE';
export const MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION =
  'MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION';
export const MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE = 'MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE';
export const MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION =
  'MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION';
export const MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES =
  'MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES';
export const MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION =
  'MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION';
export const MAINTENANCE_SET_SERVICE_CHARGE_OWING = 'MAINTENANCE_SET_SERVICE_CHARGE_OWING';
export const MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION = 'MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION';
export const MAINTENANCE_LOADED = 'MAINTENANCE_LOADED';

export const mutations = {
  [MAINTENANCE_SET_BUILDING_INSURANCE](state, value) {
    state.buildingInsurance = value;
  },
  [MAINTENANCE_SET_EXTERNAL_DECORATED](state, value) {
    state.externalDecorated = value;
  },
  [MAINTENANCE_SET_INTERNAL_DECORATED](state, value) {
    state.internalDecorated = value;
  },
  [MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION](state, value) {
    state.sellerMaintenanceContribution = value;
  },
  [MAINTENANCE_SET_DECORATION_EXPENSES](state, value) {
    state.decorationExpenses = value;
  },
  [MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION](state, value) {
    state.decorationExpensesDescription = value;
  },
  [MAINTENANCE_SET_MANAGEMENT_PROBLEMS](state, value) {
    state.managementProblems = value;
  },
  [MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION](state, value) {
    state.managementProblemsDescription = value;
  },
  [MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE](state, value) {
    state.buildingSafetyRiskExistence = value;
  },
  [MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION](state, value) {
    state.buildingSafetyRiskExistenceDescription = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE](state, value) {
    state.serviceChargeChallenge = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION](state, value) {
    state.serviceChargeChallengeDescription = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES](state, value) {
    state.serviceChargeCollectionDifficulties = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION](state, value) {
    state.serviceChargeCollectionDifficultiesDescription = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_OWING](state, value) {
    state.serviceChargeOwing = value;
  },
  [MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION](state, value) {
    state.serviceChargeOwingDescription = value;
  },
  [MAINTENANCE_LOADED](state) {
    state.loaded = true;
  },
};
