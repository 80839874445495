<script>
export default {
  name: 'HoldingPage',
};
</script>

<template>
  <div class="page-content">
    <!-- Main content -->
    <div class="content-wrapper">
      <!-- Content area -->
      <div class="content d-flex justify-content-center align-items-center">
        <!-- Password recovery form -->
        <div class="card mb-0 col-8">
          <div class="card-body">
            <h1>Undergoing Maintenance</h1>
            <p>We're currently updating our systems - please check back shortly.</p>
          </div>
        </div>
        <!-- /password recovery form -->
      </div>
      <!-- /content area -->
    </div>
  </div>
</template>
