import store from '@/store';

const checkAdmin = (permission) => async (to, from, next) => {
  if (typeof permission === 'string' && store.state.auth.permissions.includes(permission)) {
    next();
  } else if (store.state.auth.permissions.find((v) => permission.test(v)) !== undefined) {
    next();
  } else {
    next('/');
  }
};

export default checkAdmin;
