import getPurchase from '@/api/purchases/getPurchase';
import * as mutations from '@/store/purchase/mutations';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';

export const PURCHASE_FETCH = 'purchase/fetch';

export const refreshTrackingEvents = async ({ commit }, id) => {
  const trackingEvents = (await getTrackingEvents(id, [])).data;
  if (trackingEvents?.results) {
    commit(mutations.PURCHASE_SET_TRACKING_EVENTS, trackingEvents?.results);
  }
};

export const setTrackingEvents = (commit, trackingEvents) => {
  commit(mutations.PURCHASE_SET_TRACKING_EVENTS, trackingEvents);
};

export const addTrackingEvent = (commit, trackingEvent) => {
  commit(mutations.PURCHASE_ADD_TRACKING_EVENT, trackingEvent);
};

export const setEventToHandle = (commit, eventToHandle) => {
  commit(mutations.PURCHASE_SET_EVENT_TO_HANDLE, eventToHandle);
};

export const fetchFromApi = (commit, purchaseId) =>
  new Promise((resolve) => {
    getPurchase(purchaseId)
      .then(async (response) => {
        commit(mutations.PURCHASE_SET_PURCHASE_ID, response.data.purchase_id);
        commit(mutations.PURCHASE_SET_AUTHORITY_PURCHASE, response.data.authority_purchase);
        commit(mutations.PURCHASE_SET_AUTHORITY_PURCHASE_DETAILS, response.data.authority_purchase_details);
        commit(mutations.PURCHASE_SET_PROPERTY_TYPE, response.data.property_type);
        commit(mutations.PURCHASE_SET_PROPERTY_TYPE_OTHER, response.data.property_type_other);
        commit(mutations.PURCHASE_SET_PURCHASE_TYPE, response.data.purchase_type);
        commit(mutations.PURCHASE_SET_TYPE_OF_PURCHASE, response.data.type_of_purchase);
        commit(mutations.PURCHASE_SET_BUYER_NAME, response.data.buyer_name);
        commit(mutations.PURCHASE_SET_PRICE, Number(response.data.purchase_price));
        commit(mutations.PURCHASE_SET_TYPE_OF_TENANCY, response.data.type_of_tenancy);
        commit(mutations.PURCHASE_SET_TYPE_OF_TENANCY_DETAILS, response.data.type_of_tenancy_details);
        commit(mutations.PURCHASE_SET_INTERIM_ADDRESS, response.data.interim_address);
        commit(mutations.PURCHASE_SET_INTERIM_PHONE, response.data.interim_phone);
        commit(mutations.PURCHASE_SET_ESTATE_AGENT_ID, response.data.estate_agent_id);
        commit(mutations.PURCHASE_SET_FIXTURE_PRICE, Number(response.data.fixture_price));
        commit(mutations.PURCHASE_SET_AGENT_FEE, response.data.agent_fee);
        commit(mutations.PURCHASE_SET_AGENT_FEE_TYPE, response.data.agent_fee_type);
        commit(mutations.PURCHASE_SET_AGENT_FEE_PAYMENT_PERMISSION, response.data.agent_fee_payment_permission);
        commit(mutations.PURCHASE_SET_RELATED_CASE, response.data.related_case);
        commit(mutations.PURCHASE_SET_RELATED_CASES, response.data.related_cases || []);
        commit(mutations.PURCHASE_SET_SPECIAL_PURCHASE, response.data.special_purchase);
        commit(mutations.PURCHASE_SET_SPECIAL_PURCHASE_DETAILS, response.data.special_purchase_details);
        commit(mutations.PURCHASE_SET_MORTGAGES, response.data.mortgages || []);
        commit(mutations.PURCHASE_SET_TITLE_NUMBERS, response.data.title_numbers);
        commit(mutations.PURCHASE_SET_DESIRED_COMPLETION_DATE, response.data.desired_completion_date);
        commit(mutations.PURCHASE_SET_EXCHANGE_DEADLINE, response.data.exchange_deadline);
        commit(mutations.PURCHASE_SET_COMPLETION_DEADLINE, response.data.completion_deadline);
        commit(mutations.PURCHASE_SET_SURPLUS_PAYMENT_METHOD, response.data.surplus_payment_method);
        commit(mutations.PURCHASE_SET_BUILDING_ID, response.data.building_id);
        commit(mutations.PURCHASE_SET_SORT_REFER_ID, response.data.sort_refer_id);
        commit(mutations.PURCHASE_SET_SUPPLIER_INFORMATION, response.data.supplier_information);
        commit(mutations.PURCHASE_SET_SAGEPAY_TRANSACTION_ID, response.data.sagepay_transaction_id);
        commit(mutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS, response.data.structural_alterations);
        commit(mutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS, response.data.structural_alterations_details);
        commit(
          mutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR,
          response.data.structural_alterations_within_ten_yr,
        );
        commit(mutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK, response.data.further_points_to_check);
        commit(mutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS, response.data.further_points_to_check_details);
        commit(mutations.PURCHASE_SET_BROKER_OR_IFA, response.data.broker_or_ifa);
        commit(mutations.PURCHASE_SET_BROKER_OR_IFA_DETAILS, response.data.broker_or_ifa_details);
        commit(mutations.PURCHASE_SET_SOURCE_OF_FUNDS, response.data.source_of_funds || []);
        commit(mutations.PURCHASE_SET_BUILDING_INSURER, response.data.building_insurer);
        commit(mutations.PURCHASE_SET_SOLD_BY_ESTATE_AGENT, response.data.sold_by_estate_agent);
        commit(mutations.PURCHASE_SET_SOLICITOR_CONTACT_DETAILS, response.data.solicitor_contact_details);
        commit(mutations.PURCHASE_SET_CONFIG, response.data.config);
        commit(mutations.PURCHASE_SET_PASS_STATEMENTS_TO_BROKER, response.data.pass_statements_to_broker);
        commit(mutations.PURCHASE_SET_ADDITIONAL_INFO, response.data.additional_info);

        if (response.data.hasOwnProperty('bank_details') && response.data.bank_details) {
          const bank_details = response.data.bank_details;
          commit(mutations.PURCHASE_SET_BANK_NAME, bank_details.bank_name);
          commit(mutations.PURCHASE_SET_BANK_ACCOUNT_NAME, bank_details.account_name);
          commit(mutations.PURCHASE_SET_BANK_ACCOUNT_NUMBER, bank_details.account_number);
          commit(mutations.PURCHASE_SET_BANK_BACS_OR_CHAPS, bank_details.bacs_or_chaps);
          commit(mutations.PURCHASE_SET_BANK_SORT_CODE, bank_details.sort_code);
        } else {
          commit(mutations.PURCHASE_RESET_BANK_DETAILS);
        }

        const clientIds = response.data.client_ids || [];
        commit(mutations.PURCHASE_SET_CLIENT_IDS, clientIds);

        const occupierIds = response.data.occupier_ids || [];
        commit(mutations.PURCHASE_SET_OCCUPIER_IDS, occupierIds);

        commit(mutations.PURCHASE_LOADED);

        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
