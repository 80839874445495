import * as mutations from '@/store/rights/mutations';

export const RIGHTS_UPDATE = 'rights/update';

export const updateRights = (commit, rightsData) =>
  new Promise((resolve) => {
    if (rightsData) {
      commit(mutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE, rightsData.shared_service_maintenance);
      commit(
        mutations.RIGHTS_SET_SHARED_SERVICE_MAINTENANCE_DESCRIPTION,
        rightsData.shared_service_maintenance_description,
      );
      commit(mutations.RIGHTS_SET_NEIGHBOURING_RIGHTS, rightsData.neighbouring_rights);
      commit(mutations.RIGHTS_SET_NEIGHBOURING_RIGHTS_DESCRIPTION, rightsData.neighbouring_rights_description);
      commit(mutations.RIGHTS_SET_ACCESS_PREVENTION, rightsData.access_prevention);
      commit(mutations.RIGHTS_SET_ACCESS_PREVENTION_DESCRIPTION, rightsData.access_prevention_description);
      commit(mutations.RIGHTS_SET_RIGHTS_OF_LIGHT, rightsData.rights_of_light);
      commit(mutations.RIGHTS_SET_ADJOINING_PROPERTIES, rightsData.adjoining_properties);
      commit(mutations.RIGHTS_SET_CUSTOMARY_RIGHTS, rightsData.customary_rights);
      commit(mutations.RIGHTS_SET_MINES_AND_MINERALS, rightsData.mines_and_minerals);
      commit(mutations.RIGHTS_SET_CHANCEL_REPAIR_LIABILITY, rightsData.chancel_repair_liability);
      commit(mutations.RIGHTS_SET_RESOURCE_RIGHTS, rightsData.resource_rights);
      commit(mutations.RIGHTS_SET_ARRANGEMENTS_DESCRIPTION, rightsData.arrangements_description);
      commit(mutations.RIGHTS_SET_OTHER_ARRANGEMENTS, rightsData.other_arrangements);
      commit(mutations.RIGHTS_SET_OTHER_ARRANGEMENTS_DESCRIPTION, rightsData.other_arrangements_description);
      commit(mutations.RIGHTS_SET_PIPES_CROSS_NEIGHBOURS, rightsData.pipes_cross_neighbours);
      commit(mutations.RIGHTS_SET_NEIGHBOURS_PIPES_CROSS, rightsData.neighbours_pipes_cross);
      commit(mutations.RIGHTS_SET_PIPES_AGREEMENT, rightsData.pipes_agreement);
      commit(mutations.RIGHTS_SET_PIPES_AGREEMENT_COPY, rightsData.pipes_agreement_copy);
      commit(mutations.RIGHTS_SET_PIPES_AGREEMENT_DESCRIPTION, rightsData.pipes_agreement_description);
    }

    resolve();
  });
