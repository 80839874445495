import * as mutations from '@/store/leaseholdAlterations/mutations';

export const LEASEHOLD_ALTERATIONS_UPDATE = 'leaseholdAlterations/update';

export const updateLeaseholdAlterations = (commit, leaseholdAlterationsData) =>
  new Promise((resolve) => {
    if (leaseholdAlterationsData) {
      commit(
        mutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED,
        leaseholdAlterationsData.alterations_since_lease_granted,
      );
      commit(
        mutations.LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION,
        leaseholdAlterationsData.alterations_since_lease_granted_description,
      );
      commit(mutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED, leaseholdAlterationsData.consent_obtained);
      commit(
        mutations.LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT,
        leaseholdAlterationsData.consent_obtained_document,
      );
    }

    resolve();
  });
