import * as mutations from '@/store/leaseholdInformation/mutations';

export const LEASEHOLD_INFORMATION_UPDATE = 'leaseholdInformation/update';

export const updateLeaseholdInformation = (commit, leaseholdInformationData) =>
  new Promise((resolve) => {
    if (leaseholdInformationData) {
      commit(mutations.LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE, leaseholdInformationData.leasehold_type);
      commit(mutations.LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP, leaseholdInformationData.shared_ownership);
      commit(mutations.LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT, leaseholdInformationData.seller_pays_rent);
      commit(mutations.LEASEHOLD_INFORMATION_SET_YEARLY_RENT, leaseholdInformationData.yearly_rent);
      commit(
        mutations.LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY,
        leaseholdInformationData.rent_payment_frequency,
      );
      commit(
        mutations.LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE,
        leaseholdInformationData.rent_subject_to_increase,
      );
      commit(
        mutations.LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY,
        leaseholdInformationData.rent_revision_frequency,
      );
      commit(
        mutations.LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION,
        leaseholdInformationData.increase_calculation_description,
      );
      commit(mutations.LEASEHOLD_INFORMATION_LOADED);
    }
    resolve();
  });
