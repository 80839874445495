export const COMPLAINTS_PREFIX = 'building/complaints/';

export const COMPLAINTS_SET_LANDLORD = 'COMPLAINTS_SET_LANDLORD';
export const COMPLAINTS_SET_LANDLORD_DESCRIPTION = 'COMPLAINTS_SET_LANDLORD_DESCRIPTION';
export const COMPLAINTS_SET_SELLER = 'COMPLAINTS_SET_SELLER';
export const COMPLAINTS_SET_SELLER_DESCRIPTION = 'COMPLAINTS_SET_SELLER_DESCRIPTION';
export const COMPLAINTS_LOADED = 'COMPLAINTS_LOADED';

export const mutations = {
  [COMPLAINTS_SET_LANDLORD](state, value) {
    state.landlordComplaint = value;
  },
  [COMPLAINTS_SET_LANDLORD_DESCRIPTION](state, value) {
    state.landlordComplaintDescription = value;
  },
  [COMPLAINTS_SET_SELLER](state, value) {
    state.sellerComplaint = value;
  },
  [COMPLAINTS_SET_SELLER_DESCRIPTION](state, value) {
    state.sellerComplaintDescription = value;
  },
  [COMPLAINTS_LOADED](state) {
    state.loaded = true;
  },
};
