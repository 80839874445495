import * as mutations from '@/store/enfranchisement/mutations';

export const ENFRANCHISEMENT_UPDATE = 'enfranchisement/update';

export const updateEnfranchisement = (commit, enfranchisementData) =>
  new Promise((resolve) => {
    if (enfranchisementData) {
      commit(mutations.ENFRANCHISEMENT_SET_OWNED_TWO_YEARS, enfranchisementData.owned_two_years);
      commit(mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE, enfranchisementData.seller_purchase_notice);
      commit(
        mutations.ENFRANCHISEMENT_SET_SELLER_PURCHASE_NOTICE_COPY,
        enfranchisementData.seller_purchase_notice_copy,
      );
      commit(mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE, enfranchisementData.collective_purchase_notice);
      commit(
        mutations.ENFRANCHISEMENT_SET_COLLECTIVE_PURCHASE_NOTICE_COPY,
        enfranchisementData.collective_purchase_notice_copy,
      );
      commit(mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES, enfranchisementData.purchase_notice_responses);
      commit(
        mutations.ENFRANCHISEMENT_SET_PURCHASE_NOTICE_RESPONSES_COPY,
        enfranchisementData.purchase_notice_responses_copy,
      );
    }

    resolve();
  });
