export const PERSONS_PREFIX = 'persons/';
export const PERSON_CLIENT_ADD = 'PERSON_CLIENT_ADD';
export const PERSON_CLIENT_ADD_ALL = 'PERSON_CLIENT_ADD_ALL';
export const PERSON_CLIENT_SET = 'PERSON_CLIENT_SET';
export const PERSON_CLIENT_REMOVE = 'PERSON_CLIENT_REMOVE';
export const PERSON_CLIENT_REMOVE_ALL = 'PERSON_CLIENT_REMOVE_ALL';

export const PERSON_OCCUPIER_ADD = 'PERSON_OCCUPIER_ADD';
export const PERSON_OCCUPIER_ADD_ALL = 'PERSON_OCCUPIER_ADD_ALL';
export const PERSON_OCCUPIER_SET = 'PERSON_OCCUPIER_SET';
export const PERSON_OCCUPIER_REMOVE = 'PERSON_OCCUPIER_REMOVE';
export const PERSON_OCCUPIER_REMOVE_ALL = 'PERSON_OCCUPIER_REMOVE_ALL';

export const PERSON_ADDITIONAL_TOE_PERSON_ADD = 'PERSON_ADDITIONAL_TOE_PERSON_ADD';
export const PERSON_ADDITIONAL_TOE_PERSON_ADD_ALL = 'PERSON_ADDITIONAL_TOE_PERSON_ADD_ALL';
export const PERSON_ADDITIONAL_TOE_PERSON_SET = 'PERSON_ADDITIONAL_TOE_PERSON_SET';
export const PERSON_ADDITIONAL_TOE_PERSON_REMOVE = 'PERSON_ADDITIONAL_TOE_PERSON_REMOVE';
export const PERSON_ADDITIONAL_TOE_PERSON_REMOVE_ALL = 'PERSON_ADDITIONAL_TOE_PERSON_REMOVE_ALL';

export const PERSON_REMOVED_TOE_PERSON_ADD = 'PERSON_REMOVED_TOE_PERSON_ADD';
export const PERSON_REMOVED_TOE_PERSON_ADD_ALL = 'PERSON_REMOVED_TOE_PERSON_ADD_ALL';
export const PERSON_REMOVED_TOE_PERSON_SET = 'PERSON_REMOVED_TOE_PERSON_SET';
export const PERSON_REMOVED_TOE_PERSON_REMOVE = 'PERSON_REMOVED_TOE_PERSON_REMOVE';
export const PERSON_REMOVED_TOE_PERSON_REMOVE_ALL = 'PERSON_REMOVED_TOE_PERSON_REMOVE_ALL';

export const PERSON_FIRM_PERSON_ADD = 'PERSON_FIRM_PERSON_ADD';
export const PERSON_FIRM_PERSON_SET = 'PERSON_FIRM_PERSON_SET';
export const PERSON_FIRM_PERSON_REMOVE = 'PERSON_FIRM_PERSON_REMOVE';
export const PERSON_FIRM_PERSON_REMOVE_ALL = 'PERSON_FIRM_PERSON_REMOVE_ALL';

export const PERSONS_LOADED = 'PERSONS_LOADED';
export const PERSON_CLIENT_DETAILS_UPDATED = 'PERSON_CLIENT_DETAILS_UPDATED';

export const mutations = {
  [PERSON_CLIENT_ADD](state, value) {
    if (state.clients.findIndex((item) => item.personId === value.personId) < 0) {
      state.clients.push(value);
    }
  },
  [PERSON_CLIENT_ADD_ALL](state, value) {
    let data = [...value];
    data.forEach((item) => {
      state.clients.push(item);
    });
  },
  [PERSON_CLIENT_SET](state, { index, value }) {
    state.clients[index] = value;
  },
  [PERSON_CLIENT_REMOVE](state, id) {
    const index = state.clients.findIndex((item) => item.personId === id);
    state.clients.splice(index, 1);
  },
  [PERSON_CLIENT_REMOVE_ALL](state) {
    state.clients = [];
  },
  [PERSON_OCCUPIER_ADD](state, value) {
    if (state.occupiers.findIndex((item) => item.personId === value.personId) < 0) {
      state.occupiers.push(value);
    }
  },
  [PERSON_OCCUPIER_ADD_ALL](state, value) {
    state.occupiers = value;
  },
  [PERSON_OCCUPIER_SET](state, { index, value }) {
    state.occupiers[index] = value;
  },
  [PERSON_OCCUPIER_REMOVE](state, id) {
    const index = state.occupiers.findIndex((item) => item.personId === id);
    state.occupiers.splice(index, 1);
  },
  [PERSON_OCCUPIER_REMOVE_ALL](state) {
    state.occupiers = [];
  },
  [PERSON_ADDITIONAL_TOE_PERSON_ADD](state, value) {
    if (state.additionalToePersons.findIndex((item) => item.personId === value.personId) < 0) {
      state.additionalToePersons.push(value);
    }
  },
  [PERSON_ADDITIONAL_TOE_PERSON_ADD_ALL](state, value) {
    state.additionalToePersons = value;
  },
  [PERSON_ADDITIONAL_TOE_PERSON_SET](state, { index, value }) {
    state.additionalToePersons[index] = value;
  },
  [PERSON_ADDITIONAL_TOE_PERSON_REMOVE](state, id) {
    const index = state.additionalToePersons.findIndex((item) => item.personId === id);
    state.additionalToePersons.splice(index, 1);
  },
  [PERSON_ADDITIONAL_TOE_PERSON_REMOVE_ALL](state) {
    state.additionalToePersons = [];
  },
  [PERSON_REMOVED_TOE_PERSON_ADD](state, value) {
    if (state.removedToePersons.findIndex((item) => item.personId === value.personId) < 0) {
      state.removedToePersons.push(value);
    }
  },
  [PERSON_REMOVED_TOE_PERSON_ADD_ALL](state, value) {
    state.removedToePersons = value;
  },
  [PERSON_REMOVED_TOE_PERSON_SET](state, { index, value }) {
    state.removedToePersons[index] = value;
  },
  [PERSON_REMOVED_TOE_PERSON_REMOVE](state, id) {
    const index = state.removedToePersons.findIndex((item) => item.personId === id);
    state.removedToePersons.splice(index, 1);
  },
  [PERSON_REMOVED_TOE_PERSON_REMOVE_ALL](state) {
    state.removedToePersons = [];
  },
  [PERSON_FIRM_PERSON_ADD](state, value) {
    if (state.firmPersons.findIndex((item) => item.personId === value.personId) < 0) {
      state.firmPersons.push(value);
    }
  },
  [PERSON_FIRM_PERSON_SET](state, { index, value }) {
    state.firmPersons[index] = value;
  },
  [PERSON_FIRM_PERSON_REMOVE](state, id) {
    const index = state.firmPersons.findIndex((item) => item.personId === id);
    state.firmPersons.splice(index, 1);
  },
  [PERSON_FIRM_PERSON_REMOVE_ALL](state) {
    state.firmPersons = [];
  },
  [PERSONS_LOADED](state) {
    state.loaded = true;
  },
  [PERSON_CLIENT_DETAILS_UPDATED](state, value) {
    if (value) {
      // Random number is required to force vue to rerender on a property change
      // This works as a random number is set as a :key index of DOM element
      state.clientDetailsUpdated = Math.floor(Math.random() * 1000);
    }
  },
};
