import * as mutations from '@/store/remortgage/mutations';
import {
  addTrackingEvent,
  fetchFromApi,
  fetchSubmissionStatus,
  refreshTrackingEvents,
  resetSubmissionStatus,
  setTrackingEvents,
  setEventToHandle,
  trySubmitRemortgageQuestionnaire,
} from '@/store/remortgage/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    remortgageId: null,
    additionalInfo: null,
    additionalLenderName: null,
    additionalLenderAccountNumber: null,
    additionalLenderAmountOwed: null,
    additionalLenderKeep: null,
    additionalLenderHelpToBuyCharge: null,
    additionalLenderHelpToBuyChargePayingOff: null,
    additionalLenderTargetHcaToPayOff: null,
    additionalLenderTargetHcaToRemortgage: null,
    additionalLenderEarlyRepaymentCharge: null,
    additionalLenderEarlyRepaymentChargeEndDate: null,
    additionalLenderEarlyRepaymentChargeExists: null,
    additionalLenderEarlyRepaymentChargePostponeCompletion: null,
    additionalLenderEarlyRepaymentChargeUnknown: null,
    address: null,
    anyToePersonChanges: null,
    bankName: null,
    bankAccountName: null,
    bankAccountNumber: null,
    bankSortCode: null,
    bankBacsOrChaps: null,
    clientIds: [],
    currentLenderName: null,
    currentLenderAccountNumber: null,
    currentLenderAmountOwed: null,
    currentLenderMakingOverpayment: null,
    currentLenderOverpaymentAmount: null,
    currentLenderBankStatementDocuments: null,
    currentLenderAdditionalBorrowing: null,
    currentLenderAdditionalBorrowingAmount: null,
    bankDetails: null,
    earlyRepaymentCharge: null,
    earlyRepaymentChargeEndDate: null,
    earlyRepaymentChargeExists: null,
    earlyRepaymentChargePostponeCompletion: null,
    earlyRepaymentChargeUnknown: null,
    leaseholdAdditionalInfo: null,
    newLenderName: null,
    newLenderAmountOwed: null,
    occupierIds: [],
    passStatementsToBroker: null,
    personsToBeAddedToTitleIds: [],
    personsToBeRemovedFromTitleIds: [],
    clientsDetails: [],
    sortReferId: null,
    supplierInformation: null,
    trackingEvents: [],
    remortgageAddressUpdated: 0,
    eventToHandle: null,
    config: null,
    tenureType: null,
    submissionStatus: null,
    clientMatchDetails: [],
    hmlrTitleDocumentId: null,
    standaloneToe: null,
    existingMortgage: null,
  },
  getters: {
    trackingEvents: (state) => state.trackingEvents,
    eventToHandle: (state) => state.eventToHandle,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: ({ commit }, remortgageId) => fetchFromApi(commit, remortgageId),
    fetchSubmissionStatus: ({ commit }, remortgageId) => fetchSubmissionStatus(commit, remortgageId),
    refreshTrackingEvents: (context, id) => refreshTrackingEvents(context, id),
    resetSubmissionStatus: ({ commit }) => resetSubmissionStatus(commit),
    setTrackingEvents: ({ commit }, trackingEvents) => setTrackingEvents(commit, trackingEvents),
    addTrackingEvent: ({ commit }, trackingEvent) => addTrackingEvent(commit, trackingEvent),
    setEventToHandle: ({ commit }, eventToHandle) => setEventToHandle(commit, eventToHandle),
    trySubmitRemortgageQuestionnaire: ({ commit }, payload) => trySubmitRemortgageQuestionnaire(commit, payload),
  },
};
