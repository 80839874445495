export const ROOMS_PREFIX = 'building/rooms/';

export const ROOMS_SET_RECEIVED = 'ROOMS_SET_RECEIVED';
export const ROOMS_SET_ROOMS_DATA = 'ROOMS_SET_ROOMS_DATA';
export const ROOMS_ADD_NEW = 'ROOMS_ADD_NEW';
export const ROOMS_REMOVE_EXISTING = 'ROOMS_REMOVE_EXISTING';
export const ROOMS_LOADED = 'ROOMS_LOADED';

// room fittings
export const ROOMS_SET_FITTING = 'ROOM_SET_FITTING';
export const ROOMS_REMOVE_FITTING = 'ROOMS_REMOVE_FITTING';
export const ROOMS_ADD_FITTING = 'ROOMS_ADD_FITTING';

export const mutations = {
  [ROOMS_SET_RECEIVED](state, value) {
    state.roomsReceived = value;
  },
  [ROOMS_SET_ROOMS_DATA](state, value) {
    state.roomsData = value;
  },
  [ROOMS_ADD_NEW](state, value) {
    state.roomsData.push(value);
  },
  [ROOMS_REMOVE_EXISTING](state, index) {
    state.roomsData.splice(index, 1);
  },
  [ROOMS_SET_FITTING](state, { value, roomId, fittingIndex }) {
    Object.assign(state.roomsData.find((room) => room.roomId === roomId).fittings[fittingIndex], value);
  },
  [ROOMS_REMOVE_FITTING](state, { roomId, fittingIndex }) {
    const room = state.roomsData.find((room) => room.roomId === roomId);

    room.fittings = room.fittings.filter((item, index) => index !== fittingIndex);
  },
  [ROOMS_ADD_FITTING](state, { value, roomId }) {
    state.roomsData.find((room) => room.roomId === roomId).fittings.push(value);
  },
  [ROOMS_LOADED](state) {
    state.loaded = true;
  },
};
