import * as mutations from '@/store/relevantDocuments/mutations';

export const RELEVANT_DOCUMENTS_UPDATE = 'relevantDocuments/update';

export const updateRelevantDocument = (commit, relevantDocumentsData) =>
  new Promise((resolve) => {
    if (relevantDocumentsData) {
      commit(mutations.RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS, relevantDocumentsData.lease_supplemental_deeds);
      commit(mutations.RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS, relevantDocumentsData.landlord_regulations);
      commit(mutations.RELEVANT_DOCUMENTS_SET_CORRESPONDENCE, relevantDocumentsData.correspondence);
      commit(mutations.RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES, relevantDocumentsData.maintenance_invoices);
      commit(mutations.RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES, relevantDocumentsData.ground_rent_invoices);
      commit(
        mutations.RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE,
        relevantDocumentsData.seller_buildings_insurance,
      );
      commit(
        mutations.RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE,
        relevantDocumentsData.landlord_buildings_insurance,
      );
      commit(
        mutations.RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY,
        relevantDocumentsData.tenants_management_company,
      );
      commit(mutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM, relevantDocumentsData.management_memorandum);
      commit(mutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE, relevantDocumentsData.management_certificate);
      commit(mutations.RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS, relevantDocumentsData.management_accounts);
    }

    resolve();
  });
