import * as mutations from '@/store/environmentalMatter/mutations';
import { updateEnvironmentalMatters } from '@/store/environmentalMatter/actions';

export default {
  namespaced: true,
  state: {
    flooding: null,
    floodingDescription: null,
    groundWater: null,
    sewerFlooding: null,
    surfaceWater: null,
    coastalFlooding: null,
    riverFlooding: null,
    other: null,
    otherDescription: null,
    floodRiskReport: null,
    floodRiskReportCopy: null,
    radonTest: null,
    radonTestReport: null,
    radonTestBelowRecommendedActionLevel: null,
    radonTestRemedialMeasures: null,
    epcCopy: null,
    installationsGreenDealFinanced: null,
    installationsGreenDealFinancedElectricityBill: null,
    installationsGreenDealFinancedDescription: null,
    propertyAffectedByJapaneseKnotweed: null,
    japaneseKnotweedManagementPlan: null,
    japaneseKnotweedManagementPlanCopy: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, environmentalMattersData) => updateEnvironmentalMatters(commit, environmentalMattersData),
  },
};
