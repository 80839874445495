import Logger from '@/functions/logger';

/**
 * Redirects the user to the dashboard page on 403.
 * Redirects the user to the logout page on 401.
 * @param error
 * @returns {Promise<never>}
 */
const forbiddenInterceptor = (error) => {
  if (error?.request?.config?.url.includes('logging')) {
    return Promise.reject(error);
  }
  switch (error?.response?.status) {
    case 401:
      location.href = '/auth/logged-out';
      break;
    case 403:
      new Logger().error('A forbidden error occurred', error.toJSON());
      break;
    default:
      return Promise.reject(error);
  }
};

export default forbiddenInterceptor;
