export const UTILITY_CONNECTIONS_PREFIX = 'building/utilityConnections/';
export const UTILITY_CONNECTIONS_SET_ELECTRICITY = 'UTILITY_CONNECTIONS_SET_ELECTRICITY';
export const UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME';
export const UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER =
  'UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER';
export const UTILITY_CONNECTIONS_SET_GAS = 'UTILITY_CONNECTIONS_SET_GAS';
export const UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME';
export const UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER = 'UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER';
export const UTILITY_CONNECTIONS_SET_WATER = 'UTILITY_CONNECTIONS_SET_WATER';
export const UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME';
export const UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK = 'UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK';
export const UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER = 'UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER';
export const UTILITY_CONNECTIONS_SET_SEWERAGE = 'UTILITY_CONNECTIONS_SET_SEWERAGE';
export const UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME';
export const UTILITY_CONNECTIONS_SET_TELEPHONE = 'UTILITY_CONNECTIONS_SET_TELEPHONE';
export const UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME';
export const UTILITY_CONNECTIONS_SET_CABLE = 'UTILITY_CONNECTIONS_SET_CABLE';
export const UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME = 'UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME';

export const UTILITY_CONNECTIONS_LOADED = 'UTILITY_CONNECTIONS_LOADED';

export const mutations = {
  [UTILITY_CONNECTIONS_SET_ELECTRICITY](state, val) {
    state.electricity = val;
  },
  [UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME](state, val) {
    state.electricityProviderName = val;
  },
  [UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER](state, val) {
    state.electricityLocationOfMeter = val;
  },
  [UTILITY_CONNECTIONS_SET_GAS](state, val) {
    state.gas = val;
  },
  [UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME](state, val) {
    state.gasProviderName = val;
  },
  [UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER](state, val) {
    state.gasLocationOfMeter = val;
  },
  [UTILITY_CONNECTIONS_SET_WATER](state, val) {
    state.water = val;
  },
  [UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME](state, val) {
    state.waterProviderName = val;
  },
  [UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK](state, val) {
    state.waterLocationOfStopcock = val;
  },
  [UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER](state, val) {
    state.waterLocationOfMeter = val;
  },
  [UTILITY_CONNECTIONS_SET_SEWERAGE](state, val) {
    state.sewerage = val;
  },
  [UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME](state, val) {
    state.sewerageProviderName = val;
  },
  [UTILITY_CONNECTIONS_SET_TELEPHONE](state, val) {
    state.telephone = val;
  },
  [UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME](state, val) {
    state.telephoneProviderName = val;
  },
  [UTILITY_CONNECTIONS_SET_CABLE](state, val) {
    state.cable = val;
  },
  [UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME](state, val) {
    state.cableProviderName = val;
  },
  [UTILITY_CONNECTIONS_LOADED](state) {
    state.loaded = true;
  },
};
