import * as mutations from '@/store/leaseholdInformation/mutations';
import { updateLeaseholdInformation } from '@/store/leaseholdInformation/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    leaseholdType: null,
    sharedOwnership: null,
    sellerPaysRent: null,
    yearlyRent: null,
    rentPaymentFrequency: null,
    rentSubjectToIncrease: null,
    rentRevisionFrequency: null,
    increaseCalculationDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, buildingId) => updateLeaseholdInformation(commit, buildingId),
  },
};
