import * as mutations from '@/store/rooms/mutations';

export const ROOMS_UPDATE = 'rooms/update';

export const updateRooms = (commit, roomsData) =>
  new Promise((resolve) => {
    if (roomsData) {
      const rooms = roomsData.map((room) => ({
        roomId: room.roomId || room.room_id,
        roomType: room.roomType || room.room_type,
        title: room.title,
        fittings: room.fittings.map((fitting) => {
          if (typeof fitting === 'string') {
            fitting = JSON.parse(fitting);
          }

          return {
            freeStanding: fitting.free_standing,
            fittingType: fitting.fitting_type,
            comments: fitting.comments,
            fitted: fitting.fitted,
            price: fitting.price,
            none: fitting.none,
            included: fitting.included,
            excluded: fitting.excluded,
            title: fitting.title,
          };
        }),
      }));
      commit(mutations.ROOMS_SET_ROOMS_DATA, rooms);
      commit(mutations.ROOMS_LOADED);
    }
    resolve();
  });
