<script>
export default {
  name: 'Modal',
  props: {
    // Close button 1 config
    closeButtonText: {
      type: String,
      default: '',
    },
    closeButtonEmitValue: {
      default: () => false,
    },
    // Close button 2 config
    closeButton2Text: {
      type: String,
      default: '',
    },
    closeButton2EmitValue: {
      default: null,
    },
    // Proceed button config
    proceedButtonText: {
      type: String,
      default: '',
    },
    proceedButtonEmitValue: {
      default: () => true,
    },
    showButtons: {
      type: Boolean,
      default: () => true,
    },
    showHeader: {
      type: Boolean,
      default: () => true,
    },
    fullModalWidth: {
      type: Boolean,
      default: () => false,
    },
    removeSectionSpacing: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    validateProps() {
      if (this.closeButton2Text !== '' && this.closeButton2EmitValue == null) {
        console.error('A value must be provided for close button 2');
      }
    },
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateProps();
      },
    },
  },
};
</script>

<template>
  <div>
    <!-- Mobile -->
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container d-block d-sm-none w-100 position-absolute top-0 bottom-0"
            :class="fullModalWidth ? 'full-modal-width' : ''"
            style="border-radius: 0"
          >
            <div v-if="showHeader" class="modal-header">
              <h3 class="modal-title">
                <slot name="header"></slot>
              </h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="$emit('proceed', false)"
              >
                <span aria-hidden="true" class="font-size-lg"><i class="icon-close2 icon-2x" /></span>
              </button>
            </div>
            <div v-if="!removeSectionSpacing" class="border-top my-3"></div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div v-if="showButtons">
              <div class="modal-mobile-footer mb-md-2">
                <div class="row">
                  <div v-if="proceedButtonText !== ''" class="col col-12 mb-2">
                    <button class="btn btn-success" @click="$emit('proceed', proceedButtonEmitValue)">
                      {{ proceedButtonText }}
                    </button>
                  </div>

                  <div v-if="closeButtonText !== ''" class="col col-12 mb-2">
                    <button class="btn btn-modal-cancel" @click="$emit('proceed', closeButtonEmitValue)">
                      {{ closeButtonText }}
                    </button>
                  </div>

                  <div v-if="closeButton2Text !== ''" class="col col-12 mb-2">
                    <button class="btn btn-modal-cancel" @click="$emit('proceed', closeButton2EmitValue)">
                      {{ closeButton2Text }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-container d-none d-sm-block" :class="fullModalWidth ? 'full-modal-width' : ''">
            <div v-if="showHeader" class="modal-header">
              <h3 class="modal-title">
                <slot name="header"></slot>
              </h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="$emit('proceed', false)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="!removeSectionSpacing" class="border-top my-3"></div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div v-if="showButtons">
              <div v-if="!removeSectionSpacing" class="border-top my-3"></div>
              <div class="modal-footer mb-2">
                <slot name="footer">
                  <button
                    v-if="closeButtonText !== ''"
                    class="btn btn-modal-cancel"
                    @click="$emit('proceed', closeButtonEmitValue)"
                  >
                    {{ closeButtonText }}
                  </button>

                  <button
                    v-if="closeButton2Text !== ''"
                    class="btn btn-modal-cancel"
                    @click="$emit('proceed', closeButton2EmitValue)"
                  >
                    {{ closeButton2Text }}
                  </button>

                  <button
                    v-if="proceedButtonText !== ''"
                    class="btn btn-success"
                    @click="$emit('proceed', proceedButtonEmitValue)"
                  >
                    {{ proceedButtonText }}
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  postion: relative;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-mobile-footer {
  margin: 20px 0;
  text-align: center;
  height: auto;
  transform: translate3d(0, 0, 0);
  @media (max-width: 576px) {
    margin: 0;
    max-height: 210px;
    padding: 20px 0;
    width: 100%;
    bottom: 0;
    background: #fff;
    position: fixed;
  }
}

.modal-footer {
  text-align: center !important;
}

.full-modal-width {
  width: 1200px !important;
  @media (max-width: 1199.98px) {
    width: 90% !important;
  }
  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
