import * as mutations from '@/store/rights/mutations';
import { updateRights } from '@/store/rights/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    sharedServiceMaintenance: null,
    sharedServiceMaintenanceDescription: null,
    neighbouringRights: null,
    neighbouringRightsDescription: null,
    accessPrevention: null,
    accessPreventionDescription: null,
    rightsOfLight: null,
    adjoiningProperties: null,
    customaryRights: null,
    minesAndMinerals: null,
    chancelRepairLiability: null,
    resourceRights: null,
    arrangementsDescription: null,
    otherArrangements: null,
    otherArrangementsDescription: null,
    pipesCrossNeighbours: null,
    neighboursPipesCross: null,
    pipesAgreement: null,
    pipesAgreementDescription: null,
    pipesAgreementCopy: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, rightsData) => updateRights(commit, rightsData),
  },
};
