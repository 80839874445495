import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import currentUser from '@/plugins/auth/currentUser';
import masqueradedUser from '@/plugins/auth/masqueradedUser';
import axios from 'axios';

export default class Logger {
  cleanse(data) {
    if (data?.isAxiosError) {
      data = data.toJSON();
    }
    if (data instanceof Error) {
      data = { name: data.name, message: data.message, stack: data.stack };
    }

    return this.removePasswords(data);
  }

  debug(message, ...optionalParams) {
    this.writeLog('debug', message, ...optionalParams);
  }

  error(message, ...optionalParams) {
    this.writeLog('error', message, ...optionalParams);
  }

  log(message, ...optionalParams) {
    this.writeLog('log', message, ...optionalParams);
  }

  removePasswords(data) {
    let clonedData = cloneDeep(data);

    if (isArray(clonedData)) {
      return map(clonedData, this.removePasswords);
    } else {
      clonedData = mapValues(clonedData, (value, key) => {
        return String(key).toLowerCase().includes('password') ? '[REDACTED]' : value;
      });

      // Recursively apply throughout object
      return mapValues(clonedData, (value) => {
        if (isPlainObject(value)) {
          return this.removePasswords(value);
        } else if (isArray(value)) {
          return map(value, this.removePasswords);
        } else {
          return value;
        }
      });
    }
  }

  verbose(message, ...optionalParams) {
    this.writeLog('verbose', message, ...optionalParams);
  }

  warn(message, ...optionalParams) {
    this.writeLog('warn', message, ...optionalParams);
  }

  writeLog(level, message, ...optionalParams) {
    const contexts = optionalParams.map((optionalParam) => this.cleanse(optionalParam));
    const cognitoUserId = currentUser()?.attributes?.sub;
    const masqueradedCognitoUserId = masqueradedUser()?.id ?? '';
    contexts.push({ cognitoUserId, env: process.env, location, masqueradedCognitoUserId });
    axios
      .post(`${process.env.VUE_APP_API_ENDPOINT}/logging`, {
        level,
        message,
        environment: process.env.VUE_APP_LOGGING_ENVIRONMENT,
        context: contexts,
      })
      .catch(() => {});
  }
}
