import aws4 from 'aws4';
import getRequestComponents from '@/plugins/auth/interceptors/getRequestComponents';
import currentCredentials from '@/plugins/auth/currentCredentials';
import currentUser from '@/plugins/auth/currentUser';
import masqueradedUser from '../masqueradedUser';

export default async (config) => {
  // Set the content type, we need to specify this so aws4 can sign the request
  config.headers['Content-Type'] = 'application/json';

  // Configure our request options
  const opts = getRequestComponents(config.url);
  opts.method = 'DELETE'; // Axios requires data

  // Get credentials from AWS
  const { accessKeyId, secretAccessKey, sessionToken } = (await currentCredentials()) ?? {};
  if (process.env.VUE_APP_SAM_LOCAL === '1') {
    config.headers.common['X-Sort-Cognito-Id'] = currentUser()?.attributes?.sub;
  }
  config.headers.common['X-Sort-Masqueraded-Cognito-Id'] = masqueradedUser()?.id ?? '';

  // Sign our request object with AWS credentials
  const request = aws4.sign(opts, { accessKeyId, secretAccessKey, sessionToken });

  // Set the original config item with the correct security headers
  config.headers['X-Amz-Security-Token'] = request.headers['X-Amz-Security-Token'];
  config.headers['X-Amz-Date'] = request.headers['X-Amz-Date'];
  config.headers['Authorization'] = request.headers['Authorization'];

  if (+process.env.VUE_APP_SAM_LOCAL === 1) {
    config.headers.common['X-Access-Key-Id'] = accessKeyId;
    config.headers.common['X-Secret-Access-Key'] = secretAccessKey;
    config.headers.common['X-Session-Token'] = sessionToken;
  }

  return config;
};
