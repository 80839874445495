import * as mutations from '@/store/payment/mutations';
import { updatePayments } from '@/store/payment/actions';

const billingAddress = {
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  country: 'GB',
  company: '',
  premise: '',
  thoroughfare: '',
  county: '',
};
const card = {
  merchantSessionKey: '',
  cardIdentifier: '',
};
const paymentMethod = { card };

export const defaultCase = {
  index: 0,
  transactionType: 'Payment',
  paymentMethod,
  amount: 3000,
  customerFirstName: '',
  customerLastName: '',
  billingAddress,
};

export default {
  namespaced: true,
  state: {
    currentCase: '',
    cases: {},
  },
  mutations: {
    ...mutations.mutations,
  },
  getters: {
    case: (state) => {
      return state.cases[state.currentCase] ?? defaultCase;
    },
  },
  actions: {
    update: ({ commit }, paymentData) => updatePayments(commit, paymentData),
  },
};
