import * as mutations from '@/store/parkingDetails/mutations';
import { updateParkingDetails } from '@/store/parkingDetails/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    arrangementsDescription: null,
    controlledParkingZone: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, parkingDetailsData) => updateParkingDetails(commit, parkingDetailsData),
  },
};
