import get from './interceptors/get';
import post from './interceptors/post';
import patch from './interceptors/patch';
import deletion from './interceptors/delete';

const request = (config) => {
  const path = new URL(config.url).pathname.replace(/^\/(dev|production)/, '');
  // Public routes do not require authenticating
  if (path.startsWith('/public/')) {
    return config;
  }
  // Switch out for new test proxy for certain services
  if (
    ['sales/', 'buildings/', 'firms/', 'document-manager/'].find(
      (value) => config.url.includes(value) && window.localStorage.getItem('useNestApi') === 'true',
    ) &&
    !config.url.includes(process.env.VUE_APP_NEST_PREFIX)
  ) {
    console.debug(`Intercepting URL: ${config.url}`);
    config.url = config.url.replace(process.env.VUE_APP_OLD_PREFIX, process.env.VUE_APP_NEST_PREFIX);
  }
  if (
    [
      'document-sign',
      'person',
      'land-registry-search',
      'sale',
      'purchase',
      'building',
      'firm',
      'pdf',
      'tracking',
      'document-manager',
      'remortgage',
      'lenders',
      'referrals',
      'hooyu',
      'postcode',
      'logging',
      'sagepay',
      'cases',
      'users',
      'yoti',
    ].find((value) => config.url.includes(value))
  ) {
    switch (config.method) {
      case 'get':
        config = get(config);
        break;
      case 'post':
        config = post(config);
        break;
      case 'patch':
        config = patch(config);
        break;
      case 'delete':
        config = deletion(config);
        break;
      default:
        break;
    }
  }

  return config;
};

const requestError = (error) => {
  return Promise.reject(error);
};
export { request, requestError };
