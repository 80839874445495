import * as mutations from '@/store/occupiers/mutations';
import { updateOccupiers } from '@/store/occupiers/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    sellerLivesAtProperty: false,
    seventeenOrOverLivesAtProperty: false,
    occupierNamesDescription: null,
    occupiersTenants: false,
    vacantPossession: false,
    occupantsLeavingBeforeCompletion: false,
    agreeToSaleContract: false,
    agreeToSaleContractDocuments: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, occupiersData) => updateOccupiers(commit, occupiersData),
  },
};
