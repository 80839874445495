import * as mutations from '@/store/consents/mutations';

export const CONSENTS_UPDATE = 'consents/update';

export const updateConsents = (commit, consentsData) =>
  new Promise((resolve) => {
    if (consentsData) {
      commit(mutations.CONSENTS_SET_LEASE_TERMS_CHANGE, consentsData.lease_terms_change);
      commit(mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DOCUMENT, consentsData.lease_terms_change_document);
      commit(mutations.CONSENTS_SET_LEASE_TERMS_CHANGE_DESCRIPTION, consentsData.lease_terms_change_description);
    }

    resolve();
  });
