import * as mutations from '@/store/relevantDocuments/mutations';
import { updateRelevantDocument } from '@/store/relevantDocuments/actions';

export default {
  namespaced: true,
  state: {
    leaseSupplementalDeeds: null,
    landlordRegulations: null,
    correspondence: null,
    maintenanceInvoices: null,
    groundRentInvoices: null,
    sellerBuildingsInsurance: null,
    landlordBuildingsInsurance: null,
    tenantsManagementCompany: null,
    managementMemorandum: null,
    managementCertificate: null,
    managementAccounts: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, buildingId) => updateRelevantDocument(commit, buildingId),
  },
};
