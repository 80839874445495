import axios from 'axios';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

const camelize = (obj) =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export default async (entity_id) => {
  const result = await axios.get(
    `${process.env.VUE_APP_API_ENDPOINT}/document-manager/entity/${entity_id}?hydrate=true`,
  );
  result.data.results = result.data.results.map(({ metadata, ...document }) => ({
    ...document,
    metadata: typeof metadata === 'string' ? JSON.parse(metadata) : metadata,
  }));
  result.data.results = camelize(result.data.results);
  return result;
};
