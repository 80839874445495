export const OCCUPIERS_PREFIX = 'building/occupiers/';

export const OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY = 'OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY';
export const OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY = 'OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY';
export const OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION = 'OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION';
export const OCCUPIERS_SET_OCCUPIERS_TENANTS = 'OCCUPIERS_SET_OCCUPIERS_TENANTS';
export const OCCUPIERS_SET_VACANT_POSSESSION = 'OCCUPIERS_SET_VACANT_POSSESSION';
export const OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION = 'OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION';
export const OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT = 'OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT';
export const OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS = 'OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS';
export const OCCUPIERS_LOADED = 'OCCUPIERS_LOADED';

export const mutations = {
  [OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY](state, val) {
    state.sellerLivesAtProperty = val;
  },
  [OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY](state, val) {
    state.seventeenOrOverLivesAtProperty = val;
  },
  [OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION](state, val) {
    state.occupierNamesDescription = val;
  },
  [OCCUPIERS_SET_OCCUPIERS_TENANTS](state, val) {
    state.occupiersTenants = val;
  },
  [OCCUPIERS_SET_VACANT_POSSESSION](state, val) {
    state.vacantPossession = val;
  },
  [OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION](state, val) {
    state.occupantsLeavingBeforeCompletion = val;
  },
  [OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT](state, val) {
    state.agreeToSaleContract = val;
  },
  [OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS](state, val) {
    state.agreeToSaleContractDocuments = val;
  },
  [OCCUPIERS_LOADED](state) {
    state.loaded = true;
  },
};
