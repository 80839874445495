import * as mutations from '@/store/utility-connections/mutations';
import { updateUtilityConnections } from '@/store/utility-connections/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    electricity: null,
    electricityProviderName: null,
    electricityLocationOfMeter: null,
    gas: null,
    gasProviderName: null,
    gasLocationOfMeter: null,
    water: null,
    waterProviderName: null,
    waterLocationOfStopcock: null,
    waterLocationOfMeter: null,
    sewerage: null,
    sewerageProviderName: null,
    telephone: null,
    telephoneProviderName: null,
    cable: null,
    cableProviderName: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, utilityConnectionsData) => updateUtilityConnections(commit, utilityConnectionsData),
  },
};
