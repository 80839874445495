import * as mutations from '@/store/transactionInformation/mutations';
import { updateTransactionInformation } from '@/store/transactionInformation/actions';

export default {
  namespaced: true,
  state: {
    sameDayCompletion: null,
    sellerMovingDateRequirements: null,
    sellerMovingDateRequirementsDescription: null,
    salePriceExceedsMortgageCharges: null,
    buildingTidy: null,
    emptyLightFittingsReplaced: null,
    fittingRemovalCare: null,
    securityDetailsProvidedAtBuilding: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, transactionInformationData) =>
      updateTransactionInformation(commit, transactionInformationData),
  },
};
