import * as mutations from '@/store/disputes/mutations';

export const DISPUTES_UPDATE = 'disputes/update';

export const updateDisputes = (commit, disputesData) =>
  new Promise((resolve) => {
    if (disputesData) {
      commit(mutations.DISPUTES_SET_COMPLAINTS, disputesData.disputes_complaints);
      commit(mutations.DISPUTES_SET_COMPLAINTS_DESCRIPTION, disputesData.disputes_complaints_description);
      commit(mutations.DISPUTES_SET_POTENTIAL, disputesData.potential_dispute);
      commit(mutations.DISPUTES_SET_POTENTIAL_DESCRIPTION, disputesData.potential_dispute_description);
    }

    resolve();
  });
