import * as mutations from '@/store/alterations/mutations';
import { updateAlterations } from '@/store/alterations/actions';

export default {
  namespaced: true,
  state: {
    buildingWorks: null,
    buildingWorksDescription: null,
    changeOfUse: null,
    changeOfUseYear: null,
    replacementWindowInstallation: null,
    replacementWindowInstallationYear: null,
    conservatoryAddition: null,
    conservatoryAdditionYear: null,
    planningPermissionNotRequiredDescription: null,
    changesUnfinished: null,
    changesUnfinishedDescription: null,
    planningPermissionConditionsBreached: null,
    planningPermissionConditionsBreachedDescription: null,
    buildingControlIssuesToResolve: null,
    buildingControlIssuesToResolveDescription: null,
    solarPanelsInstalled: null,
    solarPanelsInstalledYear: null,
    solarPanelsOwned: null,
    solarPanelsLongLease: null,
    solarPanelsLongLeaseDocuments: null,
    listedBuilding: null,
    conservationArea: null,
    listedBuildingConservationAreaDocuments: null,
    treesSubjectToPreservationOrder: null,
    preservationOrderCompliedWith: null,
    preservationOrderRelevantDocuments: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, alterationsData) => updateAlterations(commit, alterationsData),
  },
};
