import axios from 'axios';

export default (id, reason, additionalInfo) => {
  const nameMismatchData = {
    name_mismatch_reason: reason,
    additional_info: additionalInfo,
  };
  return axios.post(
    `${process.env.VUE_APP_API_ENDPOINT}/sales/${id}/try-submit-client-questionnaire`,
    nameMismatchData,
  );
};
