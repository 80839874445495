import getFirm from '@/api/firms/getFirm';
import * as mutations from '@/store/firm/mutations';

export const FIRM_FETCH = 'firm/fetch';

export const fetchFromApi = (commit, firmId) =>
  new Promise((resolve, reject) => {
    getFirm(firmId)
      .then((response) => {
        commit(mutations.FIRM_SET_FIRM_ID, response.data.firm_id);

        commit(mutations.FIRM_SET_NAME, response.data.name);
        commit(mutations.FIRM_SET_EMAIL, response.data.email);
        commit(mutations.FIRM_SET_PHONE, response.data.phone);
        commit(mutations.FIRM_SET_MOBILE_PHONE, response.data.mobile_phone);
        commit(mutations.FIRM_SET_REFERENCE, response.data.reference);
        commit(mutations.FIRM_SET_ADDRESS, response.data.address);

        const personIds = response.data.person_ids || [];
        commit(mutations.FIRM_SET_PERSON_IDS, personIds);

        commit(mutations.FIRM_LOADED);
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
