import * as mutations from '@/store/enfranchisement/mutations';
import { updateEnfranchisement } from '@/store/enfranchisement/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    ownedTwoYears: null,
    sellerPurchaseNotice: null,
    sellerPurchaseNoticeCopy: null,
    collectivePurchaseNotice: null,
    collectivePurchaseNoticeCopy: null,
    purchaseNoticeResponses: null,
    purchaseNoticeResponsesCopy: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, enfranchisementData) => updateEnfranchisement(commit, enfranchisementData),
  },
};
