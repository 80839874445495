export const CONTACT_PREFIX = 'building/contact/';

export const CONTACT_LOADED = 'CONTACT_LOADED';
export const CONTACT_LANDLORD_NAME = 'CONTACT_LANDLORD_NAME';
export const CONTACT_LANDLORD_ADDRESS = 'CONTACT_LANDLORD_ADDRESS';
export const CONTACT_LANDLORD_TEL = 'CONTACT_LANDLORD_TEL';
export const CONTACT_LANDLORD_EMAIL = 'CONTACT_LANDLORD_EMAIL';
export const CONTACT_LANDLORD_AGENT_NAME = 'CONTACT_LANDLORD_AGENT_NAME';
export const CONTACT_LANDLORD_AGENT_ADDRESS = 'CONTACT_LANDLORD_AGENT_ADDRESS';
export const CONTACT_LANDLORD_AGENT_TEL = 'CONTACT_LANDLORD_AGENT_TEL';
export const CONTACT_LANDLORD_AGENT_EMAIL = 'CONTACT_LANDLORD_AGENT_EMAIL';
export const CONTACT_TENANT_AGENT_NAME = 'CONTACT_TENANT_AGENT_NAME';
export const CONTACT_TENANT_AGENT_ADDRESS = 'CONTACT_TENANT_AGENT_ADDRESS';
export const CONTACT_TENANT_AGENT_TEL = 'CONTACT_TENANT_AGENT_TEL';
export const CONTACT_TENANT_AGENT_EMAIL = 'CONTACT_TENANT_AGENT_EMAIL';

export const mutations = {
  [CONTACT_LANDLORD_NAME](state, val) {
    state.landlordName = val;
  },
  [CONTACT_LANDLORD_ADDRESS](state, val) {
    state.landlordAddress = val;
  },
  [CONTACT_LANDLORD_TEL](state, val) {
    state.landlordTel = val;
  },
  [CONTACT_LANDLORD_EMAIL](state, val) {
    state.landlordEmail = val;
  },
  [CONTACT_LANDLORD_AGENT_NAME](state, val) {
    state.landlordAgentName = val;
  },
  [CONTACT_LANDLORD_AGENT_ADDRESS](state, val) {
    state.landlordAgentAddress = val;
  },
  [CONTACT_LANDLORD_AGENT_TEL](state, val) {
    state.landlordAgentTel = val;
  },
  [CONTACT_LANDLORD_AGENT_EMAIL](state, val) {
    state.landlordAgentEmail = val;
  },
  [CONTACT_TENANT_AGENT_NAME](state, val) {
    state.tenantAgentName = val;
  },
  [CONTACT_TENANT_AGENT_ADDRESS](state, val) {
    state.tenantAgentAddress = val;
  },
  [CONTACT_TENANT_AGENT_TEL](state, val) {
    state.tenantAgentTel = val;
  },
  [CONTACT_TENANT_AGENT_EMAIL](state, val) {
    state.tenantAgentEmail = val;
  },
  [CONTACT_LOADED](state) {
    state.loaded = true;
  },
};
