import Vue from 'vue';
import VueRouter from 'vue-router';
import checkPermission from '@/router/middleware/check-permission';
import checkAuthenticated from './middleware/check-authenticated';
import checkDeveloper from './middleware/check-developer';
import checkAdmin from './middleware/check-admin';
import hooyuRedirect from './middleware/hooyu-redirect';

const ClientPlatform = () => import('@/layouts/ClientPlatform.vue');
const AuthCredentials = () => import('@/pages/auth/AuthCredentials.vue');
const AdminPasswordReset = () => import('@/pages/admin/AdminPasswordReset.vue');
const TA6 = () => import('@/pages/TA6.vue');
const TA7 = () => import('@/pages/TA7.vue');
const TA10 = () => import('@/pages/TA10.vue');
const supportingDocuments = () => import('@/pages/SupportingDocuments.vue');
const EmbeddedYotiSign = () => import('@/pages/EmbeddedYotiSign.vue');
const EmbeddedHooYu = () => import('@/pages/EmbeddedHooYu.vue');
const OAuthCallback = () => import('@/pages/auth/OAuthCallback');
const LoginFailed = () => import('@/pages/auth/LoginFailed.vue');
const Debug = () => import('@/pages/auth/Debug.vue');
const LoggedOut = () => import('@/pages/auth/LoggedOut.vue');
const AuthLayout = () => import('@/layouts/AuthLayout.vue');
const PaymentLayout = () => import('@/layouts/PaymentLayout.vue');
const SaleClientQuestionnaire = () => import('@/pages/sale/SaleClientQuestionnaire.vue');
const PurchaseClientQuestionnaire = () => import('@/pages/purchase/PurchaseClientQuestionnaire.vue');
const RemortgageClientQuestionnaire = () => import('@/pages/remortgage/RemortgageClientQuestionnaire.vue');
const MyCases = () => import('@/pages/MyCases.vue');
const Payments = () => import('@/pages/payment/Payments.vue');
const PaymentSuccess = () => import('@/pages/payment/PaymentSuccess.vue');
const PaymentError = () => import('@/pages/payment/PaymentError.vue');
const Dashboard = () => import('@/pages/Dashboard.vue');
const HelpCentre = () => import('@/pages/HelpCentre.vue');
const AdminResendWelcomeEmail = () => import('@/pages/admin/AdminResendWelcomeEmail.vue');
const AdminUserMasquerade = () => import('@/pages/admin/AdminUserMasquerade.vue');
const ViewEntity = () => import('@/pages/ViewEntity.vue');
const PasswordReset = () => import('@/pages/auth/PasswordReset.vue');
const ForgotPassword = () => import('@/pages/auth/ForgotPassword.vue');
const Login = () => import('@/pages/auth/Login.vue');

Vue.use(VueRouter);

const appRoutes = [
  {
    path: '/debug/:entityId/:sagepayTransactionId',
    name: 'Debug',
    component: Debug,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentLayout,
    children: [
      {
        path: 'payment-success',
        name: 'paymentSuccess',
        component: PaymentSuccess,
      },
      {
        path: 'payment-error',
        name: 'paymentError',
        component: PaymentError,
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'ForceLogin',
        component: Login,
      },
      {
        path: 'oauth',
        name: 'oauthCallback',
        component: OAuthCallback,
      },
      {
        path: 'logged-out',
        name: 'loggedOut',
        component: LoggedOut,
      },
      {
        path: 'login-failed',
        name: 'loginFailed',
        component: LoginFailed,
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: PasswordReset,
        props: (route) => ({ token: route.query?.token, user: route.query?.user }),
      },
      {
        path: 'request-password-reset',
        name: 'ForgotPassword',
        component: ForgotPassword,
        props: (route) => ({ reference: route.query?.reference }),
      },
    ],
  },
  {
    path: '/',
    name: 'ClientPlatform',
    component: ClientPlatform,
    beforeEnter: (to, from, next) => hooyuRedirect(to, from, () => checkAuthenticated(to, from, next)),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/help-centre',
        name: 'HelpCentre',
        component: HelpCentre,
      },
      {
        path: 'auth-credentials',
        name: 'AuthCredentials',
        component: AuthCredentials,
        beforeEnter: checkDeveloper,
      },
      {
        path: 'admin/password-reset',
        name: 'AdminPasswordReset',
        component: AdminPasswordReset,
        beforeEnter: checkAdmin || checkDeveloper,
      },
      {
        path: 'admin/resend-welcome-email',
        name: 'AdminResendWelcomeEmail',
        component: AdminResendWelcomeEmail,
        beforeEnter: checkAdmin || checkDeveloper,
      },
      {
        path: 'admin/user-masquerade',
        name: 'AdminUserMasquerade',
        component: AdminUserMasquerade,
        beforeEnter: checkPermission('admin.user.masquerade'),
      },
      {
        path: 'my-cases',
        name: 'MyCases',
        component: MyCases,
      },
      {
        path: 'view/:entityType/:entityId',
        name: 'ViewEntity',
        component: ViewEntity,
        props: true,
        meta: {
          breadcrumb: {
            label: 'View Case',
            parent: 'MyCases',
          },
        },
      },
      {
        path: 'view/:entityType(sale)/:entityId/client-questionnaire',
        name: 'SaleClientQuestionnaire',
        component: SaleClientQuestionnaire,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Client Questionnaire',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType(purchase)/:entityId/client-questionnaire',
        name: 'PurchaseClientQuestionnaire',
        component: PurchaseClientQuestionnaire,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Client Questionnaire',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType(remortgage)/:entityId/client-questionnaire',
        name: 'RemortgageClientQuestionnaire',
        component: RemortgageClientQuestionnaire,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Client Questionnaire',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType/:entityId/ta6-form',
        name: 'Ta6Form',
        component: TA6,
        props: true,
        meta: {
          breadcrumb: {
            label: 'TA6 - Property Information Form',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType/:entityId/ta7-form',
        name: 'Ta7Form',
        component: TA7,
        props: true,
        meta: {
          breadcrumb: {
            label: 'TA7 - Sellers Leasehold Information',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType/:entityId/ta10-form',
        name: 'Ta10Form',
        component: TA10,
        props: true,
        meta: {
          breadcrumb: {
            label: 'TA10 - Fittings and Contents',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType/:entityId/supporting-documents',
        name: 'supportingDocuments',
        component: supportingDocuments,
        props: true,
        meta: {
          breadcrumb: {
            label: 'Supporting Documents',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'payments/:entityType/:entityId/',
        name: 'Payments',
        props: true,
        component: Payments,
      },
      {
        path: 'view/:entityType/:entityId/sign/:token',
        name: 'EmbeddedYotiSign',
        props: true,
        component: EmbeddedYotiSign,
        meta: {
          breadcrumb: {
            label: 'Document Signing',
            parent: 'ViewEntity',
          },
        },
      },
      {
        path: 'view/:entityType/:entityId/hooyu/:clientId',
        name: 'EmbeddedHooYu',
        props: true,
        component: EmbeddedHooYu,
        meta: {
          breadcrumb: {
            label: 'Identity Verification',
            parent: 'ViewEntity',
          },
        },
      },
    ],
  },
];

const routes = [...appRoutes];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
