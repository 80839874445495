export const INSURANCE_PREFIX = 'building/insurance/';

export const INSURANCE_SET_BUILDING_INSURED = 'INSURANCE_SET_BUILDING_INSURED';
export const INSURANCE_SET_BUILDING_INSURED_DETAILS = 'INSURANCE_SET_BUILDING_INSURED_DETAILS';
export const INSURANCE_SET_BUILDING_LANDLORD_INSURED = 'INSURANCE_SET_BUILDING_LANDLORD_INSURED';
export const INSURANCE_SET_PREMIUM_RISE = 'INSURANCE_SET_PREMIUM_RISE';
export const INSURANCE_SET_HIGH_EXCESSES = 'INSURANCE_SET_HIGH_EXCESSES';
export const INSURANCE_SET_UNUSUAL_CONDITIONS = 'INSURANCE_SET_UNUSUAL_CONDITIONS';
export const INSURANCE_SET_REFUSED = 'INSURANCE_SET_REFUSED';
export const INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION = 'INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION';
export const INSURANCE_SET_CLAIMS = 'INSURANCE_SET_CLAIMS';
export const INSURANCE_SET_CLAIMS_DESCRIPTION = 'INSURANCE_SET_CLAIMS_DESCRIPTION';
export const INSURANCE_LOADED = 'INSURANCE_LOADED';

export const mutations = {
  [INSURANCE_SET_BUILDING_INSURED](state, val) {
    state.buildingInsured = val;
  },
  [INSURANCE_SET_BUILDING_INSURED_DETAILS](state, val) {
    state.buildingInsuredDetails = val;
  },
  [INSURANCE_SET_BUILDING_LANDLORD_INSURED](state, val) {
    state.landlordInsured = val;
  },
  [INSURANCE_SET_PREMIUM_RISE](state, val) {
    state.premiumRise = val;
  },
  [INSURANCE_SET_HIGH_EXCESSES](state, val) {
    state.highExcesses = val;
  },
  [INSURANCE_SET_UNUSUAL_CONDITIONS](state, val) {
    state.unusualConditions = val;
  },
  [INSURANCE_SET_REFUSED](state, val) {
    state.refused = val;
  },
  [INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION](state, val) {
    state.insuranceProblemsDescription = val;
  },
  [INSURANCE_SET_CLAIMS](state, val) {
    state.claims = val;
  },
  [INSURANCE_SET_CLAIMS_DESCRIPTION](state, val) {
    state.claimsDescription = val;
  },
  [INSURANCE_LOADED](state) {
    state.loaded = true;
  },
};
