import * as mutations from '@/store/sale/mutations';
import {
  addTrackingEvent,
  fetchFromApi,
  fetchSubmissionStatus,
  refreshTrackingEvents,
  resetSubmissionStatus,
  setTrackingEvents,
  setEventToHandle,
  trySubmitSaleQuestionnaire,
} from '@/store/sale/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    saleId: null,
    clientIds: [],
    authorityPurchase: false,
    authorityPurchaseDetails: null,
    propertyType: null,
    propertyTypeOther: null,
    purchaseType: null,
    councilTaxBand: null,
    buyerName: null,
    salePrice: null,
    estateAgentId: null,
    fixturePrice: null,
    agentFee: null,
    agentFeeType: null,
    agentFeePaymentPermission: null,
    relatedCase: false,
    relatedCases: [],
    postCompletionAddress: null,
    specialPurchase: false,
    specialPurchaseDetails: null,
    occupierIds: [],
    soldByCompany: false,
    soldByCompanyDetails: false,
    titleDeedDeclared: null,
    titleDeedAlternative: null,
    titleDeedAlternatives: [],
    mortgagesOrCharges: [],
    leaseholdToFreehold: false,
    titleNumbers: null,
    desiredCompletionDate: null,
    exchangeDeadline: null,
    completionDeadline: null,
    surplusPaymentMethod: null,
    postCompletionPhone: null,
    buildingId: null,
    clientsDetails: [],
    buildingDetails: null,
    sortReferId: null,
    supplierInformation: null,
    sagepayTransactionId: null,
    chiefOrGroundRent: null,
    chiefOrGroundRentAddress: null,
    chiefOrGroundRentPhone: null,
    productCode: null,
    trackingEvents: [],
    saleAddressUpdated: 0,
    config: null,
    eventToHandle: null,
    submissionStatus: null,
    clientMatchDetails: [],
    hmlrTitleDocumentId: null,
    soldByEstateAgent: null,
    passStatementsToBroker: null,
    additionalInfo: null,
    bankName: null,
    bankAccountName: null,
    bankAccountNumber: null,
    bankSortCode: null,
    bankBacsOrChaps: null,
  },
  getters: {
    trackingEvents: (state) => state.trackingEvents,
    eventToHandle: (state) => state.eventToHandle,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    addTrackingEvent: ({ commit }, trackingEvent) => addTrackingEvent(commit, trackingEvent),
    fetch: ({ commit }, saleId) => fetchFromApi(commit, saleId),
    fetchSubmissionStatus: ({ commit }, saleId) => fetchSubmissionStatus(commit, saleId),
    refreshTrackingEvents: (context, id) => refreshTrackingEvents(context, id),
    resetSubmissionStatus: ({ commit }) => resetSubmissionStatus(commit),
    setEventToHandle: ({ commit }, eventToHandle) => setEventToHandle(commit, eventToHandle),
    setTrackingEvents: ({ commit }, trackingEvents) => setTrackingEvents(commit, trackingEvents),
    trySubmitSaleQuestionnaire: ({ commit }, payload) => trySubmitSaleQuestionnaire(commit, payload),
  },
};
