// JavaScript
import Vue from 'vue';
import Hotjar from 'vue-hotjar';
import VueGtag from 'vue-gtag';
import VueMonitor from '@sort-group/sort-vue-monitoring';
import Auth from '@/plugins/auth';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { BootstrapVue, BVToastPlugin, IconsPlugin } from 'bootstrap-vue';
import vueScrollto from 'vue-scrollto';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import vSelect from 'vue-select';
import IdleVue from 'idle-vue';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import store from './store';
import router from './router';
import App from './App.vue';
import './vee-validate';

// CSS
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'vue-select/dist/vue-select.css';

import i18n from './i18n';

dayjs.extend(relativeTime);

document.onkeydown = function (e) {
  if (e.key === '.' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    const useNestApi = window.localStorage.getItem('useNestApi') === 'true' ? true : false;
    console.debug(useNestApi ? 'Using old API' : 'Using Nest API');
    window.localStorage.setItem('useNestApi', !useNestApi);
  }
};

Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 0, 1);
  var jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

Vue.mixin({
  methods: {
    navigate: function (name, params = false) {
      if (this.$router.currentRoute.name === name) {
        return;
      }

      this.$router.push({
        name: name,
        params: params,
      });
    },
  },
});

Vue.mixin({
  methods: {
    async scrollToSelector(selector) {
      // Wait for any DOM updates triggered by
      // reactive properties.
      await this.$nextTick();

      const element = this.$el.querySelector(selector);
      const scrollIntoViewOptions = {
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      };
      element.scrollIntoView(scrollIntoViewOptions);
    },
  },
});

Vue.filter('fromNow', (value) => {
  const date = dayjs(value);
  return date.isValid() ? dayjs().to(date) : '';
});

Vue.filter('formatDateTime', (value) => {
  if (value) {
    const date = new Date(String(value));
    return date.toLocaleString('en-GB', { timeZone: 'Europe/London' });
  }
});

Vue.filter('formatDate', (value) => {
  if (value) {
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Europe/London',
    };
    const date = new Date(String(value));
    return date.toLocaleString('en-GB', options);
  }
});

Vue.filter('percent', (value) => {
  if (value >= 0 && value <= 1) {
    return `${Math.round(value * 100)} %`;
  }
  console.log('The "percent" filter only accepts values between 0 and 1');
  return '';
});

Vue.filter('yesno', (value) => (value ? 'Yes' : 'No'));

// Register the component globally.;
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('v-select', vSelect);
Vue.component('VOffline', require('v-offline'));

Vue.use(Auth);
Vue.use(BootstrapVue);
Vue.use(vueScrollto);
Vue.use(IconsPlugin);
Vue.use(VueMonitor);
Vue.use(VueBreadcrumbs);
Vue.use(BVToastPlugin);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_UA },
});

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_SESSION_TIMEOUT,
  startAtIdle: false,
});

Vue.config.productionTip = false;

Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
});

Vue.filter('capitalize', function (value) {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.prototype.$tenancy = process.env.VUE_APP_REFERRAL_SITE_TENANCY;

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
  window.__store = store;
}
