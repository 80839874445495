import * as mutations from '@/store/guarantees/mutations';

export const GUARANTEES_UPDATE = 'guarantees/update';

export const updateGuarantees = (commit, guaranteesData) =>
  new Promise((resolve) => {
    if (guaranteesData) {
      commit(mutations.GUARANTEES_SET_NEW_HOME_WARRANTY, guaranteesData.new_home_warranty);
      commit(mutations.GUARANTEES_SET_NEW_HOME_WARRANTY_COPY, guaranteesData.new_home_warranty_copy);
      commit(mutations.GUARANTEES_SET_DAMP_PROOFING, guaranteesData.damp_proofing);
      commit(mutations.GUARANTEES_SET_DAMP_PROOFING_COPY, guaranteesData.damp_proofing_copy);
      commit(mutations.GUARANTEES_SET_TIMBER_TREATMENT, guaranteesData.timber_treatment);
      commit(mutations.GUARANTEES_SET_TIMBER_TREATMENT_COPY, guaranteesData.timber_treatment_copy);
      commit(mutations.GUARANTEES_SET_WINDOWS, guaranteesData.windows);
      commit(mutations.GUARANTEES_SET_WINDOWS_COPY, guaranteesData.windows_copy);
      commit(mutations.GUARANTEES_SET_ELECTRICAL_WORK, guaranteesData.electrical_work);
      commit(mutations.GUARANTEES_SET_ELECTRICAL_WORK_COPY, guaranteesData.electrical_work_copy);
      commit(mutations.GUARANTEES_SET_ROOFING, guaranteesData.roofing);
      commit(mutations.GUARANTEES_SET_ROOFING_COPY, guaranteesData.roofing_copy);
      commit(mutations.GUARANTEES_SET_CENTRAL_HEATING, guaranteesData.central_heating);
      commit(mutations.GUARANTEES_SET_CENTRAL_HEATING_COPY, guaranteesData.central_heating_copy);
      commit(mutations.GUARANTEES_SET_UNDER_PINNING, guaranteesData.underpinning);
      commit(mutations.GUARANTEES_SET_UNDER_PINNING_COPY, guaranteesData.underpinning_copy);
      commit(mutations.GUARANTEES_SET_OTHER, guaranteesData.other);
      commit(mutations.GUARANTEES_SET_OTHER_COPY, guaranteesData.other_copy);
      commit(mutations.GUARANTEES_SET_OTHER_DESCRIPTION, guaranteesData.other_description);
      commit(mutations.GUARANTEES_SET_CLAIMS, guaranteesData.claims);
      commit(mutations.GUARANTEES_SET_CLAIMS_DESCRIPTION, guaranteesData.claims_description);
    }

    resolve();
  });
