import * as mutations from '@/store/personsOccupiers/mutations';
import { fetchFromApi } from '@/store/personsOccupiers/actions';

export default {
  namespaced: true,
  state: {
    age: null,
    relationship: null,
    relationshipOther: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: ({ commit }, personId) => fetchFromApi(commit, personId),
  },
};
