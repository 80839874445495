export const BOUNDARIES_PREFIX = 'building/boundary/';
export const BOUNDARIES_SET_RIGHT = 'BOUNDARIES_SET_RIGHT';
export const BOUNDARIES_SET_LEFT = 'BOUNDARIES_SET_LEFT';
export const BOUNDARIES_SET_FRONT = 'BOUNDARIES_SET_FRONT';
export const BOUNDARIES_SET_REAR = 'BOUNDARIES_SET_REAR';
export const BOUNDARIES_SET_IRREGULAR = 'BOUNDARIES_SET_IRREGULAR';
export const BOUNDARIES_SET_FEATURE_MOVED = 'BOUNDARIES_SET_FEATURE_MOVED';
export const BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION = 'BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION';
export const BOUNDARIES_SET_LAND_SOLD_PURCHASED = 'BOUNDARIES_SET_LAND_SOLD_PURCHASED';
export const BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION = 'BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION';
export const BOUNDARIES_SET_OVERHANG_PROJECT_UNDER = 'BOUNDARIES_SET_OVERHANG_PROJECT_UNDER';
export const BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION = 'BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION';
export const BOUNDARIES_SET_PARTY_WALL_ACT = 'BOUNDARIES_SET_PARTY_WALL_ACT';
export const BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE = 'BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE';
export const BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION = 'BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION';
export const BOUNDARIES_LOADED = 'BOUNDARIES_LOADED';

export const mutations = {
  [BOUNDARIES_SET_RIGHT](state, value) {
    state.boundaryRight = value;
  },
  [BOUNDARIES_SET_LEFT](state, value) {
    state.boundaryLeft = value;
  },
  [BOUNDARIES_SET_FRONT](state, value) {
    state.boundaryFront = value;
  },
  [BOUNDARIES_SET_REAR](state, value) {
    state.boundaryRear = value;
  },
  [BOUNDARIES_SET_IRREGULAR](state, value) {
    state.irregular = value;
  },
  [BOUNDARIES_SET_FEATURE_MOVED](state, value) {
    state.featureMoved = value;
  },
  [BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION](state, value) {
    state.featureMovedDescription = value;
  },
  [BOUNDARIES_SET_LAND_SOLD_PURCHASED](state, value) {
    state.landSoldPurchased = value;
  },
  [BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION](state, value) {
    state.landSoldPurchasedDescription = value;
  },
  [BOUNDARIES_SET_OVERHANG_PROJECT_UNDER](state, value) {
    state.overhangProjectUnder = value;
  },
  [BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION](state, value) {
    state.overhangProjectUnderDescription = value;
  },
  [BOUNDARIES_SET_PARTY_WALL_ACT](state, value) {
    state.partyWallAct = value;
  },
  [BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE](state, value) {
    state.partyWallActNotice = value;
  },
  [BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION](state, value) {
    state.partyWallActDescription = value;
  },
  [BOUNDARIES_LOADED](state) {
    state.loaded = true;
  },
};
