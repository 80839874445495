import * as mutations from '@/store/consents/mutations';
import { updateConsents } from '@/store/consents/actions';

export default {
  namespaced: true,
  state: {
    leaseTermsChange: null,
    leaseTermsChangeDocument: null,
    leaseTermsChangeDescription: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, consentsData) => updateConsents(commit, consentsData),
  },
};
