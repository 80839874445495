import * as mutations from '@/store/utility-connections/mutations';

export const UTILITY_CONNECTIONS_UPDATE = 'utilityConnections/update';

export const updateUtilityConnections = (commit, utilityConnectionsData) =>
  new Promise((resolve) => {
    if (utilityConnectionsData) {
      commit(mutations.UTILITY_CONNECTIONS_SET_ELECTRICITY, utilityConnectionsData.electricity);
      commit(
        mutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_PROVIDER_NAME,
        utilityConnectionsData.electricity_provider_name,
      );
      commit(
        mutations.UTILITY_CONNECTIONS_SET_ELECTRICITY_LOCATION_OF_METER,
        utilityConnectionsData.electricity_location_of_meter,
      );
      commit(mutations.UTILITY_CONNECTIONS_SET_GAS, utilityConnectionsData.gas);
      commit(mutations.UTILITY_CONNECTIONS_SET_GAS_PROVIDER_NAME, utilityConnectionsData.gas_provider_name);
      commit(mutations.UTILITY_CONNECTIONS_SET_GAS_LOCATION_OF_METER, utilityConnectionsData.gas_location_of_meter);
      commit(mutations.UTILITY_CONNECTIONS_SET_WATER, utilityConnectionsData.water);
      commit(mutations.UTILITY_CONNECTIONS_SET_WATER_PROVIDER_NAME, utilityConnectionsData.water_provider_name);
      commit(
        mutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_STOPCOCK,
        utilityConnectionsData.water_location_of_stopcock,
      );
      commit(mutations.UTILITY_CONNECTIONS_SET_WATER_LOCATION_OF_METER, utilityConnectionsData.water_location_of_meter);
      commit(mutations.UTILITY_CONNECTIONS_SET_SEWERAGE, utilityConnectionsData.sewerage);
      commit(mutations.UTILITY_CONNECTIONS_SET_SEWERAGE_PROVIDER_NAME, utilityConnectionsData.sewerage_provider_name);
      commit(mutations.UTILITY_CONNECTIONS_SET_TELEPHONE, utilityConnectionsData.telephone);
      commit(mutations.UTILITY_CONNECTIONS_SET_TELEPHONE_PROVIDER_NAME, utilityConnectionsData.telephone_provider_name);
      commit(mutations.UTILITY_CONNECTIONS_SET_CABLE, utilityConnectionsData.cable);
      commit(mutations.UTILITY_CONNECTIONS_SET_CABLE_PROVIDER_NAME, utilityConnectionsData.cable_provider_name);
    }

    resolve();
  });
