export const PERSONS_OCCUPIERS_PREFIX = 'personsOccupiers/';
export const PERSONS_OCCUPIERS_SET_AGE = 'PERSONS_OCCUPIERS_SET_TITLE';
export const PERSONS_OCCUPIERS_SET_RELATIONSHIP = 'PERSONS_OCCUPIERS_SET_FIRST_NAME';
export const PERSONS_OCCUPIERS_SET_RELATIONSHIP_OTHER = 'PERSONS_OCCUPIERS_SET_MIDDLE_NAME';
export const PERSONS_OCCUPIERS_LOADED = 'PERSONS_OCCUPIERS_LOADED';

export const mutations = {
  [PERSONS_OCCUPIERS_SET_AGE](state, value) {
    state.age = value;
  },
  [PERSONS_OCCUPIERS_SET_RELATIONSHIP](state, value) {
    state.relationship = value;
  },
  [PERSONS_OCCUPIERS_SET_RELATIONSHIP_OTHER](state, value) {
    state.relationship_other = value;
  },
  [PERSONS_OCCUPIERS_LOADED](state) {
    state.loaded = true;
  },
};
