import * as mutations from '@/store/notices/mutations';

export const NOTICES_UPDATE = 'notices/update';

export const updateNotices = (commit, noticesData) =>
  new Promise((resolve) => {
    if (noticesData) {
      commit(mutations.NOTICE_SET_RECEIVED, noticesData.notices_received);
      commit(mutations.NOTICE_SET_RECEIVED_DESCRIPTION, noticesData.notices_received_description);
      commit(mutations.NOTICE_SET_DEVELOPMENT_PROPOSALS, noticesData.development_proposals);
      commit(mutations.NOTICE_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION, noticesData.development_proposals_description);
    }

    resolve();
  });
