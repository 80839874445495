import * as VeeValidate from 'vee-validate';
import * as Rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import axios from 'axios';

// Install rules
VeeValidate.extend('required', Rules.required);
VeeValidate.extend('alpha_spaces', Rules.alpha_spaces);
VeeValidate.extend('min', Rules.min);
VeeValidate.extend('max', Rules.max);
VeeValidate.extend('email', Rules.email);
VeeValidate.extend('numeric', Rules.numeric);
VeeValidate.extend('regex', Rules.regex);
VeeValidate.extend('is', Rules.is);
VeeValidate.extend('min_value', Rules.min_value);
VeeValidate.extend('required_if', Rules.required_if);
VeeValidate.extend('currency', {
  validate(value) {
    const regex = /^(?:0|[1-9][0-9]*)(?:\.[0-9]{2})?$/;
    return regex.test(value);
  },
  message: 'The field must be numeric and have maximum 2 decimals',
});
VeeValidate.extend('ni_number', {
  validate(value) {
    const regex = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/i;
    return regex.test(value.replace(/[ -]/g, ''));
  },
  message: 'The NI number should match the official format',
});
VeeValidate.extend('date_ddmmyyyy', {
  validate(value) {
    const regex =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
    return regex.test(value.replace(/[ -]/g, '')) && value.length >= 10;
  },
  message: 'Please enter a date in a DD/MM/YYYY format, ie 24/05/1987',
});
VeeValidate.extend('date_yyyy', {
  validate(value) {
    const regex = /^(19|20)\d{2}$/;
    return regex.test(value);
  },
  message: 'Please enter a date in a YYYY format, ie 1987',
});
VeeValidate.extend('dob_minimum_age', {
  params: ['age'],
  validate: (value, { age }) => {
    // Validate age argument
    if (typeof age === 'string') {
      age = parseInt(age);
    }
    if (!(Number.isInteger(age) && age >= 0)) {
      console.log('Minimum age must be a valid numerical value greater than 0');
      return false;
    }

    // Get the comparison date, which is the current date
    // minus the specified number of years
    const comparisonDate = new Date();
    comparisonDate.setHours(0, 0, 0, 0);
    comparisonDate.setFullYear(comparisonDate.getFullYear() - age);

    // Parse input date value
    if (typeof value !== 'string') {
      return false;
    }
    const dateParts = value.split('/');

    var day = 1;
    var monthIndex = 0;
    var year = 0;

    // Handle both YYYY and DD/MM/YYYY formats
    if (dateParts.length === 1) {
      year = parseInt(dateParts[0], 10);
    } else if (dateParts.length === 3) {
      day = parseInt(dateParts[0], 10);
      monthIndex = parseInt(dateParts[1], 10) - 1;
      year = parseInt(dateParts[2], 10);
    } else {
      return false;
    }

    // Compare input date with minimum age
    const inputDate = new Date(year, monthIndex, day);
    const atLeastMinimumAge = comparisonDate - inputDate >= 0;
    return atLeastMinimumAge;
  },
  message: 'The person must be {age} or over',
});
VeeValidate.extend('phone_number', {
  validate(value) {
    // In the following regex, the first alternative is a UK
    // phone number and the second is a non-UK international phone number.
    const regex = /^(?:(?:0|\+?44)\s?(?:\d\s?){8,9}\d|\+(?!44)(?:\d\s?){6,14}\d)$/;
    return regex.test(value);
  },
  message: 'The phone number must be a valid UK or international phone number',
});
VeeValidate.extend('mobile_number', {
  validate(value) {
    // In the following regex, the first alternative is a UK
    // mobile number and the second is a non-UK international phone number.
    const regex = /^(?:(?:0|\+?44)\s?7\s?(?:\d\s?){7,8}\d|\+(?!44)(?:\d\s?){6,14}\d)$/;
    return regex.test(value);
  },
  message: 'The phone number must be a valid UK or international mobile number',
});

VeeValidate.extend('last_name', {
  validate(value) {
    const regex = /^[A-Za-z '-]{1,80}$/;
    return regex.test(value);
  },
  message: 'The last name field may only contain alphabetic characters as well as spaces, hyphen or apostrophe',
});

VeeValidate.extend('person_name', {
  params: ['personNameLabel'],
  validate(value, { personNameLabel }) {
    const regex = /^[A-Za-z '-]{1,80}$/;
    const message = `The ${personNameLabel} field may only contain alphabetic characters as well as spaces, hyphen or apostrophe`;
    return regex.test(value) || message;
  },
});

VeeValidate.extend('postcode', {
  validate(value) {
    const regex = /^[A-Z]{1,2}[0-9][A-Z0-9]? {1}?[0-9][A-Z]{2}$/;
    return regex.test(value);
  },
  message: 'The postcode is in an incorrect format',
});

VeeValidate.extend('confirmation', {
  validate(value) {
    return value;
  },
  message: 'Please tick this confirmation checkbox in order to continue',
});

VeeValidate.extend('seventeen_or_over', {
  validate(value) {
    return value >= 17;
  },
  message: 'Please add all adults aged 17 or over',
});

VeeValidate.extend('erc_present', {
  validate(value) {
    return value !== 'DO_NOT_KNOW';
  },
  message: 'Please select a valid option',
});

VeeValidate.extend('account_name', {
  validate(value) {
    const regex = /^[A-Za-z0-9-][A-Za-z0-9 -]*$/;
    return regex.test(value);
  },
  message: 'The account name may only include alphanumeric characters, spaces and hyphens',
});

VeeValidate.extend('account_number', {
  validate(value) {
    const regex = /^[0-9]{8}$/;
    return regex.test(value);
  },
  message:
    'The account number should be an 8-digit number. If your account number is only 7 digits long, please add a 0 to the start of the number.',
});

VeeValidate.extend('sort_code', {
  validate(value) {
    const regex = /^([0-9]{6}|[0-9]{2}-[0-9]{2}-[0-9]{2})$/;
    return regex.test(value);
  },
  message: 'The sort code should be a 6-digit number (hyphens are optional)',
});

VeeValidate.extend('valid_email_domain', {
  validate: async (value) => {
    // No domain to validate
    if (!value || !value.includes('@')) {
      return true;
    }
    const domain = value.split('@').pop();
    const result = await axios
      .get('https://dns.google/resolve', {
        params: { name: domain, type: 'MX' },
        headers: { accept: 'application/dns-json' },
      })
      .catch(() => ({ data: { Status: 0 } }));
    // See: https://www.iana.org/assignments/dns-parameters/dns-parameters.xhtml#dns-parameters-6
    // 3 is a non existent domain
    return +result?.data?.Status !== 3;
  },
  message: 'This email is invalid. Please enter a valid email address.',
});
VeeValidate.extend('unique_email', {
  params: ['otherClientEmails'],
  validate: (value, { otherClientEmails }) => {
    // Check if we have multiple clients in the case if so an array of objects will exist
    if (Array.isArray(otherClientEmails)) {
      // Filter the array of client objects and check to see if the email entered by the end user matches an email against one of the client in the object array
      const matched = otherClientEmails.filter((v) => v.email.toLowerCase() === value.toLowerCase());
      if (matched.length > 0) {
        // Get the name details from the client that has the matched email entered in by the end user which will be used in the displayed validation error message
        return `This email is already in use by ${matched[0]['firstName']} ${matched[0]['lastName']}, another client in this case. Please provide a valid unique email in order to receive your individual documents.`;
      }
      // If the email doesn't match any emails in the array we can return the email as valid.
      return otherClientEmails.email?.toLowerCase() !== value?.toLowerCase();
    } else if (
      typeof otherClientEmails === 'undefined' ||
      otherClientEmails.email?.toLowerCase() !== value?.toLowerCase()
    ) {
      return true;
    }
    return `This email is already in use by ${otherClientEmails.firstName} ${otherClientEmails.lastName}, another client in this case. Please provide a valid unique email in order to receive your individual documents.`;
  },
});

VeeValidate.extend('unique_phone_mobile', {
  params: ['otherClientPhoneMobileNumbers'],
  validate: (value, { otherClientPhoneMobileNumbers }) => {
    // If multiple clients exist an array of objects will exist so perform this check first
    if (Array.isArray(otherClientPhoneMobileNumbers)) {
      // Filter the array of clients to check to see if the mobile number enetered by the end user matches any of the client in the array
      const matchingClients = otherClientPhoneMobileNumbers.filter((v) => v.mobile === value);
      // If the value entered by the end user matches any of the clients in our array then display a validation error to the user stating who the number is in use by
      if (matchingClients.length > 0) {
        return `This mobile number is already being used by ${matchingClients[0]['firstName']} ${matchingClients[0]['lastName']}. Please provide a unique mobile number`;
      }
      return otherClientPhoneMobileNumbers.mobile !== value;
    } else if (typeof otherClientPhoneMobileNumbers === 'undefined' || otherClientPhoneMobileNumbers.mobile !== value) {
      return true;
    }
    return `This mobile number is already being used by ${otherClientPhoneMobileNumbers.firstName} ${otherClientPhoneMobileNumbers.lastName}. Please provide a valid unique mobile number`;
  },
});

VeeValidate.extend('unique_phone_home', {
  params: ['otherClientPhoneHomeNumbers'],
  validate: (value, { otherClientPhoneHomeNumbers }) => {
    if (Array.isArray(otherClientPhoneHomeNumbers)) {
      return !otherClientPhoneHomeNumbers.includes(value);
    } else if (typeof otherClientPhoneHomeNumbers === 'undefined') {
      return true;
    } else {
      return otherClientPhoneHomeNumbers !== value;
    }
  },
  message: 'To use the same home phone number a unqiue mobile number must be provided for each client',
});

VeeValidate.extend('password_match', {
  params: ['password'],
  validate(value, { password }) {
    return value === password;
  },
  message: 'Password confirmation does not match',
});

VeeValidate.extend('contains_a_symbol', {
  message: (field) => `The ${field} must contain at least 1 symbol.`,
  validate: (value) => /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]+/.test(value),
});

VeeValidate.extend('contains_a_number', {
  message: (field) => `The ${field} must contain at least 1 number.`,
  validate: (value) => /[0-9]+/.test(value),
});

VeeValidate.extend('contains_lowercase', {
  message: (field) => `The ${field} must contain at least 1 lowercase.`,
  validate: (value) => /[a-z]+/.test(value),
});

VeeValidate.extend('contains_uppercase', {
  message: (field) => `The ${field} must contain at least 1 uppercase.`,
  validate: (value) => /[A-Z]+/.test(value),
});

VeeValidate.extend('password_length', {
  validate(value) {
    return value.length >= 8;
  },
  message: 'Password must be at least 8 characters in length',
});

VeeValidate.extend('first_line_address', {
  // We want to check to make sure the client has not entered the same building number twice on the form.
  // Check the value in Address Line 1 field against the building name/number field, if they start with the same number then inform the client they have duplicated it.
  params: ['buildingName'],
  validate(value, { buildingName }) {
    return !buildingName || !value || !value.startsWith(buildingName);
  },
  message: 'You have already entered the building name/number above',
});

// Install messages
VeeValidate.localize({
  en,
});
