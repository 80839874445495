import * as mutations from '@/store/environmentalMatter/mutations';

export const ENVIRONMENTAL_MATTERS_UPDATE = 'environmentalMatters/update';

export const updateEnvironmentalMatters = (commit, environmentalMattersData) =>
  new Promise((resolve) => {
    if (environmentalMattersData) {
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_FLOODING, environmentalMattersData.flooding);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION, environmentalMattersData.flooding_description);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_GROUND_WATER, environmentalMattersData.ground_water);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING, environmentalMattersData.sewer_flooding);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER, environmentalMattersData.surface_water);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING, environmentalMattersData.coastal_flooding);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING, environmentalMattersData.river_flooding);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_OTHER, environmentalMattersData.other);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION, environmentalMattersData.other_description);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT, environmentalMattersData.flood_risk_report);
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY,
        environmentalMattersData.flood_risk_report_copy,
      );
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST, environmentalMattersData.radon_test);
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT, environmentalMattersData.radon_test_report);
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL,
        environmentalMattersData.radon_test_below_recommended_action_level,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES,
        environmentalMattersData.radon_test_remedial_measures,
      );
      commit(mutations.ENVIRONMENTAL_MATTERS_SET_EPC_COPY, environmentalMattersData.epc_copy);
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED,
        environmentalMattersData.installations_green_deal_financed,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL,
        environmentalMattersData.installations_green_deal_financed_electricity_bill,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION,
        environmentalMattersData.installations_green_deal_financed_description,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED,
        environmentalMattersData.property_affected_by_japanese_knotweed,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN,
        environmentalMattersData.japanese_knotweed_management_plan,
      );
      commit(
        mutations.ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY,
        environmentalMattersData.japanese_knotweed_management_plan_copy,
      );
    }

    resolve();
  });
