import * as mutations from '@/store/ownership/mutations';
import { updateOwnership } from '@/store/ownership/actions';

export default {
  namespaced: true,
  state: {
    freeholdControlledByTenants: null,
    freeholdNotControlledByTenants: null,
    headLeaseholderPresent: null,
    headLeaseholderControlledByTenants: null,
    managingBuildingFreeholderResponsible: null,
    managingBuildingLeaseholderResponsible: null,
    managingBuildingCompanyResponsible: null,
    managingBuildingRightToManageCompanyResponsible: null,
    managingBuildingOtherResponsible: null,
    managementCompanyDissolved: null,
    managingAgentForRentOrManagement: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, buildingId) => updateOwnership(commit, buildingId),
  },
};
