export const LEASEHOLD_SAFETY_PREFIX = 'building/leaseholdSafety/';

export const LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS = 'LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS';
export const LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY = 'LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY';
export const LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE = 'LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE';
export const LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE = 'LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE';
export const LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION =
  'LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION';
export const LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY = 'LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY';
export const LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION = 'LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION';
export const LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED =
  'LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED';
export const LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY = 'LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY';
export const LEASEHOLD_SAFETY_LOADED = 'leasehold_safety_loaded';

export const mutations = {
  [LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS](state, value) {
    state.remediationWorks = value;
  },
  [LEASEHOLD_SAFETY_SET_REMEDIATION_WORKS_COPY](state, value) {
    state.remediationWorksCopy = value;
  },
  [LEASEHOLD_SAFETY_SET_QUALIFYING_LEASE](state, value) {
    state.qualifyingLease = value;
  },
  [LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE](state, value) {
    state.deedOfCertificate = value;
  },
  [LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COMPLETION](state, value) {
    state.deedOfCertificateCompletion = value;
  },
  [LEASEHOLD_SAFETY_SET_DEED_OF_CERTIFICATE_COPY](state, value) {
    state.deedOfCertificateCopy = value;
  },
  [LEASEHOLD_SAFETY_SET_NOTIFIED_OF_SELL_INTENTION](state, value) {
    state.notifiedOfSellIntention = value;
  },
  [LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_RECEIVED](state, value) {
    state.landlordsCertificateReceived = value;
  },
  [LEASEHOLD_SAFETY_SET_LANDLORDS_CERTIFICATE_COPY](state, value) {
    state.landlordsCertificateCopy = value;
  },
  [LEASEHOLD_SAFETY_LOADED](state) {
    state.loaded = true;
  },
};
