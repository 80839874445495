import * as mutations from '@/store/documents/mutations';
import { addDocument, clearDocuments, fetchDocuments, removeDocument } from '@/store/documents/actions';

export default {
  namespaced: true,
  state: {
    documents: [],
    loading: false,
    showError: false,
  },
  getters: {
    documents: (state) => state.documents,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    addDocument: ({ commit }, document) => addDocument(commit, document),
    removeDocument: ({ commit }, documentId) => removeDocument(commit, documentId),
    clearDocuments: ({ commit }) => clearDocuments(commit),
    fetchDocuments: ({ commit }, entityId) => fetchDocuments(commit, entityId),
  },
};
