export const NOTICES_PREFIX = 'building/notices/';

export const NOTICE_SET_RECEIVED = 'NOTICES_SET_RECEIVED';
export const NOTICE_SET_RECEIVED_DESCRIPTION = 'NOTICES_SET_RECEIVED_DESCRIPTION';
export const NOTICE_SET_DEVELOPMENT_PROPOSALS = 'NOTICES_SET_DEVELOPMENT_PROPOSALS';
export const NOTICE_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION = 'NOTICES_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION';
export const NOTICE_LOADED = 'NOTICE_LOADED';

export const mutations = {
  [NOTICE_SET_RECEIVED](state, value) {
    state.noticesReceived = value;
  },
  [NOTICE_SET_RECEIVED_DESCRIPTION](state, value) {
    state.noticesReceivedDescription = value;
  },
  [NOTICE_SET_DEVELOPMENT_PROPOSALS](state, value) {
    state.developmentProposals = value;
  },
  [NOTICE_SET_DEVELOPMENT_PROPOSALS_DESCRIPTION](state, value) {
    state.developmentProposalsDescription = value;
  },
  [NOTICE_LOADED](state) {
    state.loaded = true;
  },
};
