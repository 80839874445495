export const OWNERSHIP_PREFIX = 'building/ownership/';
export const OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS = 'OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS';
export const OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS = 'OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS';
export const OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT = 'OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT';
export const OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS =
  'OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS';
export const OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE =
  'OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE';
export const OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE =
  'OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE';
export const OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE =
  'OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE';
export const OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE =
  'OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE';
export const OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE = 'OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE';
export const OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED = 'OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED';
export const OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT =
  'OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT';
export const OWNERSHIP_LOADED = 'OWNERSHIP_LOADED';

export const mutations = {
  [OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS](state, value) {
    state.freeholdControlledByTenants = value;
  },
  [OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS](state, value) {
    state.freeholdNotControlledByTenants = value;
  },
  [OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT](state, value) {
    state.headLeaseholderPresent = value;
  },
  [OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS](state, value) {
    state.headLeaseholderControlledByTenants = value;
  },
  [OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE](state, value) {
    state.managingBuildingFreeholderResponsible = value;
  },
  [OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE](state, value) {
    state.managingBuildingLeaseholderResponsible = value;
  },
  [OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE](state, value) {
    state.managingBuildingCompanyResponsible = value;
  },
  [OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE](state, value) {
    state.managingBuildingRightToManageCompanyResponsible = value;
  },
  [OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE](state, value) {
    state.managingBuildingOtherResponsible = value;
  },
  [OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED](state, value) {
    state.managementCompanyDissolved = value;
  },
  [OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT](state, value) {
    state.managingAgentForRentOrManagement = value;
  },
  [OWNERSHIP_LOADED](state) {
    state.loaded = true;
  },
};
