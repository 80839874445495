import * as mutations from '@/store/alterations/mutations';

export const ALTERATIONS_UPDATE = 'alterations/update';

export const updateAlterations = (commit, alterationsData) =>
  new Promise((resolve) => {
    if (alterationsData) {
      commit(mutations.ALTERATIONS_SET_BUILDING_WORKS, alterationsData.building_works);
      commit(mutations.ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION, alterationsData.building_works_description);
      commit(mutations.ALTERATIONS_SET_CHANGE_OF_USE, alterationsData.change_of_use);
      commit(mutations.ALTERATIONS_SET_CHANGE_OF_USE_YEAR, alterationsData.change_of_use_year);
      commit(
        mutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION,
        alterationsData.replacement_window_installation,
      );
      commit(
        mutations.ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR,
        alterationsData.replacement_window_installation_year,
      );
      commit(mutations.ALTERATIONS_SET_CONSERVATORY_ADDITION, alterationsData.conservatory_addition);
      commit(mutations.ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR, alterationsData.conservatory_addition_year);
      commit(
        mutations.ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION,
        alterationsData.planning_permission_not_required_description,
      );
      commit(mutations.ALTERATIONS_SET_CHANGES_UNFINISHED, alterationsData.changes_unfinished);
      commit(mutations.ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION, alterationsData.changes_unfinished_description);
      commit(
        mutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED,
        alterationsData.planning_permission_conditions_breached,
      );
      commit(
        mutations.ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION,
        alterationsData.planning_permission_conditions_breached_description,
      );
      commit(
        mutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE,
        alterationsData.building_control_issues_to_resolve,
      );
      commit(
        mutations.ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION,
        alterationsData.building_control_issues_to_resolve_description,
      );
      commit(mutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED, alterationsData.solar_panels_installed);
      commit(mutations.ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR, alterationsData.solar_panels_installed_year);
      commit(mutations.ALTERATIONS_SET_SOLAR_PANELS_OWNED, alterationsData.solar_panels_owned);
      commit(mutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE, alterationsData.solar_panels_long_lease);
      commit(
        mutations.ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS,
        alterationsData.solar_panels_long_lease_documents,
      );
      commit(mutations.ALTERATIONS_SET_LISTED_BUILDING, alterationsData.listed_building);
      commit(mutations.ALTERATIONS_SET_CONSERVATION_AREA, alterationsData.conservation_area);
      commit(
        mutations.ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS,
        alterationsData.listed_building_conservation_area_documents,
      );
      commit(
        mutations.ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER,
        alterationsData.trees_subject_to_preservation_order,
      );
      commit(
        mutations.ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH,
        alterationsData.preservation_order_complied_with,
      );
      commit(
        mutations.ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS,
        alterationsData.preservation_order_relevant_documents,
      );
    }

    resolve();
  });
