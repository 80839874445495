<script>
export default {
  name: 'SessionTimeOut',
  data() {
    return {
      time: 60,
    };
  },
  created() {
    let timerId = setInterval(() => {
      // Countdown to timeout
      this.time -= 1;
      if (this.time < 1) {
        clearInterval(timerId);
        this.$gtag.event('Toggle', {
          event_category: 'System',
          event_label: 'Session Timeout',
        });
        this.$emit('logout');
      }
    }, 1000);
  },
};
</script>

<template>
  <div id="page-content">
    <p>
      You’ve been inactive for quite a while. If you wish to stay logged in please choose "Stay Logged In", otherwise
      you will be logged out in {{ time }} sec...
    </p>
  </div>
</template>

<style scoped>
#page-content {
  height: 100%;
  font-size: 1rem;
  text-align: center;
}

#page-content th {
  vertical-align: top;
}
</style>
