import * as mutations from '@/store/insurance/mutations';

export const INSURANCE_UPDATE = 'insurance/update';

export const updateInsurance = (commit, insuranceData) =>
  new Promise((resolve) => {
    if (insuranceData) {
      commit(mutations.INSURANCE_SET_BUILDING_INSURED, insuranceData.building_insured);
      commit(mutations.INSURANCE_SET_BUILDING_INSURED_DETAILS, insuranceData.building_insured_details);
      commit(mutations.INSURANCE_SET_BUILDING_LANDLORD_INSURED, insuranceData.landlord_insured);
      commit(mutations.INSURANCE_SET_PREMIUM_RISE, insuranceData.premium_rise);
      commit(mutations.INSURANCE_SET_HIGH_EXCESSES, insuranceData.high_excesses);
      commit(mutations.INSURANCE_SET_UNUSUAL_CONDITIONS, insuranceData.unusual_conditions);
      commit(mutations.INSURANCE_SET_REFUSED, insuranceData.refused);
      commit(mutations.INSURANCE_SET_INSURANCE_PROBLEMS_DESCRIPTION, insuranceData.insurance_problems_description);
      commit(mutations.INSURANCE_SET_CLAIMS, insuranceData.claims);
      commit(mutations.INSURANCE_SET_CLAIMS_DESCRIPTION, insuranceData.claims_description);
    }

    resolve();
  });
