export const LEASEHOLD_INFORMATION_PREFIX = 'building/leaseholdInformation/';

export const LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE = 'LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE';
export const LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP = 'LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP';
export const LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT = 'LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT';
export const LEASEHOLD_INFORMATION_SET_YEARLY_RENT = 'LEASEHOLD_INFORMATION_SET_YEARLY_RENT';
export const LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY = 'LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY';
export const LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE = 'LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE';
export const LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY = 'LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY';
export const LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION =
  'LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION';
export const LEASEHOLD_INFORMATION_LOADED = 'LEASEHOLD_INFORMATION_LOADED';

export const mutations = {
  [LEASEHOLD_INFORMATION_SET_LEASEHOLD_TYPE](state, value) {
    state.leaseholdType = value;
  },
  [LEASEHOLD_INFORMATION_SET_SHARED_OWNERSHIP](state, value) {
    state.sharedOwnership = value;
  },
  [LEASEHOLD_INFORMATION_SET_SELLER_PAYS_RENT](state, value) {
    state.sellerPaysRent = value;
  },
  [LEASEHOLD_INFORMATION_SET_YEARLY_RENT](state, value) {
    state.yearlyRent = value;
  },
  [LEASEHOLD_INFORMATION_SET_RENT_PAYMENT_FREQUENCY](state, value) {
    state.rentPaymentFrequency = value;
  },
  [LEASEHOLD_INFORMATION_SET_RENT_SUBJECT_TO_INCREASE](state, value) {
    state.rentSubjectToIncrease = value;
  },
  [LEASEHOLD_INFORMATION_SET_RENT_REVISION_FREQUENCY](state, value) {
    state.rentRevisionFrequency = value;
  },
  [LEASEHOLD_INFORMATION_SET_INCREASE_CALCULATION_DESCRIPTION](state, value) {
    state.increaseCalculationDescription = value;
  },
  [LEASEHOLD_INFORMATION_LOADED](state) {
    state.loaded = true;
  },
};
