import * as mutations from '@/store/insurance/mutations';
import { updateInsurance } from '@/store/insurance/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    buildingInsured: null,
    buildingInsuredDetails: null,
    landlordInsured: null,
    premiumRise: null,
    highExcesses: null,
    unusualConditions: null,
    refused: null,
    insuranceProblemsDescription: null,
    claims: null,
    claimsDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, insuranceData) => updateInsurance(commit, insuranceData),
  },
};
