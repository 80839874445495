export const SALE_PREFIX = 'sale/';

export const SALE_SET_SALE_ID = 'SALE_SET_SALE_ID';
export const SALE_SET_CLIENT_IDS = 'SALE_SET_CLIENT_IDS';
export const SALE_SET_AUTHORITY_PURCHASE = 'SALE_SET_AUTHORITY_PURCHASE';
export const SALE_SET_AUTHORITY_PURCHASE_DETAILS = 'SALE_SET_AUTHORITY_PURCHASE_DETAILS';
export const SALE_SET_PROPERTY_TYPE = 'SALE_SET_PROPERTY_TYPE';
export const SALE_SET_PROPERTY_TYPE_OTHER = 'SALE_SET_PROPERTY_TYPE_OTHER';
export const SALE_SET_PURCHASE_TYPE = 'SALE_SET_PURCHASE_TYPE';
export const SALE_SET_COUNCIL_TAX_BAND = 'SALE_SET_COUNCIL_TAX_BAND';
export const SALE_SET_BUYER_NAME = 'SALE_SET_BUYER_NAME';
export const SALE_SET_PRICE = 'SALE_SET_PRICE';
export const SALE_SET_POST_COMPLETION_PHONE = 'SALE_SET_POST_COMPLETION_PHONE';
export const SALE_SET_POST_COMPLETION_ADDRESS = 'SALE_SET_POST_COMPLETION_ADDRESS';
export const SALE_SET_ESTATE_AGENT_ID = 'SALE_SET_ESTATE_AGENT_ID';
export const SALE_SET_FIXTURE_PRICE = 'SALE_SET_FIXTURE_PRICE';
export const SALE_SET_AGENT_FEE = 'SALE_SET_AGENT_FEE';
export const SALE_SET_AGENT_FEE_TYPE = 'SALE_SET_AGENT_FEE_TYPE';
export const SALE_SET_AGENT_FEE_PAYMENT_PERMISSION = 'SALE_SET_AGENT_FEE_PAYMENT_PERMISSION';
export const SALE_SET_RELATED_CASE = 'SALE_SET_RELATED_CASE';
export const SALE_SET_RELATED_CASES = 'SALE_SET_RELATED_CASES';
export const SALE_UPDATED_RELATED_CASES_ITEM = 'SALE_UPDATED_RELATED_CASES_ITEM';
export const SALE_ADD_NEW_RELATED_CASE = 'SALE_ADD_NEW_RELATED_CASE';
export const SALE_SET_SPECIAL_PURCHASE = 'SALE_SET_SPECIAL_PURCHASE';
export const SALE_SET_SPECIAL_PURCHASE_DETAILS = 'SALE_SET_SPECIAL_PURCHASE_DETAILS';
export const SALE_SET_OCCUPIER_ID = 'SALE_SET_OCCUPIER_ID';
export const SALE_SET_OCCUPIER_IDS = 'SALE_SET_OCCUPIER_IDS';
export const SALE_REMOVE_OCCUPIER_ID = 'SALE_REMOVE_OCCUPIER_ID';
export const SALE_UPDATE_OCCUPIER_ID = 'SALE_UPDATE_OCCUPIER_ID';
export const SALE_SET_MORTGAGES_OR_CHARGES = 'SALE_SET_MORTGAGES_OR_CHARGES';
export const SALE_ADD_MORTGAGES_OR_CHARGES = 'SALE_ADD_MORTGAGES_OR_CHARGES';
export const SALE_SET_LEASEHOLD_TO_FREEHOLD = 'SALE_SET_LEASEHOLD_TO_FREEHOLD';
export const SALE_SET_TITLE_NUMBERS = 'SALE_SET_TITLE_NUMBERS';
export const SALE_SET_DESIRED_COMPLETION_DATE = 'SALE_SET_DESIRED_COMPLETION_DATE';
export const SALE_SET_EXCHANGE_DEADLINE = 'SALE_SET_EXCHANGE_DEADLINE';
export const SALE_SET_COMPLETION_DEADLINE = 'SALE_SET_COMPLETION_DEADLINE';
export const SALE_SET_SURPLUS_PAYMENT_METHOD = 'SALE_SET_SURPLUS_PAYMENT_METHOD';
export const SALE_SET_BUILDING_ID = 'SALE_SET_BUILDING_ID';
export const SALE_SET_CLIENTS_DETAILS = 'SALE_SET_CLIENTS_DETAILS';
export const SALE_SET_BUILDING_DETAILS = 'SALE_SET_BUILDING_DETAILS';
export const SALE_SET_SORT_REFER_ID = 'SALE_SET_SORT_REFER_ID';
export const SALE_SET_SUPPLIER_INFORMATION = 'SALE_SET_SUPPLIER_INFORMATION';
export const SALE_SET_SAGEPAY_TRANSACTION_ID = 'SALE_SET_SAGEPAY_TRANSACTION_ID';
export const SALE_SET_CHIEF_OR_GROUND_RENT = 'SALE_SET_CHIEF_OR_GROUND_RENT';
export const SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS = 'SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS';
export const SALE_SET_CHIEF_OR_GROUND_RENT_PHONE = 'SALE_SET_CHIEF_OR_GROUND_RENT_PHONE';
export const SALE_SET_PRODUCT_CODE = 'SALE_SET_PRODUCT_CODE';
export const SALE_LOADED = 'SALE_LOADED';
export const SALE_SET_TRACKING_EVENTS = 'SALE_SET_TRACKING_EVENTS';
export const SALE_ADD_TRACKING_EVENT = 'SALE_ADD_TRACKING_EVENT';
export const SALE_SET_CONFIG = 'SALE_SET_CONFIG';
export const SALE_SET_EVENT_TO_HANDLE = 'SALE_SET_EVENT_TO_HANDLE';
export const SALE_SALE_ADDRESS_UPDATED = 'SALE_SALE_ADDRESS_UPDATED';
export const SALE_SET_SOLD_BY_ESTATE_AGENT = 'SALE_SET_SOLD_BY_ESTATE_AGENT';
export const SALE_SET_PASS_STATEMENTS_TO_BROKER = 'SALE_SET_PASS_STATEMENTS_TO_BROKER';
export const SALE_SET_BANK_NAME = 'SALE_SET_BANK_NAME';
export const SALE_SET_BANK_ACCOUNT_NAME = 'SALE_SET_BANK_ACCOUNT_NAME';
export const SALE_SET_BANK_ACCOUNT_NUMBER = 'SALE_SET_BANK_ACCOUNT_NUMBER';
export const SALE_SET_BANK_BACS_OR_CHAPS = 'SALE_SET_BANK_BACS_OR_CHAPS';
export const SALE_SET_BANK_SORT_CODE = 'SALE_SET_BANK_SORT_CODE';
export const SALE_RESET_BANK_DETAILS = 'SALE_RESET_BANK_DETAILS';
export const SALE_SET_ADDITIONAL_INFO = 'SALE_SET_ADDITIONAL_INFO';
export const SALE_SET_SOLD_BY_COMPANY = 'SALE_SET_SOLD_BY_COMPANY';
export const SALE_SET_SOLD_BY_COMPANY_DETAILS = 'SALE_SET_SOLD_BY_COMPANY_DETAILS';
export const SALE_SET_TITLE_DEED_DECLARED = 'SALE_SET_TITLE_DEED_DECLARED';
export const SALE_SET_TITLE_DEED_ALTERNATIVE = 'SALE_SET_TITLE_DEED_ALTERNATIVE';
export const SALE_SET_TITLE_DEED_ALTERNATIVES = 'SALE_SET_TITLE_DEED_ALTERNATIVES';
export const SALE_ADD_TITLE_DEED_ALTERNATIVES = 'SALE_ADD_TITLE_DEED_ALTERNATIVES';
export const SALE_SET_SUBMISSION_STATUS = 'SALE_SET_SUBMISSION_STATUS';
export const SALE_SET_CLIENT_MATCH_DETAILS = 'SALE_SET_CLIENT_MATCH_DETAILS';
export const SALE_SET_HMLR_TITLE_DOCUMENT_ID = 'SALE_SET_HMLR_TITLE_DOCUMENT_ID';
export const REMORTGAGE_SET_TRACKING_EVENTS = 'REMORTGAGE_SET_TRACKING_EVENTS';

export const mutations = {
  [SALE_SET_SALE_ID](state, value) {
    state.saleId = value;
  },
  [SALE_SET_CLIENT_IDS](state, value) {
    state.clientIds = value;
  },
  [SALE_SET_AUTHORITY_PURCHASE](state, value) {
    state.authorityPurchase = value;
  },
  [SALE_SET_AUTHORITY_PURCHASE_DETAILS](state, value) {
    state.authorityPurchaseDetails = value;
  },
  [SALE_SET_PROPERTY_TYPE](state, value) {
    state.propertyType = value;
  },
  [SALE_SET_PROPERTY_TYPE_OTHER](state, value) {
    state.propertyTypeOther = value;
  },
  [SALE_SET_PURCHASE_TYPE](state, value) {
    state.purchaseType = value;
  },
  [SALE_SET_COUNCIL_TAX_BAND](state, value) {
    state.councilTaxBand = value;
  },
  [SALE_SET_BUYER_NAME](state, value) {
    state.buyerName = value;
  },
  [SALE_SET_POST_COMPLETION_PHONE](state, value) {
    state.postCompletionPhone = value;
  },
  [SALE_SET_POST_COMPLETION_ADDRESS](state, value) {
    state.postCompletionAddress = value;
  },
  [SALE_SET_PRICE](state, value) {
    state.salePrice = value;
  },
  [SALE_SET_ESTATE_AGENT_ID](state, value) {
    state.estateAgentId = value;
  },
  [SALE_SET_FIXTURE_PRICE](state, value) {
    state.fixturePrice = value;
  },
  [SALE_SET_AGENT_FEE](state, value) {
    state.agentFee = value;
  },
  [SALE_SET_AGENT_FEE_TYPE](state, value) {
    state.agentFeeType = value;
  },
  [SALE_SET_AGENT_FEE_PAYMENT_PERMISSION](state, value) {
    state.agentFeePaymentPermission = value;
  },
  [SALE_SET_RELATED_CASE](state, value) {
    state.relatedCase = value;
  },
  [SALE_SET_RELATED_CASES](state, value) {
    state.relatedCases = value;
  },
  [SALE_UPDATED_RELATED_CASES_ITEM](state, value) {
    state.relatedCases[value.index].related_property_address = value.data;
  },
  [SALE_ADD_NEW_RELATED_CASE](state, value) {
    state.relatedCases.push(value);
  },
  [SALE_SET_SPECIAL_PURCHASE](state, value) {
    state.specialPurchase = value;
  },
  [SALE_SET_SPECIAL_PURCHASE_DETAILS](state, value) {
    state.specialPurchaseDetails = value;
  },
  [SALE_SET_OCCUPIER_ID](state, value) {
    let data = [...value];
    data.forEach((item) => state.occupierIds.push(item));
  },
  [SALE_SET_OCCUPIER_IDS](state, value) {
    state.occupierIds = value;
  },
  [SALE_REMOVE_OCCUPIER_ID](state, id) {
    const index = state.occupierIds.findIndex((item) => item.personId === id);
    state.occupierIds.splice(index, 1);
  },
  [SALE_UPDATE_OCCUPIER_ID](state, { index, payload }) {
    state.occupierIds[index] = payload;
  },
  [SALE_SET_MORTGAGES_OR_CHARGES](state, value) {
    state.mortgagesOrCharges = value;
  },
  [SALE_ADD_MORTGAGES_OR_CHARGES](state, value) {
    state.mortgagesOrCharges.push(value);
  },
  [SALE_SET_LEASEHOLD_TO_FREEHOLD](state, value) {
    state.leaseholdToFreehold = value;
  },
  [SALE_SET_TITLE_NUMBERS](state, value) {
    state.titleNumbers = value;
  },
  [SALE_SET_DESIRED_COMPLETION_DATE](state, value) {
    state.desiredCompletionDate = value;
  },
  [SALE_SET_EXCHANGE_DEADLINE](state, value) {
    state.exchangeDeadline = value;
  },
  [SALE_SET_COMPLETION_DEADLINE](state, value) {
    state.completionDeadline = value;
  },
  [SALE_SET_SURPLUS_PAYMENT_METHOD](state, value) {
    state.surplusPaymentMethod = value;
  },
  [SALE_SET_BUILDING_ID](state, value) {
    state.buildingId = value;
  },
  [SALE_SET_CLIENTS_DETAILS](state, value) {
    state.clientsDetails = value;
  },
  [SALE_SET_BUILDING_DETAILS](state, value) {
    state.buildingDetails = value;
  },
  [SALE_SET_SORT_REFER_ID](state, value) {
    state.sortReferId = value;
  },
  [SALE_SET_SUPPLIER_INFORMATION](state, value) {
    state.supplierInformation = value;
  },
  [SALE_SET_SAGEPAY_TRANSACTION_ID](state, value) {
    state.sagepayTransactionId = value;
  },
  [SALE_SET_CHIEF_OR_GROUND_RENT](state, value) {
    state.chiefOrGroundRent = value;
  },
  [SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS](state, value) {
    state.chiefOrGroundRentAddress = value;
  },
  [SALE_SET_CHIEF_OR_GROUND_RENT_PHONE](state, value) {
    state.chiefOrGroundRentPhone = value;
  },
  [SALE_SET_PRODUCT_CODE](state, value) {
    state.productCode = value;
  },
  [SALE_LOADED](state) {
    state.loaded = true;
  },
  [SALE_SET_TRACKING_EVENTS](state, value) {
    state.trackingEvents = value;
  },
  [SALE_ADD_TRACKING_EVENT](state, value) {
    state.trackingEvents.push(value);
  },
  [SALE_SET_CONFIG](state, value) {
    state.config = value;
  },
  [SALE_SET_EVENT_TO_HANDLE](state, value) {
    state.eventToHandle = value;
  },
  [SALE_SET_SOLD_BY_ESTATE_AGENT](state, value) {
    state.soldByEstateAgent = value;
  },
  [SALE_SET_BANK_NAME](state, value) {
    state.bankName = value;
  },
  [SALE_SET_BANK_ACCOUNT_NAME](state, value) {
    state.bankAccountName = value;
  },
  [SALE_SET_BANK_ACCOUNT_NUMBER](state, value) {
    state.bankAccountNumber = value;
  },
  [SALE_SET_BANK_BACS_OR_CHAPS](state, value) {
    state.bankBacsOrChaps = value;
  },
  [SALE_SET_BANK_SORT_CODE](state, value) {
    state.bankSortCode = value;
  },
  [SALE_RESET_BANK_DETAILS](state) {
    state.bankAccountName = null;
    state.bankAccountNumber = null;
    state.bankBacsOrChaps = null;
    state.bankName = null;
    state.bankSortCode = null;
  },
  [SALE_SET_ADDITIONAL_INFO](state, value) {
    state.additionalInfo = value;
  },
  [SALE_SALE_ADDRESS_UPDATED](state, value) {
    if (value) {
      // Random number is required to force vue to rerender on a property change
      // This works as a random number is set as a :key index of DOM element
      state.saleAddressUpdated = Math.floor(Math.random() * 1000);
    }
  },
  [SALE_SET_PASS_STATEMENTS_TO_BROKER](state, value) {
    state.passStatementsToBroker = value;
  },
  [SALE_SET_SOLD_BY_COMPANY](state, value) {
    state.soldByCompany = value;
  },
  [SALE_SET_SOLD_BY_COMPANY_DETAILS](state, value) {
    state.soldByCompanyDetails = value;
  },
  [SALE_SET_TITLE_DEED_DECLARED](state, value) {
    state.titleDeedDeclared = value;
  },
  [SALE_SET_TITLE_DEED_ALTERNATIVE](state, value) {
    state.titleDeedAlternative = value;
  },
  [SALE_SET_TITLE_DEED_ALTERNATIVES](state, value) {
    state.titleDeedAlternatives = value;
  },
  [SALE_ADD_TITLE_DEED_ALTERNATIVES](state, value) {
    state.titleDeedAlternatives.push(value);
  },
  [SALE_SET_SUBMISSION_STATUS](state, value) {
    state.submissionStatus = value;
  },
  [SALE_SET_CLIENT_MATCH_DETAILS](state, value) {
    state.clientMatchDetails = value;
  },
  [SALE_SET_HMLR_TITLE_DOCUMENT_ID](state, value) {
    state.hmlrTitleDocumentId = value;
  },
};
