import * as mutations from '@/store/lenders/mutations';
import { fetchFromApi } from '@/store/lenders/actions';

export default {
  namespaced: true,
  state: {
    lenders: [],
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: ({ commit }) => fetchFromApi(commit),
  },
};
