import * as mutations from '@/store/otherCharges/mutations';
import { updateOtherCharges } from '@/store/otherCharges/actions';

export default {
  namespaced: true,
  state: {
    propertyRelatedCharges: null,
    propertyRelatedChargesDetails: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, otherChargesData) => updateOtherCharges(commit, otherChargesData),
  },
};
