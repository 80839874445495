import * as mutations from '@/store/guarantees/mutations';
import { updateGuarantees } from '@/store/guarantees/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    newHomeWarranty: null,
    newHomeWarrantyCopy: null,
    dampProofing: null,
    dampProofingCopy: null,
    timberTreatment: null,
    timberTreatmentCopy: null,
    windows: null,
    windowsCopy: null,
    electricalWork: null,
    electricalWorkCopy: null,
    roofing: null,
    roofingCopy: null,
    centralHeating: null,
    centralHeatingCopy: null,
    underpinning: null,
    underpinningCopy: null,
    other: null,
    otherCopy: null,
    otherDescription: null,
    claims: null,
    claimsDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, guaranteesData) => updateGuarantees(commit, guaranteesData),
  },
};
