import * as mutations from '@/store/occupiers/mutations';

export const OCCUPIERS_UPDATE = 'occupiers/update';

export const updateOccupiers = (commit, occupiersData) =>
  new Promise((resolve) => {
    if (occupiersData) {
      commit(mutations.OCCUPIERS_SET_SELLER_LIVES_AT_PROPERTY, occupiersData.seller_lives_at_property);
      commit(
        mutations.OCCUPIERS_SET_SEVENTEEN_OR_OVER_LIVES_AT_PROPERTY,
        occupiersData.seventeen_or_over_lives_at_property,
      );
      commit(mutations.OCCUPIERS_SET_OCCUPIER_NAMES_DESCRIPTION, occupiersData.occupier_names_description);
      commit(mutations.OCCUPIERS_SET_OCCUPIERS_TENANTS, occupiersData.occupiers_tenants);
      commit(mutations.OCCUPIERS_SET_VACANT_POSSESSION, occupiersData.vacant_possession);
      commit(
        mutations.OCCUPIERS_SET_OCCUPANTS_LEAVING_BEFORE_COMPLETION,
        occupiersData.occupants_leaving_before_completion,
      );
      commit(mutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT, occupiersData.agree_to_sale_contract);
      commit(mutations.OCCUPIERS_SET_AGREE_TO_SALE_CONTRACT_DOCUMENTS, occupiersData.agree_to_sale_contract_documents);
    }

    resolve();
  });
