import * as mutations from '@/store/rooms/mutations';
import { updateRooms } from '@/store/rooms/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    roomsData: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, buildingId) => updateRooms(commit, buildingId),
  },
};
