export const PURCHASE_PREFIX = 'purchase/';

export const PURCHASE_SET_PURCHASE_ID = 'PURCHASE_SET_PURCHASE_ID';
export const PURCHASE_SET_CLIENT_IDS = 'PURCHASE_SET_CLIENT_IDS';
export const PURCHASE_SET_AUTHORITY_PURCHASE = 'PURCHASE_SET_AUTHORITY_PURCHASE';
export const PURCHASE_SET_AUTHORITY_PURCHASE_DETAILS = 'PURCHASE_SET_AUTHORITY_PURCHASE_DETAILS';
export const PURCHASE_SET_PROPERTY_TYPE = 'PURCHASE_SET_PROPERTY_TYPE';
export const PURCHASE_SET_PROPERTY_TYPE_OTHER = 'PURCHASE_SET_PROPERTY_TYPE_OTHER';
export const PURCHASE_SET_PURCHASE_TYPE = 'PURCHASE_SET_PURCHASE_TYPE';
export const PURCHASE_SET_TYPE_OF_PURCHASE = 'PURCHASE_SET_TYPE_OF_PURCHASE';
export const PURCHASE_SET_BUYER_NAME = 'PURCHASE_SET_BUYER_NAME';
export const PURCHASE_SET_PRICE = 'PURCHASE_SET_PRICE';
export const PURCHASE_SET_TYPE_OF_TENANCY = 'PURCHASE_SET_TYPE_OF_TENANCY';
export const PURCHASE_SET_TYPE_OF_TENANCY_DETAILS = 'PURCHASE_SET_TYPE_OF_TENANCY_DETAILS';
export const PURCHASE_SET_INTERIM_PHONE = 'PURCHASE_SET_INTERIM_PHONE';
export const PURCHASE_SET_INTERIM_ADDRESS = 'PURCHASE_SET_INTERIM_ADDRESS';
export const PURCHASE_SET_ESTATE_AGENT_ID = 'PURCHASE_SET_ESTATE_AGENT_ID';
export const PURCHASE_SET_FIXTURE_PRICE = 'PURCHASE_SET_FIXTURE_PRICE';
export const PURCHASE_SET_AGENT_FEE = 'PURCHASE_SET_AGENT_FEE';
export const PURCHASE_SET_AGENT_FEE_TYPE = 'PURCHASE_SET_AGENT_FEE_TYPE';
export const PURCHASE_SET_AGENT_FEE_PAYMENT_PERMISSION = 'PURCHASE_SET_AGENT_FEE_PAYMENT_PERMISSION';
export const PURCHASE_SET_RELATED_CASE = 'PURCHASE_SET_RELATED_CASE';
export const PURCHASE_SET_RELATED_CASES = 'PURCHASE_SET_RELATED_CASES';
export const PURCHASE_UPDATED_RELATED_CASES_ITEM = 'PURCHASE_UPDATED_RELATED_CASES_ITEM';
export const PURCHASE_ADD_NEW_RELATED_CASE = 'PURCHASE_ADD_NEW_RELATED_CASE';
export const PURCHASE_SET_SPECIAL_PURCHASE = 'PURCHASE_SET_SPECIAL_PURCHASE';
export const PURCHASE_SET_SPECIAL_PURCHASE_DETAILS = 'PURCHASE_SET_SPECIAL_PURCHASE_DETAILS';
export const PURCHASE_SET_OCCUPIER_ID = 'PURCHASE_SET_OCCUPIER_ID';
export const PURCHASE_SET_OCCUPIER_IDS = 'PURCHASE_SET_OCCUPIER_IDS';
export const PURCHASE_REMOVE_OCCUPIER_ID = 'PURCHASE_REMOVE_OCCUPIER_ID';
export const PURCHASE_UPDATE_OCCUPIER_ID = 'PURCHASE_UPDATE_OCCUPIER_ID';
export const PURCHASE_SET_MORTGAGES = 'PURCHASE_SET_MORTGAGES';
export const PURCHASE_ADD_MORTGAGES = 'PURCHASE_ADD_MORTGAGES';
export const PURCHASE_SET_TITLE_NUMBERS = 'PURCHASE_SET_TITLE_NUMBERS';
export const PURCHASE_SET_DESIRED_COMPLETION_DATE = 'PURCHASE_SET_DESIRED_COMPLETION_DATE';
export const PURCHASE_SET_EXCHANGE_DEADLINE = 'PURCHASE_SET_EXCHANGE_DEADLINE';
export const PURCHASE_SET_COMPLETION_DEADLINE = 'PURCHASE_SET_COMPLETION_DEADLINE';
export const PURCHASE_SET_SURPLUS_PAYMENT_METHOD = 'PURCHASE_SET_SURPLUS_PAYMENT_METHOD';
export const PURCHASE_SET_BUILDING_ID = 'PURCHASE_SET_BUILDING_ID';
export const PURCHASE_SET_CLIENTS_DETAILS = 'PURCHASE_SET_CLIENTS_DETAILS';
export const PURCHASE_SET_BUILDING_DETAILS = 'PURCHASE_SET_BUILDING_DETAILS';
export const PURCHASE_SET_SORT_REFER_ID = 'PURCHASE_SET_SORT_REFER_ID';
export const PURCHASE_SET_SUPPLIER_INFORMATION = 'PURCHASE_SET_SUPPLIER_INFORMATION';
export const PURCHASE_SET_SAGEPAY_TRANSACTION_ID = 'PURCHASE_SET_SAGEPAY_TRANSACTION_ID';
export const PURCHASE_SET_STRUCTURAL_ALTERATIONS = 'PURCHASE_SET_STRUCTURAL_ALTERATIONS';
export const PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS = 'PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS';
export const PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR = 'PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR';
export const PURCHASE_SET_FURTHER_POINTS_TO_CHECK = 'PURCHASE_SET_FURTHER_POINTS_TO_CHECK';
export const PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS = 'PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS';
export const PURCHASE_SET_BROKER_OR_IFA = 'PURCHASE_SET_BROKER_OR_IFA';
export const PURCHASE_SET_BROKER_OR_IFA_DETAILS = 'PURCHASE_SET_BROKER_OR_IFA_DETAILS';
export const PURCHASE_SET_SOURCE_OF_FUNDS = 'PURCHASE_SET_SOURCE_OF_FUNDS';
export const PURCHASE_ADD_SOURCE_OF_FUNDS = 'PURCHASE_ADD_SOURCE_OF_FUNDS';
export const PURCHASE_SET_BUILDING_INSURER = 'PURCHASE_SET_BUILDING_INSURER';
export const PURCHASE_SET_SOLD_BY_ESTATE_AGENT = 'PURCHASE_SET_SOLD_BY_ESTATE_AGENT';
export const PURCHASE_SET_SOLICITOR_CONTACT_DETAILS = 'PURCHASE_SET_SOLICITOR_CONTACT_DETAILS';
export const PURCHASE_LOADED = 'PURCHASE_LOADED';
export const PURCHASE_SET_TRACKING_EVENTS = 'PURCHASE_SET_TRACKING_EVENTS';
export const PURCHASE_ADD_TRACKING_EVENT = 'PURCHASE_ADD_TRACKING_EVENT';
export const PURCHASE_SET_CONFIG = 'PURCHASE_SET_CONFIG';
export const PURCHASE_SET_EVENT_TO_HANDLE = 'PURCHASE_SET_EVENT_TO_HANDLE';
export const PURCHASE_PURCHASE_ADDRESS_UPDATED = 'PURCHASE_PURCHASE_ADDRESS_UPDATED';
export const PURCHASE_SET_PASS_STATEMENTS_TO_BROKER = 'PURCHASE_SET_PASS_STATEMENTS_TO_BROKER';
export const PURCHASE_SET_BANK_NAME = 'PURCHASE_SET_BANK_NAME';
export const PURCHASE_SET_BANK_ACCOUNT_NAME = 'PURCHASE_SET_BANK_ACCOUNT_NAME';
export const PURCHASE_SET_BANK_ACCOUNT_NUMBER = 'PURCHASE_SET_BANK_ACCOUNT_NUMBER';
export const PURCHASE_SET_BANK_BACS_OR_CHAPS = 'PURCHASE_SET_BANK_BACS_OR_CHAPS';
export const PURCHASE_SET_BANK_SORT_CODE = 'PURCHASE_SET_BANK_SORT_CODE';
export const PURCHASE_RESET_BANK_DETAILS = 'PURCHASE_RESET_BANK_DETAILS';
export const PURCHASE_SET_ADDITIONAL_INFO = 'PURCHASE_SET_ADDITIONAL_INFO';

export const mutations = {
  [PURCHASE_SET_PURCHASE_ID](state, value) {
    state.purchaseId = value;
  },
  [PURCHASE_SET_CLIENT_IDS](state, value) {
    state.clientIds = value;
  },
  [PURCHASE_SET_AUTHORITY_PURCHASE](state, value) {
    state.authorityPurchase = value;
  },
  [PURCHASE_SET_AUTHORITY_PURCHASE_DETAILS](state, value) {
    state.authorityPurchaseDetails = value;
  },
  [PURCHASE_SET_PROPERTY_TYPE](state, value) {
    state.propertyType = value;
  },
  [PURCHASE_SET_PROPERTY_TYPE_OTHER](state, value) {
    state.propertyTypeOther = value;
  },
  [PURCHASE_SET_PURCHASE_TYPE](state, value) {
    state.purchaseType = value;
  },
  [PURCHASE_SET_TYPE_OF_PURCHASE](state, value) {
    state.typeOfPurchase = value;
  },
  [PURCHASE_SET_BUYER_NAME](state, value) {
    state.buyerName = value;
  },
  [PURCHASE_SET_INTERIM_PHONE](state, value) {
    state.interimPhone = value;
  },
  [PURCHASE_SET_INTERIM_ADDRESS](state, value) {
    state.interimAddress = value;
  },
  [PURCHASE_SET_PRICE](state, value) {
    state.purchasePrice = value;
  },
  [PURCHASE_SET_TYPE_OF_TENANCY](state, value) {
    state.typeOfTenancy = value;
  },
  [PURCHASE_SET_TYPE_OF_TENANCY_DETAILS](state, value) {
    state.typeOfTenancyDetails = value;
  },
  [PURCHASE_SET_ESTATE_AGENT_ID](state, value) {
    state.estateAgentId = value;
  },
  [PURCHASE_SET_FIXTURE_PRICE](state, value) {
    state.fixturePrice = value;
  },
  [PURCHASE_SET_AGENT_FEE](state, value) {
    state.agentFee = value;
  },
  [PURCHASE_SET_AGENT_FEE_TYPE](state, value) {
    state.agentFeeType = value;
  },
  [PURCHASE_SET_AGENT_FEE_PAYMENT_PERMISSION](state, value) {
    state.agentFeePaymentPermission = value;
  },
  [PURCHASE_SET_RELATED_CASE](state, value) {
    state.relatedCase = value;
  },
  [PURCHASE_SET_RELATED_CASES](state, value) {
    state.relatedCases = value;
  },
  [PURCHASE_UPDATED_RELATED_CASES_ITEM](state, value) {
    state.relatedCases[value.index].related_property_address = value.data;
  },
  [PURCHASE_ADD_NEW_RELATED_CASE](state, value) {
    state.relatedCases.push(value);
  },
  [PURCHASE_SET_SPECIAL_PURCHASE](state, value) {
    state.specialPurchase = value;
  },
  [PURCHASE_SET_SPECIAL_PURCHASE_DETAILS](state, value) {
    state.specialPurchaseDetails = value;
  },
  [PURCHASE_SET_OCCUPIER_ID](state, value) {
    let data = [...value];
    data.forEach((item) => state.occupierIds.push(item));
  },
  [PURCHASE_SET_OCCUPIER_IDS](state, value) {
    state.occupierIds = value;
  },
  [PURCHASE_REMOVE_OCCUPIER_ID](state, id) {
    const index = state.occupierIds.findIndex((item) => item.personId === id);
    state.occupierIds.splice(index, 1);
  },
  [PURCHASE_UPDATE_OCCUPIER_ID](state, { index, payload }) {
    state.occupierIds[index] = payload;
  },
  [PURCHASE_SET_MORTGAGES](state, value) {
    state.mortgages = value;
  },
  [PURCHASE_ADD_MORTGAGES](state, value) {
    state.mortgages.push(value);
  },
  [PURCHASE_SET_TITLE_NUMBERS](state, value) {
    state.titleNumbers = value;
  },
  [PURCHASE_SET_DESIRED_COMPLETION_DATE](state, value) {
    state.desiredCompletionDate = value;
  },
  [PURCHASE_SET_EXCHANGE_DEADLINE](state, value) {
    state.exchangeDeadline = value;
  },
  [PURCHASE_SET_COMPLETION_DEADLINE](state, value) {
    state.completionDeadline = value;
  },
  [PURCHASE_SET_SURPLUS_PAYMENT_METHOD](state, value) {
    state.surplusPaymentMethod = value;
  },
  [PURCHASE_SET_BUILDING_ID](state, value) {
    state.buildingId = value;
  },
  [PURCHASE_SET_CLIENTS_DETAILS](state, value) {
    state.clientsDetails = value;
  },
  [PURCHASE_SET_BUILDING_DETAILS](state, value) {
    state.buildingDetails = value;
  },
  [PURCHASE_SET_SORT_REFER_ID](state, value) {
    state.sortReferId = value;
  },
  [PURCHASE_SET_SUPPLIER_INFORMATION](state, value) {
    state.supplierInformation = value;
  },
  [PURCHASE_SET_SAGEPAY_TRANSACTION_ID](state, value) {
    state.sagepayTransactionId = value;
  },
  [PURCHASE_SET_STRUCTURAL_ALTERATIONS](state, value) {
    state.structuralAlterations = value;
  },
  [PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS](state, value) {
    state.structuralAlterationsDetails = value;
  },
  [PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR](state, value) {
    state.structuralAlterationsWithinTenYr = value;
  },
  [PURCHASE_SET_FURTHER_POINTS_TO_CHECK](state, value) {
    state.furtherPointsToCheck = value;
  },
  [PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS](state, value) {
    state.furtherPointsToCheckDetails = value;
  },
  [PURCHASE_SET_BROKER_OR_IFA](state, value) {
    state.brokerOrIfa = value;
  },
  [PURCHASE_SET_BROKER_OR_IFA_DETAILS](state, value) {
    state.brokerOrIfaDetails = value;
  },
  [PURCHASE_SET_SOURCE_OF_FUNDS](state, value) {
    state.sourceOfFunds = value;
  },
  [PURCHASE_ADD_SOURCE_OF_FUNDS](state, value) {
    state.sourceOfFunds.push(value);
  },
  [PURCHASE_SET_BUILDING_INSURER](state, value) {
    state.buildingInsurer = value;
  },
  [PURCHASE_SET_SOLD_BY_ESTATE_AGENT](state, value) {
    state.soldByEstateAgent = value;
  },
  [PURCHASE_SET_SOLICITOR_CONTACT_DETAILS](state, value) {
    state.solicitorContactDetails = value;
  },
  [PURCHASE_LOADED](state) {
    state.loaded = true;
  },
  [PURCHASE_SET_TRACKING_EVENTS](state, value) {
    state.trackingEvents = value;
  },
  [PURCHASE_ADD_TRACKING_EVENT](state, value) {
    state.trackingEvents.push(value);
  },
  [PURCHASE_SET_CONFIG](state, value) {
    state.config = value;
  },
  [PURCHASE_SET_EVENT_TO_HANDLE](state, value) {
    state.eventToHandle = value;
  },
  [PURCHASE_SET_BANK_NAME](state, value) {
    state.bankName = value;
  },
  [PURCHASE_SET_BANK_ACCOUNT_NAME](state, value) {
    state.bankAccountName = value;
  },
  [PURCHASE_SET_BANK_ACCOUNT_NUMBER](state, value) {
    state.bankAccountNumber = value;
  },
  [PURCHASE_SET_BANK_BACS_OR_CHAPS](state, value) {
    state.bankBacsOrChaps = value;
  },
  [PURCHASE_SET_BANK_SORT_CODE](state, value) {
    state.bankSortCode = value;
  },
  [PURCHASE_RESET_BANK_DETAILS](state) {
    state.bankAccountName = null;
    state.bankAccountNumber = null;
    state.bankBacsOrChaps = null;
    state.bankName = null;
    state.bankSortCode = null;
  },
  [PURCHASE_SET_ADDITIONAL_INFO](state, value) {
    state.additionalInfo = value;
  },
  [PURCHASE_PURCHASE_ADDRESS_UPDATED](state, value) {
    if (value) {
      // Random number is required to force vue to rerender on a property change
      // This works as a random number is set as a :key index of DOM element
      state.purchaseAddressUpdated = Math.floor(Math.random() * 1000);
    }
  },
  [PURCHASE_SET_PASS_STATEMENTS_TO_BROKER](state, value) {
    state.passStatementsToBroker = value;
  },
};
