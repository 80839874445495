export const LEASEHOLD_ALTERATIONS_PREFIX = 'building/leaseholdAlterations/';
export const LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED =
  'LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED';
export const LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION =
  'LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION';
export const LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED = 'LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED';
export const LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT =
  'LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT';
export const LEASEHOLD_ALTERATIONS_LOADED = 'LEASEHOLD_ALTERATIONS_LOADED';

export const mutations = {
  [LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED](state, value) {
    state.alterationsSinceLeaseGranted = value;
  },
  [LEASEHOLD_ALTERATIONS_SET_ALTERATIONS_SINCE_LEASE_GRANTED_DESCRIPTION](state, value) {
    state.alterationsSinceLeaseGrantedDescription = value;
  },
  [LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED](state, value) {
    state.consentObtained = value;
  },
  [LEASEHOLD_ALTERATIONS_SET_CONSENT_OBTAINED_DOCUMENT](state, value) {
    state.consentObtainedDocument = value;
  },
  [LEASEHOLD_ALTERATIONS_LOADED](state) {
    state.loaded = true;
  },
};
