export const ENVIRONMENTAL_MATTERS_PREFIX = 'building/environmentalMatters/';
export const ENVIRONMENTAL_MATTERS_SET_FLOODING = 'ENVIRONMENTAL_MATTERS_SET_FLOODING';
export const ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION = 'ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION';
export const ENVIRONMENTAL_MATTERS_SET_GROUND_WATER = 'ENVIRONMENTAL_MATTERS_SET_GROUND_WATER';
export const ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING = 'ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING';
export const ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER = 'ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER';
export const ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING = 'ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING';
export const ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING = 'ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING';
export const ENVIRONMENTAL_MATTERS_SET_OTHER = 'ENVIRONMENTAL_MATTERS_SET_OTHER';
export const ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION = 'ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION';
export const ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT = 'ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT';
export const ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY = 'ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY';
export const ENVIRONMENTAL_MATTERS_SET_RADON_TEST = 'ENVIRONMENTAL_MATTERS_SET_RADON_TEST';
export const ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT = 'ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT';
export const ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL =
  'ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL';
export const ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES =
  'ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES';
export const ENVIRONMENTAL_MATTERS_SET_EPC_COPY = 'ENVIRONMENTAL_MATTERS_SET_EPC_COPY';
export const ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED =
  'ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED';
export const ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL =
  'ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL';
export const ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION =
  'ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION';
export const ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED =
  'ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED';
export const ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN =
  'ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN';
export const ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY =
  'ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY';
export const ENVIRONMENTAL_MATTERS_LOADED = 'ENVIRONMENTAL_MATTERS_LOADED';

export const mutations = {
  [ENVIRONMENTAL_MATTERS_SET_FLOODING](state, value) {
    state.flooding = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_FLOODING_DESCRIPTION](state, value) {
    state.floodingDescription = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_GROUND_WATER](state, value) {
    state.groundWater = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_SEWER_FLOODING](state, value) {
    state.sewerFlooding = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_SURFACE_WATER](state, value) {
    state.surfaceWater = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_COASTAL_FLOODING](state, value) {
    state.coastalFlooding = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_RIVER_FLOODING](state, value) {
    state.riverFlooding = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_OTHER](state, value) {
    state.other = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_OTHER_DESCRIPTION](state, value) {
    state.otherDescription = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT](state, value) {
    state.floodRiskReport = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_FLOOD_RISK_REPORT_COPY](state, value) {
    state.floodRiskReportCopy = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_RADON_TEST](state, value) {
    state.radonTest = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REPORT](state, value) {
    state.radonTestReport = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_RADON_TEST_BELOW_RECOMMENDED_ACTION_LEVEL](state, value) {
    state.radonTestBelowRecommendedActionLevel = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_RADON_TEST_REMEDIAL_MEASURES](state, value) {
    state.radonTestRemedialMeasures = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_EPC_COPY](state, value) {
    state.epcCopy = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED](state, value) {
    state.installationsGreenDealFinanced = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_ELECTRICITY_BILL](state, value) {
    state.installationsGreenDealFinancedElectricityBill = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_INSTALLATIONS_GREEN_DEAL_FINANCED_DESCRIPTION](state, value) {
    state.installationsGreenDealFinancedDescription = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_PROPERTY_AFFECTED_BY_JAPANESE_KNOTWEED](state, value) {
    state.propertyAffectedByJapaneseKnotweed = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN](state, value) {
    state.japaneseKnotweedManagementPlan = value;
  },
  [ENVIRONMENTAL_MATTERS_SET_JAPANESE_KNOTWEED_MANAGEMENT_PLAN_COPY](state, value) {
    state.japaneseKnotweedManagementPlanCopy = value;
  },
  [ENVIRONMENTAL_MATTERS_LOADED](state) {
    state.loaded = true;
  },
};
