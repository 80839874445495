import { defaultCase } from './module';

export const PAYMENTS_SET_INDEX = 'PAYMENTS_SET_INDEX';
export const PAYMENTS_SET_CURRENT_CASE = 'PAYMENTS_SET_CURRENT_CASE';
export const PAYMENTS_SET_CUSTOMER_FIRST_NAME = 'PAYMENTS_SET_CUSTOMER_FIRST_NAME';
export const PAYMENTS_SET_CUSTOMER_LAST_NAME = 'PAYMENTS_SET_CUSTOMER_LAST_NAME';

export const PAYMENTS_SET_BILLING_ADDRESS = {
  COMPANY: 'PAYMENTS_SET_BILLING_COMPANY',
  PREMISE: 'PAYMENTS_SET_BILLING_PREMISE',
  THOROUGHFARE: 'PAYMENTS_SET_BILLING_THOROUGHFARE',
  ADDRESS1: 'PAYMENTS_SET_BILLING_ADDRESS1',
  ADDRESS2: 'PAYMENTS_SET_BILLING_ADDRESS2',
  CITY: 'PAYMENTS_SET_BILLING_CITY',
  COUNTY: 'PAYMENTS_SET_BILLING_COUNTY',
  COUNTRY: 'PAYMENTS_SET_BILLING_COUNTRY',
  POSTAL_CODE: 'PAYMENTS_SET_BILLING_POSTAL_CODE',

  DEPENDANT_LOCALITY: 'PAYMENTS_SET_BILLING_ADDRESS2',
  LOCALITY: 'PAYMENTS_SET_BILLING_CITY',
  ORGANISATION_NAME: 'PAYMENTS_SET_BILLING_COMPANY',
  SUB_ADMINISTRATIVE_AREA: 'PAYMENTS_SET_BILLING_COUNTY',
};
export const PAYMENTS_SET_PAYMENT_AMOUNT = 'PAYMENTS_SET_PAYMENT_AMOUNT';

export const mutations = {
  [PAYMENTS_SET_CURRENT_CASE](state, value) {
    state.paymentDetails.currentCase = value;
    if (!state.paymentDetails.cases.hasOwnProperty(value)) {
      state.paymentDetails.cases = { ...state.paymentDetails.cases, [value]: defaultCase };
    }
  },
  [PAYMENTS_SET_INDEX](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].index = value;
  },
  [PAYMENTS_SET_CUSTOMER_FIRST_NAME](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].customerFirstName = value;
  },
  [PAYMENTS_SET_CUSTOMER_LAST_NAME](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].customerLastName = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.PREMISE](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.premise = value;
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.address1 = `${value} ${
      state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.thoroughfare
    }`;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.THOROUGHFARE](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.thoroughfare = value;
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.address1 = `${
      state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.premise
    } ${value}`;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.COMPANY](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.company = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.ADDRESS1](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.address1 = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.ADDRESS2](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.address2 = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.CITY](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.city = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.COUNTY](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.county = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.COUNTRY](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.country = value;
  },
  [PAYMENTS_SET_BILLING_ADDRESS.POSTAL_CODE](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].billingAddress.postalCode = value;
  },
  [PAYMENTS_SET_PAYMENT_AMOUNT](state, value) {
    state.paymentDetails.cases[state.paymentDetails.currentCase].amount = value;
  },
};
