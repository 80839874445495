<script>
import Modal from '@/components/Modal';
import SessionTimeOut from '@/components/messaging/SessionTimeOut';
import VersionCheck from '@/components/messaging/VersionCheck';
import UnexpectedError from '@/components/messaging/UnexpectedError';
import { mapActions, mapState } from 'vuex';
import HoldingPage from './pages/HoldingPage.vue';

export default {
  data() {
    return {
      showTimeOutModal: false,
      showUnexpectedError: false,
      render: true,
    };
  },
  components: { Modal, SessionTimeOut, HoldingPage, VersionCheck, UnexpectedError },
  methods: {
    ...mapActions('auth', ['userLoggedOut']),
    sessionTimeoutModalAction(value) {
      if (value) {
        this.showTimeOutModal = false;
      } else {
        this.logout();
      }
    },
    logout: function () {
      this.showTimeOutModal = false;
      this.userLoggedOut();
      this.$auth.signOut().then(() => {
        this.$router.push({ name: 'loggedOut' }).catch(() => undefined);
        localStorage.clear();
        location.reload(); // to force it to clear computed data
      });
    },
    removeUnexpectedError() {
      this.$store.commit('setUnexpectedError', false);
    },
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    isUnexpectedError() {
      return this.$store.state.unexpectedError;
    },
    holdingPageActive() {
      if (window.localStorage.getItem('useNestApi') === 'true') {
        return false;
      }

      return process.env.VUE_APP_HOLDING_PAGE !== 'undefined' && process.env.VUE_APP_HOLDING_PAGE === 'true';
    },
    ...mapState({
      cognitoUserId: (state) => state.auth.cognitoUserId,
    }),
  },
  mounted() {
    if (top.location !== self.location) {
      top.location = self.location.href;
    }
  },
  watch: {
    isIdle() {
      if (this.isIdle) {
        this.showTimeOutModal = this.cognitoUserId && this.isIdle;
      }
    },
    isUnexpectedError() {
      this.showUnexpectedError = this.isUnexpectedError;
    },
    cognitoUserId() {
      if (this.cognitoUserId === null) {
        this.logout();
      }
    },
  },
};
</script>

<template>
  <div v-if="render">
    <modal
      v-if="showTimeOutModal"
      :fullModalWidth="false"
      :show-buttons="true"
      closeButtonText="Log Out"
      proceedButtonText="Stay Logged In"
      @proceed="sessionTimeoutModalAction"
    >
      <session-time-out @logout="logout" />
    </modal>

    <unexpected-error v-if="showUnexpectedError" @removeUnexpectedError="removeUnexpectedError()" />
    <holding-page v-if="holdingPageActive" />
    <router-view v-else />
    <version-check></version-check>
  </div>
</template>

<style lang="scss">
@import 'ScssDir/main.scss';
</style>
