export const FIRM_PREFIX = 'firm/';

export const FIRM_SET_FIRM_ID = 'FIRM_SET_FIRM_ID';
export const FIRM_SET_NAME = 'FIRM_SET_NAME';
export const FIRM_SET_EMAIL = 'FIRM_SET_EMAIL';
export const FIRM_SET_PHONE = 'FIRM_SET_PHONE';
export const FIRM_SET_MOBILE_PHONE = 'FIRM_SET_MOBILE_PHONE';
export const FIRM_SET_REFERENCE = 'FIRM_SET_REFERENCE';
export const FIRM_SET_ADDRESS = 'FIRM_SET_ADDRESS';
export const FIRM_SET_PERSON_IDS = 'FIRM_SET_PERSON_IDS';

export const FIRM_LOADED = 'FIRM_LOADED';

export const mutations = {
  [FIRM_SET_FIRM_ID](state, value) {
    state.firmId = value;
  },
  [FIRM_SET_NAME](state, value) {
    state.name = value;
  },
  [FIRM_SET_EMAIL](state, value) {
    state.email = value;
  },
  [FIRM_SET_PHONE](state, value) {
    state.phone = value;
  },
  [FIRM_SET_MOBILE_PHONE](state, value) {
    state.mobilePhone = value;
  },
  [FIRM_SET_REFERENCE](state, value) {
    state.reference = value;
  },
  [FIRM_SET_ADDRESS](state, value) {
    state.address = value;
  },
  [FIRM_SET_PERSON_IDS](state, value) {
    state.personIds = value;
  },
  [FIRM_LOADED](state) {
    state.loaded = true;
  },
};
