<script>
import Modal from '@/components/Modal';

export default {
  name: 'UnexpectedError',
  components: { Modal },
  methods: {
    removeUnexpectedError() {
      this.$emit('removeUnexpectedError');
    },
  },
};
</script>

<template>
  <modal closeButtonText="Close" @proceed="removeUnexpectedError()">
    <div class="text-center">
      <i class="icon-warning2 icon-2x text-danger-400 border-danger-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
      <h3>Oops! An unexpected error has occurred.</h3>
      <p>
        We sincerely apologize for the inconvenience. Please try again later. If the issue persists, don't hesitate to
        contact our support team. Thank you for your understanding and patience.
      </p>
    </div>
  </modal>
</template>
