import * as mutations from '@/store/maintenance/mutations';

export const MAINTENANCE_FETCH = 'maintenance/fetch';
export const MAINTENANCE_UPDATE = 'maintenance/update';

export const updateMaintenance = (commit, maintenanceData) =>
  new Promise((resolve) => {
    if (maintenanceData) {
      commit(mutations.MAINTENANCE_SET_BUILDING_INSURANCE, maintenanceData.building_insurance);
      commit(mutations.MAINTENANCE_SET_EXTERNAL_DECORATED, maintenanceData.external_decorated);
      commit(mutations.MAINTENANCE_SET_INTERNAL_DECORATED, maintenanceData.internal_decorated);
      commit(
        mutations.MAINTENANCE_SET_SELLER_MAINTENANCE_CONTRIBUTION,
        maintenanceData.seller_maintenance_contribution,
      );
      commit(mutations.MAINTENANCE_SET_DECORATION_EXPENSES, maintenanceData.decoration_expenses);
      commit(
        mutations.MAINTENANCE_SET_DECORATION_EXPENSES_DESCRIPTION,
        maintenanceData.decoration_expenses_description,
      );
      commit(mutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS, maintenanceData.management_problems);
      commit(
        mutations.MAINTENANCE_SET_MANAGEMENT_PROBLEMS_DESCRIPTION,
        maintenanceData.management_problems_description,
      );
      commit(mutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE, maintenanceData.building_safety_risk_existence);
      commit(
        mutations.MAINTENANCE_SET_BUILDING_SAFETY_RISK_EXISTENCE_DESCRIPTION,
        maintenanceData.building_safety_risk_existence_description,
      );
      commit(mutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE, maintenanceData.service_charge_challenge);
      commit(
        mutations.MAINTENANCE_SET_SERVICE_CHARGE_CHALLENGE_DESCRIPTION,
        maintenanceData.service_charge_challenge_description,
      );
      commit(
        mutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES,
        maintenanceData.service_charge_collection_difficulties,
      );
      commit(
        mutations.MAINTENANCE_SET_SERVICE_CHARGE_COLLECTION_DIFFICULTIES_DESCRIPTION,
        maintenanceData.service_charge_collection_difficulties_description,
      );
      commit(mutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING, maintenanceData.service_charge_owing);
      commit(
        mutations.MAINTENANCE_SET_SERVICE_CHARGE_OWING_DESCRIPTION,
        maintenanceData.service_charge_owing_description,
      );
    }

    resolve();
  });
