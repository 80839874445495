import axios from 'axios';

export default (id, eventSlugs) => {
  let queryString = '';
  // Since we use identical query key values (as per API Gateway) we can't rely on Axios params
  eventSlugs.forEach((eventSlug) => {
    queryString += queryString.length === 0 ? `?eventSlug=${eventSlug}` : `&eventSlug=${eventSlug}`;
  });
  return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/tracking/entity-id/${id}${queryString}`);
};
