import * as mutations from '@/store/transactionInformation/mutations';

export const TRANSACTION_INFORMATION_UPDATE = 'transactionInformation/update';

export const updateTransactionInformation = (commit, transactionInformationData) =>
  new Promise((resolve) => {
    if (transactionInformationData) {
      commit(mutations.TRANSACTION_INFORMATION_SET_SAME_DAY_COMPLETION, transactionInformationData.same_day_completion);
      commit(
        mutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS,
        transactionInformationData.seller_moving_date_requirements,
      );
      commit(
        mutations.TRANSACTION_INFORMATION_SET_SELLER_MOVING_DATE_REQUIREMENTS_DESCRIPTION,
        transactionInformationData.seller_moving_date_requirements_description,
      );
      commit(
        mutations.TRANSACTION_INFORMATION_SET_SALE_PRICE_EXCEEDS_MORTGAGE_CHARGES,
        transactionInformationData.sale_price_exceeds_mortgage_charges,
      );
      commit(mutations.TRANSACTION_INFORMATION_SET_BUILDING_TIDY, transactionInformationData.building_tidy);
      commit(
        mutations.TRANSACTION_INFORMATION_SET_EMPTY_LIGHT_FITTINGS_REPLACED,
        transactionInformationData.empty_light_fittings_replaced,
      );
      commit(
        mutations.TRANSACTION_INFORMATION_SET_FITTING_REMOVAL_CARE,
        transactionInformationData.fitting_removal_care,
      );
      commit(
        mutations.TRANSACTION_INFORMATION_SET_SECURITY_DETAILS_PROVIDED_AT_BUILDING,
        transactionInformationData.security_details_provided_at_building,
      );
    }

    resolve();
  });
