import store from '@/store';

const checkAdmin = async (to, from, next) => {
  if (store.state.auth.isAdmin) {
    next();
  } else {
    next('/');
  }
};

export default checkAdmin;
