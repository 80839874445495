import * as mutations from '@/store/ownership/mutations';

export const OWNERSHIP_UPDATE = 'ownership/update';

export const updateOwnership = (commit, ownershipData) =>
  new Promise((resolve) => {
    if (ownershipData) {
      commit(mutations.OWNERSHIP_SET_FREEHOLD_CONTROLLED_BY_TENANTS, ownershipData.freehold_controlled_by_tenants);
      commit(
        mutations.OWNERSHIP_SET_FREEHOLD_NOT_CONTROLLED_BY_TENANTS,
        ownershipData.freehold_not_controlled_by_tenants,
      );
      commit(mutations.OWNERSHIP_SET_HEAD_LEASEHOLDER_PRESENT, ownershipData.head_leaseholder_present);
      commit(
        mutations.OWNERSHIP_SET_HEAD_LEASEHOLDER_CONTROLLED_BY_TENANTS,
        ownershipData.head_leaseholder_controlled_by_tenants,
      );
      commit(
        mutations.OWNERSHIP_SET_MANAGING_BUILDING_FREEHOLDER_RESPONSIBLE,
        ownershipData.managing_building_freeholder_responsible,
      );
      commit(
        mutations.OWNERSHIP_SET_MANAGING_BUILDING_LEASEHOLDER_RESPONSIBLE,
        ownershipData.managing_building_leaseholder_responsible,
      );
      commit(
        mutations.OWNERSHIP_SET_MANAGING_BUILDING_COMPANY_RESPONSIBLE,
        ownershipData.managing_building_company_responsible,
      );
      commit(
        mutations.OWNERSHIP_SET_MANAGING_BUILDING_RIGHT_TO_MANAGE_COMPANY_RESPONSIBLE,
        ownershipData.managing_building_right_to_manage_company_responsible,
      );
      commit(
        mutations.OWNERSHIP_SET_MANAGING_BUILDING_OTHER_RESPONSIBLE,
        ownershipData.managing_building_other_responsible,
      );
      commit(mutations.OWNERSHIP_SET_MANAGEMENT_COMPANY_DISSOLVED, ownershipData.management_company_dissolved);
      commit(
        mutations.OWNERSHIP_SET_MANAGING_AGENT_FOR_RENT_OR_MANAGEMENT,
        ownershipData.managing_agent_for_rent_or_management,
      );
      commit(mutations.OWNERSHIP_LOADED);
    }

    resolve();
  });
