export const REMORTGAGE_PREFIX = 'remortgage/';

export const REMORTGAGE_SET_REMORTGAGE_ID = 'REMORTGAGE_SET_REMORTGAGE_ID';

export const REMORTGAGE_SET_ADDRESS = 'REMORTGAGE_SET_ADDRESS';
export const REMORTGAGE_SET_ADDITIONAL_INFO = 'REMORTGAGE_SET_ADDITIONAL_INFO';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_NAME = 'REMORTGAGE_SET_ADDITIONAL_LENDER_NAME';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER = 'REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED = 'REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP = 'REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE';
export const REMORTGAGE_RESET_ADDITIONAL_LENDER_DETAILS = 'REMORTGAGE_RESET_ADDITIONAL_LENDER_DETAILS';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION';
export const REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN =
  'REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN';
export const REMORTGAGE_RESET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_DETAILS =
  'REMORTGAGE_RESET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_DETAILS';
export const REMORTGAGE_SET_BANK_NAME = 'REMORTGAGE_SET_BANK_NAME';
export const REMORTGAGE_SET_BANK_ACCOUNT_NAME = 'REMORTGAGE_SET_BANK_ACCOUNT_NAME';
export const REMORTGAGE_SET_BANK_ACCOUNT_NUMBER = 'REMORTGAGE_SET_BANK_ACCOUNT_NUMBER';
export const REMORTGAGE_SET_BANK_BACS_OR_CHAPS = 'REMORTGAGE_SET_BANK_BACS_OR_CHAPS';
export const REMORTGAGE_SET_BANK_SORT_CODE = 'REMORTGAGE_SET_BANK_SORT_CODE';
export const REMORTGAGE_RESET_BANK_DETAILS = 'REMORTGAGE_RESET_BANK_DETAILS';
export const REMORTGAGE_SET_CLIENTS_DETAILS = 'REMORTGAGE_SET_CLIENTS_DETAILS';
export const REMORTGAGE_SET_CLIENT_IDS = 'REMORTGAGE_SET_CLIENT_IDS';
export const REMORTGAGE_SET_CURRENT_LENDER_NAME = 'REMORTGAGE_SET_CURRENT_LENDER_NAME';
export const REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER = 'REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER';
export const REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED = 'REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED';
export const REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT = 'REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT';
export const REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT = 'REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT';
export const REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS =
  'REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS';
export const REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING = 'REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING';
export const REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT =
  'REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT';
export const REMORTGAGE_RESET_CURRENT_LENDER_DETAILS = 'REMORTGAGE_RESET_CURRENT_LENDER_DETAILS';
export const REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE = 'REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE';
export const REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE = 'REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE';
export const REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS = 'REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS';
export const REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION =
  'REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION';
export const REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN = 'REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN';
export const REMORTGAGE_RESET_EARLY_REPAYMENT_CHARGE_DETAILS = 'REMORTGAGE_RESET_EARLY_REPAYMENT_CHARGE_DETAILS';
export const REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO = 'REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO';
export const REMORTGAGE_SET_NEW_LENDER_NAME = 'REMORTGAGE_SET_NEW_LENDER_NAME';
export const REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED = 'REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED';
export const REMORTGAGE_RESET_NEW_LENDER_DETAILS = 'REMORTGAGE_RESET_NEW_LENDER_DETAILS';
export const REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER = 'REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER';
export const REMORTGAGE_SET_SORT_REFER_ID = 'REMORTGAGE_SET_SORT_REFER_ID';
export const REMORTGAGE_SET_SUPPLIER_INFORMATION = 'REMORTGAGE_SET_SUPPLIER_INFORMATION';
export const REMORTGAGE_SET_TENURE_TYPE = 'REMORTGAGE_SET_TENURE_TYPE';

// Occupiers
export const REMORTGAGE_SET_OCCUPIER_ID = 'REMORTGAGE_SET_OCCUPIER_ID';
export const REMORTGAGE_SET_OCCUPIER_IDS = 'REMORTGAGE_SET_OCCUPIER_IDS';
export const REMORTGAGE_REMOVE_OCCUPIER_ID = 'REMORTGAGE_REMOVE_OCCUPIER_ID';
export const REMORTGAGE_UPDATE_OCCUPIER_ID = 'REMORTGAGE_UPDATE_OCCUPIER_ID';

// Transfer of equity - general
export const REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES = 'REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES';
export const REMORTGAGE_CLEAR_TOE_PERSONS = 'REMORTGAGE_CLEAR_TOE_PERSONS';

// Transfer of equity - persons to be added to title
export const REMORTGAGE_SET_ADDITIONAL_TOE_PERSON_ID = 'REMORTGAGE_SET_ADDITIONAL_TOE_PERSON_ID';
export const REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS = 'REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS';
export const REMORTGAGE_REMOVE_ADDITIONAL_TOE_PERSON_ID = 'REMORTGAGE_REMOVE_ADDITIONAL_TOE_PERSON_ID';
export const REMORTGAGE_UPDATE_ADDITIONAL_TOE_PERSON_ID = 'REMORTGAGE_UPDATE_ADDITIONAL_TOE_PERSON_ID';

// Transfer of equity - persons to be removed from title
export const REMORTGAGE_SET_REMOVED_TOE_PERSON_ID = 'REMORTGAGE_SET_REMOVED_TOE_PERSON_ID';
export const REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS =
  'REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS';
export const REMORTGAGE_REMOVE_REMOVED_TOE_PERSON_ID = 'REMORTGAGE_REMOVE_REMOVED_TOE_PERSON_ID';
export const REMORTGAGE_UPDATE_REMOVED_TOE_PERSON_ID = 'REMORTGAGE_UPDATE_REMOVED_TOE_PERSON_ID';

// Tracking events
export const REMORTGAGE_SET_TRACKING_EVENTS = 'REMORTGAGE_SET_TRACKING_EVENTS';
export const REMORTGAGE_ADD_TRACKING_EVENT = 'REMORTGAGE_ADD_TRACKING_EVENT';

export const REMORTGAGE_LOADED = 'REMORTGAGE_LOADED';
export const REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED = 'REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED';
export const REMORTGAGE_SET_EVENT_TO_HANDLE = 'REMORTGAGE_SET_EVENT_TO_HANDLE';
export const REMORTGAGE_SET_CONFIG = 'REMORTGAGE_SET_CONFIG';
export const REMORTGAGE_SET_SUBMISSION_STATUS = 'REMORTGAGE_SET_SUBMISSION_STATUS';
export const REMORTGAGE_SET_CLIENT_MATCH_DETAILS = 'REMORTGAGE_SET_CLIENT_MATCH_DETAILS';
export const REMORTGAGE_SET_HMLR_TITLE_DOCUMENT_ID = 'REMORTGAGE_SET_HMLR_TITLE_DOCUMENT_ID';
export const REMORTGAGE_SET_STANDALONE_TOE = 'REMORTGAGE_SET_STANDALONE_TOE';
export const REMORTGAGE_SET_EXISTING_MORTGAGE = 'REMORTGAGE_SET_EXISTING_MORTGAGE';

export const mutations = {
  [REMORTGAGE_SET_REMORTGAGE_ID](state, value) {
    state.remortgageId = value;
  },
  [REMORTGAGE_SET_ADDRESS](state, value) {
    state.address = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_INFO](state, value) {
    state.additionalInfo = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_NAME](state, value) {
    state.additionalLenderName = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER](state, value) {
    state.additionalLenderAccountNumber = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED](state, value) {
    state.additionalLenderAmountOwed = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP](state, value) {
    state.additionalLenderKeep = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE](state, value) {
    state.additionalLenderHelpToBuyCharge = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF](state, value) {
    state.additionalLenderHelpToBuyChargePayingOff = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF](state, value) {
    state.additionalLenderTargetHcaToPayOff = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE](state, value) {
    state.additionalLenderTargetHcaToRemortgage = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE](state, value) {
    state.additionalLenderEarlyRepaymentCharge = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE](state, value) {
    state.additionalLenderEarlyRepaymentChargeEndDate = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS](state, value) {
    state.additionalLenderEarlyRepaymentChargeExists = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION](state, value) {
    state.additionalLenderEarlyRepaymentChargePostponeCompletion = value;
  },
  [REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN](state, value) {
    state.additionalLenderEarlyRepaymentChargeUnknown = value;
  },
  [REMORTGAGE_RESET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_DETAILS](state) {
    state.additionalLenderEarlyRepaymentCharge = null;
    state.additionalLenderEarlyRepaymentChargeEndDate = null;
    state.additionalLenderEarlyRepaymentChargeExists = null;
    state.additionalLenderEarlyRepaymentChargePostponeCompletion = null;
    state.additionalLenderEarlyRepaymentChargeUnknown = null;
  },
  [REMORTGAGE_RESET_ADDITIONAL_LENDER_DETAILS](state) {
    state.additionalLenderAccountNumber = null;
    state.additionalLenderAmountOwed = null;
    state.additionalLenderKeep = null;
    state.additionalLenderName = null;
    state.additionalLenderHelpToBuyCharge = null;
    state.additionalLenderHelpToBuyChargePayingOff = null;
    state.additionalLenderTargetHcaToPayOff = null;
    state.additionalLenderTargetHcaToRemortgage = null;
  },
  [REMORTGAGE_SET_BANK_NAME](state, value) {
    state.bankName = value;
  },
  [REMORTGAGE_SET_BANK_ACCOUNT_NAME](state, value) {
    state.bankAccountName = value;
  },
  [REMORTGAGE_SET_BANK_ACCOUNT_NUMBER](state, value) {
    state.bankAccountNumber = value;
  },
  [REMORTGAGE_SET_BANK_BACS_OR_CHAPS](state, value) {
    state.bankBacsOrChaps = value;
  },
  [REMORTGAGE_SET_BANK_SORT_CODE](state, value) {
    state.bankSortCode = value;
  },
  [REMORTGAGE_RESET_BANK_DETAILS](state) {
    state.bankAccountName = null;
    state.bankAccountNumber = null;
    state.bankBacsOrChaps = null;
    state.bankName = null;
    state.bankSortCode = null;
  },
  [REMORTGAGE_SET_CLIENT_IDS](state, value) {
    state.clientIds = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_NAME](state, value) {
    state.currentLenderName = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER](state, value) {
    state.currentLenderAccountNumber = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED](state, value) {
    state.currentLenderAmountOwed = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT](state, value) {
    state.currentLenderMakingOverpayment = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT](state, value) {
    state.currentLenderOverpaymentAmount = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS](state, value) {
    state.currentLenderBankStatementDocuments = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING](state, value) {
    state.currentLenderAdditionalBorrowing = value;
  },
  [REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT](state, value) {
    state.currentLenderAdditionalBorrowingAmount = value;
  },
  [REMORTGAGE_RESET_CURRENT_LENDER_DETAILS](state) {
    state.currentLenderAccountNumber = null;
    state.currentLenderAmountOwed = null;
    state.currentLenderMakingOverpayment = null;
    state.currentLenderBankStatementDocuments = null;
    state.currentLenderAdditionalBorrowing = null;
    state.currentLenderAdditionalBorrowingAmount = null;
    state.currentLenderName = null;
  },
  [REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE](state, value) {
    state.earlyRepaymentCharge = value;
  },
  [REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE](state, value) {
    state.earlyRepaymentChargeEndDate = value;
  },
  [REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS](state, value) {
    state.earlyRepaymentChargeExists = value;
  },
  [REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION](state, value) {
    state.earlyRepaymentChargePostponeCompletion = value;
  },
  [REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN](state, value) {
    state.earlyRepaymentChargeUnknown = value;
  },
  [REMORTGAGE_RESET_EARLY_REPAYMENT_CHARGE_DETAILS](state) {
    state.earlyRepaymentCharge = null;
    state.earlyRepaymentChargeEndDate = null;
    state.earlyRepaymentChargeExists = null;
    state.earlyRepaymentChargePostponeCompletion = null;
    state.earlyRepaymentChargeUnknown = null;
  },
  [REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO](state, value) {
    state.leaseholdAdditionalInfo = value;
  },
  [REMORTGAGE_SET_NEW_LENDER_NAME](state, value) {
    state.newLenderName = value;
  },
  [REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED](state, value) {
    state.newLenderAmountOwed = value;
  },
  [REMORTGAGE_RESET_NEW_LENDER_DETAILS](state) {
    state.newLenderAmountOwed = null;
    state.newLenderName = null;
  },
  [REMORTGAGE_SET_OCCUPIER_ID](state, value) {
    let data = [...value];
    data.forEach((item) => state.occupierIds.push(item));
  },
  [REMORTGAGE_SET_OCCUPIER_IDS](state, value) {
    state.occupierIds = value;
  },
  [REMORTGAGE_REMOVE_OCCUPIER_ID](state, id) {
    const index = state.occupierIds.findIndex((item) => item.personId === id);
    state.occupierIds.splice(index, 1);
  },
  [REMORTGAGE_UPDATE_OCCUPIER_ID](state, { index, payload }) {
    state.occupierIds[index] = payload;
  },
  [REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER](state, value) {
    state.passStatementsToBroker = value;
  },
  [REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES](state, value) {
    state.anyToePersonChanges = value;
  },
  [REMORTGAGE_CLEAR_TOE_PERSONS](state) {
    state.personsToBeAddedToTitleIds = null;
    state.personsToBeRemovedFromTitleIds = null;
  },
  [REMORTGAGE_SET_ADDITIONAL_TOE_PERSON_ID](state, value) {
    let data = [...value];
    data.forEach((item) => state.personsToBeAddedToTitleIds.push(item));
  },
  [REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS](state, value) {
    state.personsToBeAddedToTitleIds = value;
  },
  [REMORTGAGE_REMOVE_ADDITIONAL_TOE_PERSON_ID](state, id) {
    const index = state.personsToBeAddedToTitleIds.findIndex((item) => item.personId === id);
    state.personsToBeAddedToTitleIds.splice(index, 1);
  },
  [REMORTGAGE_UPDATE_ADDITIONAL_TOE_PERSON_ID](state, { index, payload }) {
    state.personsToBeAddedToTitleIds[index] = payload;
  },
  [REMORTGAGE_SET_REMOVED_TOE_PERSON_ID](state, value) {
    let data = [...value];
    data.forEach((item) => state.personsToBeRemovedFromTitleIds.push(item));
  },
  [REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS](state, value) {
    state.personsToBeRemovedFromTitleIds = value;
  },
  [REMORTGAGE_REMOVE_REMOVED_TOE_PERSON_ID](state, id) {
    const index = state.personsToBeRemovedFromTitleIds.findIndex((item) => item.personId === id);
    state.personsToBeRemovedFromTitleIds.splice(index, 1);
  },
  [REMORTGAGE_UPDATE_REMOVED_TOE_PERSON_ID](state, { index, payload }) {
    state.personsToBeRemovedFromTitleIds[index] = payload;
  },
  [REMORTGAGE_SET_CLIENTS_DETAILS](state, value) {
    state.clientsDetails = value;
  },
  [REMORTGAGE_SET_SORT_REFER_ID](state, value) {
    state.sortReferId = value;
  },
  [REMORTGAGE_SET_SUPPLIER_INFORMATION](state, value) {
    state.supplierInformation = value;
  },
  [REMORTGAGE_LOADED](state) {
    state.loaded = true;
  },
  [REMORTGAGE_REMORTGAGE_ADDRESS_UPDATED](state, value) {
    if (value) {
      // Random number is required to force vue to rerender on a property change
      // This works as a random number is set as a :key index of DOM element
      state.remortgageAddressUpdated = Math.floor(Math.random() * 1000);
    }
  },
  [REMORTGAGE_SET_TRACKING_EVENTS](state, value) {
    state.trackingEvents = value;
  },
  [REMORTGAGE_ADD_TRACKING_EVENT](state, value) {
    state.trackingEvents.push(value);
  },
  [REMORTGAGE_SET_EVENT_TO_HANDLE](state, value) {
    state.eventToHandle = value;
  },
  [REMORTGAGE_SET_CONFIG](state, value) {
    state.config = value;
  },
  [REMORTGAGE_SET_TENURE_TYPE](state, value) {
    state.tenureType = value;
  },
  [REMORTGAGE_SET_SUBMISSION_STATUS](state, value) {
    state.submissionStatus = value;
  },
  [REMORTGAGE_SET_CLIENT_MATCH_DETAILS](state, value) {
    state.clientMatchDetails = value;
  },
  [REMORTGAGE_SET_HMLR_TITLE_DOCUMENT_ID](state, value) {
    state.hmlrTitleDocumentId = value;
  },
  [REMORTGAGE_SET_STANDALONE_TOE](state, value) {
    state.standaloneToe = value;
  },
  [REMORTGAGE_SET_EXISTING_MORTGAGE](state, value) {
    state.existingMortgage = value;
  },
};
