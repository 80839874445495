export const SERVICES_PREFIX = 'building/services/';
export const SERVICES_SET_ELECTRICS_TESTED = 'SERVICES_SET_ELECTRICS_TESTED';
export const SERVICES_SET_ELECTRICS_TESTED_YEAR = 'SERVICES_SET_ELECTRICS_TESTED_YEAR';
export const SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE = 'SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE';
export const SERVICES_SET_ELECTRICS_REWIRED = 'SERVICES_SET_ELECTRICS_REWIRED';
export const SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE = 'SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE';
export const SERVICES_SET_COMPLIANCE_CERTIFICATE = 'SERVICES_SET_COMPLIANCE_CERTIFICATE';
export const SERVICES_SET_COMPLETION_CERTIFICATE = 'SERVICES_SET_COMPLETION_CERTIFICATE';
export const SERVICES_SET_CENTRAL_HEATING_SYSTEM = 'SERVICES_SET_CENTRAL_HEATING_SYSTEM';
export const SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE = 'SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE';
export const SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE = 'SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE';
export const SERVICES_SET_SEPTIC_TANK_REPLACED = 'SERVICES_SET_SEPTIC_TANK_REPLACED';
export const SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE = 'SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE';
export const SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER = 'SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER';
export const SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR = 'SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR';
export const SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT =
  'SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT';
export const SERVICES_SET_FOUL_WATER_DRAINAGE = 'SERVICES_SET_FOUL_WATER_DRAINAGE';
export const SERVICES_SET_SURFACE_WATER_DRAINAGE = 'SERVICES_SET_SURFACE_WATER_DRAINAGE';
export const SERVICES_SET_SEWERAGE_SEPTIC_TANK = 'SERVICES_SET_SEWERAGE_SEPTIC_TANK';
export const SERVICES_SET_SEWERAGE_TREATMENT_PLANT = 'SERVICES_SET_SEWERAGE_TREATMENT_PLANT';
export const SERVICES_SET_SEWERAGE_CESSPOOL = 'SERVICES_SET_SEWERAGE_CESSPOOL';
export const SERVICES_SET_SEWERAGE_SYSTEM_SHARED = 'SERVICES_SET_SEWERAGE_SYSTEM_SHARED';
export const SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER = 'SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER';
export const SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED = 'SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED';
export const SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR = 'SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR';
export const SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR = 'SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR';
export const SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY = 'SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY';
export const SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS =
  'SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS';
export const SERVIES_LOADED = 'SERVIES_LOADED';

export const mutations = {
  [SERVICES_SET_ELECTRICS_TESTED](state, value) {
    state.electricsTested = value;
  },
  [SERVICES_SET_ELECTRICS_TESTED_YEAR](state, value) {
    state.electricsTestedYear = value;
  },
  [SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE](state, value) {
    state.electricsTestedCertificate = value;
  },
  [SERVICES_SET_ELECTRICS_REWIRED](state, value) {
    state.electricsRewired = value;
  },
  [SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE](state, value) {
    state.electricalSafetyCertificate = value;
  },
  [SERVICES_SET_COMPLIANCE_CERTIFICATE](state, value) {
    state.complianceCertificate = value;
  },
  [SERVICES_SET_COMPLETION_CERTIFICATE](state, value) {
    state.completionCertificate = value;
  },
  [SERVICES_SET_CENTRAL_HEATING_SYSTEM](state, value) {
    state.centralHeatingSystem = value;
  },
  [SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE](state, value) {
    state.centralHeatingSystemType = value;
  },
  [SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE](state, value) {
    state.centralHeatingInstallationDate = value;
  },
  [SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE](state, value) {
    state.centralHeatingBeforeSpecifiedDate = value;
  },
  [SERVICES_SET_SEPTIC_TANK_REPLACED](state, value) {
    state.septicTankReplaced = value;
  },
  [SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER](state, value) {
    state.heatingSystemInWorkingOrder = value;
  },
  [SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR](state, value) {
    state.heatingSystemServicedYear = value;
  },
  [SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT](state, value) {
    state.heatingSystemServicedInspectionReport = value;
  },
  [SERVICES_SET_FOUL_WATER_DRAINAGE](state, value) {
    state.foulWaterDrainage = value;
  },
  [SERVICES_SET_SURFACE_WATER_DRAINAGE](state, value) {
    state.surfaceWaterDrainage = value;
  },
  [SERVICES_SET_SEWERAGE_SEPTIC_TANK](state, value) {
    state.sewerageSepticTank = value;
  },
  [SERVICES_SET_SEWERAGE_TREATMENT_PLANT](state, value) {
    state.sewerageTreatmentPlant = value;
  },
  [SERVICES_SET_SEWERAGE_CESSPOOL](state, value) {
    state.sewerageCesspool = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_SHARED](state, value) {
    state.sewerageSystemShared = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER](state, value) {
    state.sewerageSystemSharedNumber = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED](state, value) {
    state.sewerageSystemLastEmptied = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR](state, value) {
    state.sewerageSystemServicedYear = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR](state, value) {
    state.sewerageSystemInstalledYear = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY](state, value) {
    state.sewerageSystemOutsideOfProperty = value;
  },
  [SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS](state, value) {
    state.sewerageSystemOutsideOfPropertyDocuments = value;
  },
  [SERVIES_LOADED](state) {
    state.loaded = true;
  },
};
