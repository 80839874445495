import * as mutations from '@/store/boundaries/mutations';

export const BOUNDARIES_UPDATE = 'boundary/update';

export const updateBoundaries = (commit, boundariesData) =>
  new Promise((resolve) => {
    if (boundariesData) {
      commit(mutations.BOUNDARIES_SET_LEFT, boundariesData.boundary_left);
      commit(mutations.BOUNDARIES_SET_RIGHT, boundariesData.boundary_right);
      commit(mutations.BOUNDARIES_SET_FRONT, boundariesData.boundary_front);
      commit(mutations.BOUNDARIES_SET_REAR, boundariesData.boundary_rear);
      commit(mutations.BOUNDARIES_SET_IRREGULAR, boundariesData.irregular);
      commit(mutations.BOUNDARIES_SET_FEATURE_MOVED, boundariesData.feature_moved);
      commit(mutations.BOUNDARIES_SET_FEATURE_MOVED_DESCRIPTION, boundariesData.feature_moved_description);
      commit(mutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED, boundariesData.land_sold_purchased);
      commit(mutations.BOUNDARIES_SET_LAND_SOLD_PURCHASED_DESCRIPTION, boundariesData.land_sold_purchased_description);
      commit(mutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER, boundariesData.overhang_project_under);
      commit(
        mutations.BOUNDARIES_SET_OVERHANG_PROJECT_UNDER_DESCRIPTION,
        boundariesData.overhang_project_under_description,
      );
      commit(mutations.BOUNDARIES_SET_PARTY_WALL_ACT, boundariesData.party_wall_act);
      commit(mutations.BOUNDARIES_SET_PARTY_WALL_ACT_NOTICE, boundariesData.party_wall_act_notice);
      commit(mutations.BOUNDARIES_SET_PARTY_WALL_ACT_DESCRIPTION, boundariesData.party_wall_act_description);
    }

    resolve();
  });
