<script>
import axios from 'axios';
import Modal from '@/components/Modal';

export default {
  name: 'VersionCheck',
  components: { Modal },
  data() {
    return {
      currentAssetHash: undefined,
      initialAssetHash: '{{POST_BUILD_HASH_PLACEHOLDER}}',
      debugging: false,
      intervalInSeconds: 30,
    };
  },
  methods: {
    async checkVersion() {
      const result = await axios
        .get(`${location.origin}/version.json?t=${new Date().getTime()}`)
        .catch(() => ({ data: {} }));
      this.currentAssetHash = result.data?.hash;
      if (!this.currentAssetHash || this.currentAssetHash === this.initialAssetHash) {
        setTimeout(async () => this.checkVersion(), this.intervalInSeconds * 1000);
      }
    },
    async reload() {
      location.reload();
    },
  },
  async mounted() {
    await this.checkVersion();
  },
};
</script>
<template>
  <div>
    <section v-if="debugging" id="version-check">
      <div>Version: {{ initialAssetHash }}</div>
    </section>
    <modal
      v-if="currentAssetHash && initialAssetHash !== currentAssetHash"
      :fullModalWidth="false"
      :show-buttons="true"
      proceedButtonText="Reload"
      @proceed="reload"
    >
      The onboarding system has updated since you started this page, please reload to continue.
    </modal>
  </div>
</template>
<style scoped>
#version-check {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  right: 50%;
  margin-right: -100px;
  width: 200px;
  height: 50px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
