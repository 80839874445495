import store from '@/store';

const checkDeveloper = async (to, from, next) => {
  if (store.state.auth.isDeveloper) {
    next();
  } else {
    next('/');
  }
};

export default checkDeveloper;
