import * as mutations from '@/store/persons/mutations';
import getPerson from '@/api/persons/getPerson';
import sortBy from 'lodash/sortBy';

export const PERSONS_FETCH_SALE_CLIENTS = 'persons/fetchSaleClients';
export const PERSONS_FETCH_PURCHASE_CLIENTS = 'persons/fetchPurchaseClients';
export const PERSONS_FETCH_REMORTGAGE_CLIENTS = 'persons/fetchRemortgageClients';
export const PERSONS_FETCH_ADDITIONAL_TOE_PERSONS = 'persons/fetchAdditionalToePersons';
export const PERSONS_FETCH_REMOVED_TOE_PERSONS = 'persons/fetchRemovedToePersons';
export const PERSONS_FETCH_OCCUPIERS = 'persons/fetchOccupiers';
export const PERSONS_FETCH_FIRM_PERSONS = 'persons/fetchFirmPersons';

export const fetchSaleClients = (commit, clientIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_CLIENT_REMOVE_ALL);
    Promise.all(clientIds.map((item) => getPerson(item)))
      .then((result) => {
        result = sortBy(result, 'data.cognito_user_id');
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_CLIENT_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            noMiddleNameConfirmed: item.no_middle_name_confirmed,
            lastName: item.last_name,
            previousName: item.previous_name,
            dob: item.dob,
            gender: item.gender,
            nationality: item.nationality,
            correspondenceAddress: item.correspondence_address,
            previousAddresses:
              item.profile_seller && item.profile_seller.previous_addresses
                ? item.profile_seller.previous_addresses
                : [],
            phoneHome: item.phone_home,
            phoneMobile: item.phone_mobile,
            phoneWork: item.phone_work,
            email: item.email,
            occupation: item.profile_seller ? item.profile_seller.occupation : null,
            niNumber: item.profile_identification ? item.profile_identification.ni_number : null,
            nameChanged: item.profile_seller ? item.profile_seller.name_changed : null,
            nameChangedDetails: item.profile_seller ? item.profile_seller.name_changed_details : null,
            bankrupt: item.profile_seller ? item.profile_seller.bankrupt : null,
            bankruptDate: item.profile_seller ? item.profile_seller.bankrupt_date : null,
            availability: item.profile_seller ? item.profile_seller.availability : null,
            relationship: item.profile_seller ? item.profile_seller.relationship : null,
            relationshipOther: item.profile_seller ? item.profile_seller.relationship_other : null,
            politicallyExposedPerson: item.profile_seller ? item.profile_seller.politically_exposed_person : null,
            cognitoUserId: item.cognito_user_id,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchPurchaseClients = (commit, clientIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_CLIENT_REMOVE_ALL);
    Promise.all(clientIds.map((item) => getPerson(item)))
      .then((result) => {
        result = sortBy(result, 'data.cognito_user_id');
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_CLIENT_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            noMiddleNameConfirmed: item.no_middle_name_confirmed,
            lastName: item.last_name,
            previousName: item.previous_name,
            dob: item.dob,
            gender: item.gender,
            nationality: item.nationality,
            correspondenceAddress: item.correspondence_address,
            previousAddresses:
              item.profile_buyer && item.profile_buyer.previous_addresses ? item.profile_buyer.previous_addresses : [],
            phoneHome: item.phone_home,
            phoneMobile: item.phone_mobile,
            phoneWork: item.phone_work,
            email: item.email,
            occupation: item.profile_buyer ? item.profile_buyer.occupation : null,
            niNumber: item.profile_identification ? item.profile_identification.ni_number : null,
            nameChanged: item.profile_buyer ? item.profile_buyer.name_changed : null,
            nameChangedDetails: item.profile_buyer ? item.profile_buyer.name_changed_details : null,
            bankrupt: item.profile_buyer ? item.profile_buyer.bankrupt : null,
            bankruptDate: item.profile_buyer ? item.profile_buyer.bankrupt_date : null,
            availability: item.profile_buyer ? item.profile_buyer.availability : null,
            propertyInterestsOwned: item.profile_buyer ? Number(item.profile_buyer.property_interests_owned) : null,
            relationship: item.profile_buyer ? item.profile_buyer.relationship : null,
            relationshipOther: item.profile_buyer ? item.profile_buyer.relationship_other : null,
            politicallyExposedPerson: item.profile_buyer ? item.profile_buyer.politically_exposed_person : null,
            cognitoUserId: item.cognito_user_id,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchRemortgageClients = (commit, clientIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_CLIENT_REMOVE_ALL);
    Promise.all(clientIds.map((item) => getPerson(item)))
      .then((result) => {
        result = sortBy(result, 'data.cognito_user_id');
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_CLIENT_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            noMiddleNameConfirmed: item.no_middle_name_confirmed,
            lastName: item.last_name,
            previousName: item.previous_name,
            dob: item.dob,
            gender: item.gender,
            nationality: item.nationality,
            correspondenceAddress: item.correspondence_address,
            phoneHome: item.phone_home,
            phoneMobile: item.phone_mobile,
            phoneWork: item.phone_work,
            email: item.email,
            occupation: item.profile_remortgagor ? item.profile_remortgagor.occupation : null,
            niNumber: item.profile_identification ? item.profile_identification.ni_number : null,
            bankrupt: item.profile_remortgagor ? item.profile_remortgagor.bankrupt : null,
            bankruptDate: item.profile_remortgagor ? item.profile_remortgagor.bankrupt_date : null,
            availability: item.profile_remortgagor ? item.profile_remortgagor.availability : null,
            relationship: item.profile_remortgagor ? item.profile_remortgagor.relationship : null,
            relationshipOther: item.profile_remortgagor ? item.profile_remortgagor.relationship_other : null,
            politicallyExposedPerson: item.profile_remortgagor
              ? item.profile_remortgagor.politically_exposed_person
              : null,
            cognitoUserId: item.cognito_user_id,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchAdditionalToePersons = (commit, additionalToePersonIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_ADDITIONAL_TOE_PERSON_REMOVE_ALL);
    Promise.all(additionalToePersonIds.map((item) => getPerson(item)))
      .then((result) => {
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_ADDITIONAL_TOE_PERSON_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            noMiddleNameConfirmed: item.no_middle_name_confirmed,
            lastName: item.last_name,
            email: item.email,
            phoneMobile: item.phone_mobile,
            dob: item.dob,
            toeAmount: item.profile_transfer_of_equity ? item.profile_transfer_of_equity.toe_amount : null,
            toeMarriedOrCivilPartnershipWithTitlePerson: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_married_or_civil_partnership_with_title_person
              : null,
            toeOwnOtherProperty: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_own_other_property
              : null,
            toeIsPrimaryResidence: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_is_primary_residence
              : null,
            toeAnyMoneyPaidForTransfer: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_any_money_paid_for_transfer
              : null,
            toeTransferByCourtOrder: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_transfer_by_court_order
              : null,
            toeTransferByCourtOrderDocuments: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_transfer_by_court_order_documents
              : null,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchRemovedToePersons = (commit, removedToePersonIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_REMOVED_TOE_PERSON_REMOVE_ALL);
    Promise.all(removedToePersonIds.map((item) => getPerson(item)))
      .then((result) => {
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_REMOVED_TOE_PERSON_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            noMiddleNameConfirmed: item.no_middle_name_confirmed,
            lastName: item.last_name,
            email: item.email,
            phoneMobile: item.phone_mobile,
            dob: item.dob,
            toeAmount: item.profile_transfer_of_equity ? item.profile_transfer_of_equity.toe_amount : null,
            toeMarriedOrCivilPartnershipWithTitlePerson: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_married_or_civil_partnership_with_title_person
              : null,
            toeOwnOtherProperty: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_own_other_property
              : null,
            toeIsPrimaryResidence: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_is_primary_residence
              : null,
            toeAnyMoneyPaidForTransfer: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_any_money_paid_for_transfer
              : null,
            toeTransferByCourtOrder: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_transfer_by_court_order
              : null,
            toeTransferByCourtOrderDocuments: item.profile_transfer_of_equity
              ? item.profile_transfer_of_equity.toe_transfer_by_court_order_documents
              : null,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchOccupiers = (commit, occuppierIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_OCCUPIER_REMOVE_ALL);
    Promise.all(occuppierIds.map((item) => getPerson(item)))
      .then((result) => {
        result.forEach((personResponse) => {
          const item = personResponse.data;
          commit(mutations.PERSON_OCCUPIER_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            lastName: item.last_name,
            email: item.email,
            phoneMobile: item.phone_mobile,
            age: item.profile_occupier ? item.profile_occupier.age : null,
            relationship: item.profile_occupier ? item.profile_occupier.relationship : null,
            relationshipOther: item.profile_occupier ? item.profile_occupier.relationship_other : null,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const fetchFirmPersons = (commit, personIds = []) =>
  new Promise((resolve, reject) => {
    commit(mutations.PERSON_FIRM_PERSON_REMOVE_ALL);

    Promise.all(personIds.map((item) => getPerson(item)))
      .then((result) => {
        result.forEach((personResponse) => {
          const item = personResponse.data;

          commit(mutations.PERSON_FIRM_PERSON_ADD, {
            personId: item.person_id,
            title: item.title,
            firstName: item.first_name,
            middleName: item.middle_name,
            lastName: item.last_name,
          });
        });

        commit(mutations.PERSONS_LOADED);
        resolve();
      })
      .catch((reason) => {
        reject(reason);
      });
  });
