export const OTHER_CHARGES_PREFIX = 'building/otherCharges/';
export const OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES = 'OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES';
export const OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION =
  'OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION';
export const OTHER_CHARGES_LOADED = 'OTHER_CHARGES_LOADED';

/**
 * Set each field in the state with the given value.
 *
 * @type {{"[OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION]"(*, *): void, "[OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES]"(*, *): void, "[OTHER_CHARGES_LOADED]"(*): void}}
 */
export const mutations = {
  [OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES](state, value) {
    state.propertyRelatedCharges = value;
  },
  [OTHER_CHARGES_SET_PROPERTY_RELATED_CHARGES_DESCRIPTION](state, value) {
    state.propertyRelatedChargesDetails = value;
  },
  [OTHER_CHARGES_LOADED](state) {
    state.loaded = true;
  },
};
