import getPersonOccupier from '@/api/persons/getPersonOccupier';
import * as mutations from '@/store/personsOccupiers/mutations';

export const PERSONS_OCCUPIERS_FETCH = 'personsOccupiers/fetch';

export const fetchFromApi = (commit, personId) =>
  new Promise((resolve) => {
    getPersonOccupier(personId).then((response) => {
      commit(mutations.PERSONS_OCCUPIERS_SET_AGE, response.data.age);
      commit(mutations.PERSONS_OCCUPIERS_SET_RELATIONSHIP, response.data.relationship);
      commit(mutations.PERSONS_OCCUPIERS_SET_RELATIONSHIP_OTHER, response.data.relationship_other);
      commit(mutations.PERSONS_OCCUPIERS_LOADED);
      resolve();
    });
  });
