export const RELEVANT_DOCUMENTS_PREFIX = 'building/relevantDocuments/';
export const RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS = 'RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS';
export const RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS = 'RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS';
export const RELEVANT_DOCUMENTS_SET_CORRESPONDENCE = 'RELEVANT_DOCUMENTS_SET_CORRESPONDENCE';
export const RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES = 'RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES';
export const RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES = 'RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES';
export const RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE = 'RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE';
export const RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE =
  'RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE';
export const RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY = 'RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY';
export const RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM = 'RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM';
export const RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE = 'RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE';
export const RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS = 'RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS';
export const RELEVANT_DOCUMENTS_LOADED = 'RELEVANT_DOCUMENTS_LOADED';

export const mutations = {
  [RELEVANT_DOCUMENTS_SET_LEASE_SUPPLEMENTAL_DEEDS](state, value) {
    state.leaseSupplementalDeeds = value;
  },
  [RELEVANT_DOCUMENTS_SET_LANDLORD_REGULATIONS](state, value) {
    state.landlordRegulations = value;
  },
  [RELEVANT_DOCUMENTS_SET_CORRESPONDENCE](state, value) {
    state.correspondence = value;
  },
  [RELEVANT_DOCUMENTS_SET_MAINTENANCE_INVOICES](state, value) {
    state.maintenanceInvoices = value;
  },
  [RELEVANT_DOCUMENTS_SET_GROUND_RENT_INVOICES](state, value) {
    state.groundRentInvoices = value;
  },
  [RELEVANT_DOCUMENTS_SET_SELLER_BUILDINGS_INSURANCE](state, value) {
    state.sellerBuildingsInsurance = value;
  },
  [RELEVANT_DOCUMENTS_SET_LANDLORD_BUILDINGS_INSURANCE](state, value) {
    state.landlordBuildingsInsurance = value;
  },
  [RELEVANT_DOCUMENTS_SET_TENANTS_MANAGEMENT_COMPANY](state, value) {
    state.tenantsManagementCompany = value;
  },
  [RELEVANT_DOCUMENTS_SET_MANAGEMENT_MEMORANDUM](state, value) {
    state.managementMemorandum = value;
  },
  [RELEVANT_DOCUMENTS_SET_MANAGEMENT_CERTIFICATE](state, value) {
    state.managementCertificate = value;
  },
  [RELEVANT_DOCUMENTS_SET_MANAGEMENT_ACCOUNTS](state, value) {
    state.managementAccounts = value;
  },
  [RELEVANT_DOCUMENTS_LOADED](state) {
    state.loaded = true;
  },
};
