import * as mutations from '@/store/contact/mutations';
import { updateContact } from '@/store/contact/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    landlordName: null,
    landlordAddress: null,
    landlordTel: null,
    landlordEmail: null,
    landlordAgentName: null,
    landlordAgentAddress: null,
    landlordAgentTel: null,
    landlordAgentEmail: null,
    tenantAgentName: null,
    tenantAgentAddress: null,
    tenantAgentTel: null,
    tenantAgentEmail: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, contactData) => updateContact(commit, contactData),
  },
};
