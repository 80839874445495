import * as mutations from '@/store/disputes/mutations';
import { updateDisputes } from '@/store/disputes/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    disputesComplaints: null,
    disputesComplaintsDescription: null,
    potentialDispute: null,
    potentialDisputeDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, disputesData) => updateDisputes(commit, disputesData),
  },
};
