import * as mutations from '@/store/firm/mutations';
import { fetchFromApi } from '@/store/firm/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    firmId: null,
    name: null,
    email: null,
    phone: null,
    mobilePhone: null,
    reference: null,
    address: {
      administrative_area: null,
      dependent_locality: null,
      locality: null,
      organisation_name: null,
      postal_code: null,
      premise: null,
      sub_administrative_area: null,
      thoroughfare: null,
    },
    personIds: [],
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: ({ commit }, firmId) => fetchFromApi(commit, firmId),
  },
};
