import * as mutations from '@/store/purchase/mutations';
import {
  addTrackingEvent,
  fetchFromApi,
  refreshTrackingEvents,
  setTrackingEvents,
  setEventToHandle,
} from '@/store/purchase/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    purchaseId: null,
    clientIds: [],
    authorityPurchase: false,
    authorityPurchaseDetails: null,
    propertyType: null,
    propertyTypeOther: null,
    purchaseType: null,
    typeOfPurchase: null,
    buyerName: null,
    purchasePrice: null,
    typeOfTenancy: null,
    typeOfTenancyDetails: null,
    estateAgentId: null,
    fixturePrice: null,
    agentFee: null,
    agentFeeType: null,
    agentFeePaymentPermission: null,
    relatedCase: false,
    relatedCases: [],
    interimAddress: null,
    interimPhone: null,
    specialPurchase: false,
    specialPurchaseDetails: null,
    occupierIds: [],
    mortgages: [],
    titleNumbers: null,
    desiredCompletionDate: null,
    exchangeDeadline: null,
    completionDeadline: null,
    surplusPaymentMethod: null,
    buildingId: null,
    clientsDetails: [],
    buildingDetails: null,
    sortReferId: null,
    supplierInformation: null,
    sagepayTransactionId: null,
    structuralAlterations: null,
    structuralAlterationsDetails: null,
    structuralAlterationsWithinTenYr: null,
    furtherPointsToCheck: null,
    furtherPointsToCheckDetails: null,
    brokerOrIfa: null,
    brokerOrIfaDetails: null,
    sourceOfFunds: [],
    buildingInsurer: null,
    soldByEstateAgent: null,
    solicitorContactDetails: null,
    trackingEvents: [],
    purchaseAddressUpdated: 0,
    config: null,
    eventToHandle: null,
    passStatementsToBroker: null,
    additionalInfo: null,
    bankName: null,
    bankAccountName: null,
    bankAccountNumber: null,
    bankSortCode: null,
    bankBacsOrChaps: null,
  },
  getters: {
    trackingEvents: (state) => state.trackingEvents,
    eventToHandle: (state) => state.eventToHandle,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: ({ commit }, purchaseId) => fetchFromApi(commit, purchaseId),
    refreshTrackingEvents: (context, id) => refreshTrackingEvents(context, id),
    setTrackingEvents: ({ commit }, trackingEvents) => setTrackingEvents(commit, trackingEvents),
    addTrackingEvent: ({ commit }, trackingEvent) => addTrackingEvent(commit, trackingEvent),
    setEventToHandle: ({ commit }, eventToHandle) => setEventToHandle(commit, eventToHandle),
  },
};
