import * as mutations from '@/store/auth/mutations';
import { Auth } from 'aws-amplify';
import findUser from '@/api/user/findUser';

export const userLoggedIn = async (commit, auth) => {
  const currentUserInfo = await auth.currentUserInfo().then((res) => res);
  const authenticatedUserDetails = await auth.currentAuthenticatedUser().then((res) => res);
  localStorage.setItem('authDetails', JSON.stringify(currentUserInfo));

  const user = await findUser(currentUserInfo.username);
  const roles = user?.roles?.map(({ name }) => name) ?? [];

  commit(mutations.AUTH_SET_EMAIL, currentUserInfo.attributes.email);
  commit(mutations.AUTH_SET_USER_DETAILS, currentUserInfo);
  commit(mutations.AUTH_SET_LOGGED_IN, true);
  commit(mutations.AUTH_SET_IS_DEVELOPER, false);
  commit(mutations.AUTH_SET_IS_ADMIN, roles.includes('Admin'));
  commit(mutations.AUTH_SET_COGNITO_USER_ID, authenticatedUserDetails.attributes.sub);
  commit(mutations.AUTH_SET_COGNITO_USER, user);
};

export const userLoggedOut = async (commit) => {
  await Auth.signOut();
  localStorage.clear();
  commit(mutations.AUTH_LOG_OUT);
};
