import * as mutations from '@/store/notices/mutations';
import { updateNotices } from '@/store/notices/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    noticesReceived: null,
    noticesReceivedDescription: null,
    developmentProposals: null,
    developmentProposalsDescription: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, noticesData) => updateNotices(commit, noticesData),
  },
};
