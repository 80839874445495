export const ALTERATIONS_PREFIX = 'building/alterations/';
export const ALTERATIONS_SET_BUILDING_WORKS = 'ALTERATIONS_SET_BUILDING_WORKS';
export const ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION = 'ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION';
export const ALTERATIONS_SET_CHANGE_OF_USE = 'ALTERATIONS_SET_CHANGE_OF_USE';
export const ALTERATIONS_SET_CHANGE_OF_USE_YEAR = 'ALTERATIONS_SET_CHANGE_OF_USE_YEAR';
export const ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION = 'ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION';
export const ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR =
  'ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR';
export const ALTERATIONS_SET_CONSERVATORY_ADDITION = 'ALTERATIONS_SET_CONSERVATORY_ADDITION';
export const ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR = 'ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR';
export const ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION =
  'ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION';
export const ALTERATIONS_SET_CHANGES_UNFINISHED = 'ALTERATIONS_SET_CHANGES_UNFINISHED';
export const ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION = 'ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION';
export const ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED =
  'ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED';
export const ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION =
  'ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION';
export const ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE = 'ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE';
export const ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION =
  'ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION';
export const ALTERATIONS_SET_SOLAR_PANELS_INSTALLED = 'ALTERATIONS_SET_SOLAR_PANELS_INSTALLED';
export const ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR = 'ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR';
export const ALTERATIONS_SET_SOLAR_PANELS_OWNED = 'ALTERATIONS_SET_SOLAR_PANELS_OWNED';
export const ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE = 'ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE';
export const ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS = 'ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS';
export const ALTERATIONS_SET_LISTED_BUILDING = 'ALTERATIONS_SET_LISTED_BUILDING';
export const ALTERATIONS_SET_CONSERVATION_AREA = 'ALTERATIONS_SET_CONSERVATION_AREA';
export const ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS =
  'ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS';
export const ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER =
  'ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER';
export const ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH = 'ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH';
export const ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS =
  'ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS';
export const ALTERATIONS_LOADED = 'ALTERATIONS_LOADED';

export const mutations = {
  [ALTERATIONS_SET_BUILDING_WORKS](state, value) {
    state.buildingWorks = value;
  },
  [ALTERATIONS_SET_BUILDING_WORKS_DESCRIPTION](state, value) {
    state.buildingWorksDescription = value;
  },
  [ALTERATIONS_SET_CHANGE_OF_USE](state, value) {
    state.changeOfUse = value;
  },
  [ALTERATIONS_SET_CHANGE_OF_USE_YEAR](state, value) {
    state.changeOfUseYear = value;
  },
  [ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION](state, value) {
    state.replacementWindowInstallation = value;
  },
  [ALTERATIONS_SET_REPLACEMENT_WINDOW_INSTALLATION_YEAR](state, value) {
    state.replacementWindowInstallationYear = value;
  },
  [ALTERATIONS_SET_CONSERVATORY_ADDITION](state, value) {
    state.conservatoryAddition = value;
  },
  [ALTERATIONS_SET_CONSERVATORY_ADDITION_YEAR](state, value) {
    state.conservatoryAdditionYear = value;
  },
  [ALTERATIONS_SET_PLANNING_PERMISSION_NOT_REQUIRED_DESCRIPTION](state, value) {
    state.planningPermissionNotRequiredDescription = value;
  },
  [ALTERATIONS_SET_CHANGES_UNFINISHED](state, value) {
    state.changesUnfinished = value;
  },
  [ALTERATIONS_SET_CHANGES_UNFINISHED_DESCRIPTION](state, value) {
    state.changesUnfinishedDescription = value;
  },
  [ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED](state, value) {
    state.planningPermissionConditionsBreached = value;
  },
  [ALTERATIONS_SET_PLANNING_PERMISSION_CONDITIONS_BREACHED_DESCRIPTION](state, value) {
    state.planningPermissionConditionsBreachedDescription = value;
  },
  [ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE](state, value) {
    state.buildingControlIssuesToResolve = value;
  },
  [ALTERATIONS_SET_BUILDING_CONTROL_ISSUES_TO_RESOLVE_DESCRIPTION](state, value) {
    state.buildingControlIssuesToResolveDescription = value;
  },
  [ALTERATIONS_SET_SOLAR_PANELS_INSTALLED](state, value) {
    state.solarPanelsInstalled = value;
  },
  [ALTERATIONS_SET_SOLAR_PANELS_INSTALLED_YEAR](state, value) {
    state.solarPanelsInstalledYear = value;
  },
  [ALTERATIONS_SET_SOLAR_PANELS_OWNED](state, value) {
    state.solarPanelsOwned = value;
  },
  [ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE](state, value) {
    state.solarPanelsLongLease = value;
  },
  [ALTERATIONS_SET_SOLAR_PANELS_LONG_LEASE_DOCUMENTS](state, value) {
    state.solarPanelsLongLeaseDocuments = value;
  },
  [ALTERATIONS_SET_LISTED_BUILDING](state, value) {
    state.listedBuilding = value;
  },
  [ALTERATIONS_SET_CONSERVATION_AREA](state, value) {
    state.conservationArea = value;
  },
  [ALTERATIONS_SET_LISTED_BUILDING_CONSERVATION_AREA_DOCUMENTS](state, value) {
    state.listedBuildingConservationAreaDocuments = value;
  },
  [ALTERATIONS_SET_TREES_SUBJECT_TO_PRESERVATION_ORDER](state, value) {
    state.treesSubjectToPreservationOrder = value;
  },
  [ALTERATIONS_SET_PRESERVATION_ORDER_COMPLIED_WITH](state, value) {
    state.preservationOrderCompliedWith = value;
  },
  [ALTERATIONS_SET_PRESERVATION_ORDER_RELEVANT_DOCUMENTS](state, value) {
    state.preservationOrderRelevantDocuments = value;
  },
  [ALTERATIONS_LOADED](state) {
    state.loaded = true;
  },
};
