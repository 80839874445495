import * as mutations from '@/store/services/mutations';
import { updateServices } from '@/store/services/actions';

export default {
  namespaced: true,
  state: {
    electricsTested: null,
    electricsTestedYear: null,
    electricsTestedCertificate: null,
    electricsRewired: null,
    electricalSafetyCertificate: null,
    complianceCertificate: null,
    completionCertificate: null,
    centralHeatingSystem: null,
    centralHeatingSystemType: null,
    centralHeatingInstallationDate: null,
    septicTankReplaced: null,
    centralHeatingBeforeSpecifiedDate: null,
    heatingSystemInWorkingOrder: null,
    heatingSystemServicedYear: null,
    heatingSystemServicedInspectionReport: null,
    foulWaterDrainage: null,
    surfaceWaterDrainage: null,
    sewerageSepticTank: null,
    sewerageTreatmentPlant: null,
    sewerageCesspool: null,
    sewerageSystemShared: null,
    sewerageSystemSharedNumber: null,
    sewerageSystemLastEmptied: null,
    sewerageSystemServicedYear: null,
    sewerageSystemInstalledYear: null,
    sewerageSystemOutsideOfProperty: null,
    sewerageSystemOutsideOfPropertyDocuments: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, servicesData) => updateServices(commit, servicesData),
  },
};
