import * as mutations from '@/store/leasehold-notices/mutations';

export const LEASEHOLD_NOTICES_UPDATE = 'leaseholdNotices/update';

export const updateLeaseholdNotices = (commit, leaseholdNoticesData) =>
  new Promise((resolve) => {
    if (leaseholdNoticesData) {
      commit(mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE, leaseholdNoticesData.sale_notice);
      commit(mutations.LEASEHOLD_NOTICE_SET_SALE_NOTICE_COPY, leaseholdNoticesData.sale_notice_copy);
      commit(mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE, leaseholdNoticesData.general_notice);
      commit(mutations.LEASEHOLD_NOTICE_SET_GENERAL_NOTICE_COPY, leaseholdNoticesData.general_notice_copy);
    }

    resolve();
  });
