export const DISPUTES_PREFIX = 'building/disputes/';
export const DISPUTES_SET_COMPLAINTS = 'DISPUTES_SET_COMPLAINTS';
export const DISPUTES_SET_COMPLAINTS_DESCRIPTION = 'DISPUTES_SET_COMPLAINTS_DESCRIPTION';
export const DISPUTES_SET_POTENTIAL = 'DISPUTES_SET_POTENTIAL';
export const DISPUTES_SET_POTENTIAL_DESCRIPTION = 'DISPUTES_SET_POTENTIAL_DESCRIPTION';
export const DISPUTES_LOADED = 'DISPUTES_LOADED';

export const mutations = {
  [DISPUTES_SET_COMPLAINTS](state, value) {
    state.disputesComplaints = value;
  },
  [DISPUTES_SET_COMPLAINTS_DESCRIPTION](state, value) {
    state.disputesComplaintsDescription = value;
  },
  [DISPUTES_SET_POTENTIAL](state, value) {
    state.potentialDispute = value;
  },
  [DISPUTES_SET_POTENTIAL_DESCRIPTION](state, value) {
    state.potentialDisputeDescription = value;
  },
  [DISPUTES_LOADED](state) {
    state.loaded = true;
  },
};
