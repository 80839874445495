export const GUARANTEES_PREFIX = 'building/guarantees/';

export const GUARANTEES_SET_NEW_HOME_WARRANTY = 'GUARANTEES_SET_NEW_HOME_WARRANTY';
export const GUARANTEES_SET_NEW_HOME_WARRANTY_COPY = 'GUARANTEES_SET_NEW_HOME_WARRANTY_COPY';
export const GUARANTEES_SET_DAMP_PROOFING = 'GUARANTEES_SET_DAMP_PROOFING';
export const GUARANTEES_SET_DAMP_PROOFING_COPY = 'GUARANTEES_SET_DAMP_PROOFING_COPY';
export const GUARANTEES_SET_TIMBER_TREATMENT = 'GUARANTEES_SET_TIMBER_TREATMENT';
export const GUARANTEES_SET_TIMBER_TREATMENT_COPY = 'GUARANTEES_SET_TIMBER_TREATMENT_COPY';
export const GUARANTEES_SET_WINDOWS = 'GUARANTEES_SET_WINDOWS';
export const GUARANTEES_SET_WINDOWS_COPY = 'GUARANTEES_SET_WINDOWS_COPY';
export const GUARANTEES_SET_ELECTRICAL_WORK = 'GUARANTEES_SET_ELECTRICAL_WORK';
export const GUARANTEES_SET_ELECTRICAL_WORK_COPY = 'GUARANTEES_SET_ELECTRICAL_WORK_COPY';
export const GUARANTEES_SET_ROOFING = 'GUARANTEES_SET_ROOFING';
export const GUARANTEES_SET_ROOFING_COPY = 'GUARANTEES_SET_ROOFING_COPY';
export const GUARANTEES_SET_CENTRAL_HEATING = 'GUARANTEES_SET_CENTRAL_HEATING';
export const GUARANTEES_SET_CENTRAL_HEATING_COPY = 'GUARANTEES_SET_CENTRAL_HEATING_COPY';
export const GUARANTEES_SET_UNDER_PINNING = 'GUARANTEES_SET_UNDER_PINNING';
export const GUARANTEES_SET_UNDER_PINNING_COPY = 'GUARANTEES_SET_UNDER_PINNING_COPY';
export const GUARANTEES_SET_OTHER = 'GUARANTEES_SET_OTHER';
export const GUARANTEES_SET_OTHER_COPY = 'GUARANTEES_SET_OTHER_COPY';
export const GUARANTEES_SET_OTHER_DESCRIPTION = 'GUARANTEES_SET_OTHER_DESCRIPTION';
export const GUARANTEES_SET_CLAIMS = 'GUARANTEES_SET_CLAIMS';
export const GUARANTEES_SET_CLAIMS_DESCRIPTION = 'GUARANTEES_SET_CLAIMS_DESCRIPTION';
export const GUARANTEES_LOADED = 'GUARANTEES_LOADED';

export const mutations = {
  [GUARANTEES_SET_NEW_HOME_WARRANTY](state, value) {
    state.newHomeWarranty = value;
  },
  [GUARANTEES_SET_NEW_HOME_WARRANTY_COPY](state, value) {
    state.newHomeWarrantyCopy = value;
  },
  [GUARANTEES_SET_DAMP_PROOFING](state, value) {
    state.dampProofing = value;
  },
  [GUARANTEES_SET_DAMP_PROOFING_COPY](state, value) {
    state.dampProofingCopy = value;
  },
  [GUARANTEES_SET_TIMBER_TREATMENT](state, value) {
    state.timberTreatment = value;
  },
  [GUARANTEES_SET_TIMBER_TREATMENT_COPY](state, value) {
    state.timberTreatmentCopy = value;
  },
  [GUARANTEES_SET_WINDOWS](state, value) {
    state.windows = value;
  },
  [GUARANTEES_SET_WINDOWS_COPY](state, value) {
    state.windowsCopy = value;
  },
  [GUARANTEES_SET_ELECTRICAL_WORK](state, value) {
    state.electricalWork = value;
  },
  [GUARANTEES_SET_ELECTRICAL_WORK_COPY](state, value) {
    state.electricalWorkCopy = value;
  },
  [GUARANTEES_SET_ROOFING](state, value) {
    state.roofing = value;
  },
  [GUARANTEES_SET_ROOFING_COPY](state, value) {
    state.roofingCopy = value;
  },
  [GUARANTEES_SET_CENTRAL_HEATING](state, value) {
    state.centralHeating = value;
  },
  [GUARANTEES_SET_CENTRAL_HEATING_COPY](state, value) {
    state.centralHeatingCopy = value;
  },
  [GUARANTEES_SET_UNDER_PINNING](state, value) {
    state.underpinning = value;
  },
  [GUARANTEES_SET_UNDER_PINNING_COPY](state, value) {
    state.underpinningCopy = value;
  },
  [GUARANTEES_SET_OTHER](state, value) {
    state.other = value;
  },
  [GUARANTEES_SET_OTHER_COPY](state, value) {
    state.otherCopy = value;
  },
  [GUARANTEES_SET_OTHER_DESCRIPTION](state, value) {
    state.otherDescription = value;
  },
  [GUARANTEES_SET_CLAIMS](state, value) {
    state.claims = value;
  },
  [GUARANTEES_SET_CLAIMS_DESCRIPTION](state, value) {
    state.claimsDescription = value;
  },
  [GUARANTEES_LOADED](state) {
    state.loaded = true;
  },
};
