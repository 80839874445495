import * as mutations from '@/store/boundaries/mutations';
import { updateBoundaries } from '@/store/boundaries/actions';

export default {
  namespaced: true,
  state: {
    boundaryRight: null,
    boundaryLeft: null,
    boundaryFront: null,
    boundaryRear: null,
    irregular: null,
    featureMoved: null,
    featureMovedDescription: null,
    landSoldPurchased: null,
    landSoldPurchasedDescription: null,
    overhangProjectUnder: null,
    overhangProjectUnderDescription: null,
    partyWallAct: null,
    partyWallActNotice: null,
    partyWallActDescription: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, boundariesData) => updateBoundaries(commit, boundariesData),
  },
};
