// import * as personOccupierActions from '@/store/personsOccupiers/actions';
import { fetchFromApi } from '@/store/building/actions';
import * as boundaries from '@/store/boundaries/module';
import * as disputes from '@/store/disputes/module';
import * as notices from '@/store/notices/module';
import * as alterations from '@/store/alterations/module';
import * as guarantees from '@/store/guarantees/module';
import * as complaints from '@/store/complaints/module';
import * as consents from '@/store/consents/module';
import * as contact from '@/store/contact/module';
import * as enfranchisement from '@/store/enfranchisement/module';
import * as environmentalMatter from '@/store/environmentalMatter/module';
import * as rights from '@/store/rights/module';
import * as insurance from '@/store/insurance/module';
import * as leaseholdInformation from '@/store/leaseholdInformation/module';
import * as leaseholdAlterations from '@/store/leaseholdAlterations/module';
import * as leaseholdNotices from '@/store/leasehold-notices/module';
import * as leaseholdSafety from '@/store/leaseholdSafety/module';
import * as maintenance from '@/store/maintenance/module';
import * as relevantDocuments from '@/store/relevantDocuments/module';
import * as occupiers from '@/store/occupiers/module';
import * as ownership from '@/store/ownership/module';
import * as utilityConnections from '@/store/utility-connections/module';
import * as parkingDetails from '@/store/parkingDetails/module';
import * as otherCharges from '@/store/otherCharges/module';
import * as transactionInformation from '@/store/transactionInformation/module';
import * as services from '@/store/services/module';
import * as rooms from '@/store/rooms/module';
import * as mutations from '@/store/building/mutations';

export default {
  namespaced: true,
  modules: {
    boundary: { ...boundaries.default },
    disputes: { ...disputes.default },
    notices: { ...notices.default },
    alterations: { ...alterations.default },
    guarantees: { ...guarantees.default },
    complaints: { ...complaints.default },
    consents: { ...consents.default },
    contact: { ...contact.default },
    enfranchisement: { ...enfranchisement.default },
    environmentalMatters: { ...environmentalMatter.default },
    rights: { ...rights.default },
    insurance: { ...insurance.default },
    leaseholdInformation: { ...leaseholdInformation.default },
    leaseholdAlterations: { ...leaseholdAlterations.default },
    leaseholdNotices: { ...leaseholdNotices.default },
    leaseholdSafety: { ...leaseholdSafety.default },
    maintenance: { ...maintenance.default },
    relevantDocuments: { ...relevantDocuments.default },
    occupiers: { ...occupiers.default },
    ownership: { ...ownership.default },
    utilityConnections: { ...utilityConnections.default },
    parkingDetails: { ...parkingDetails.default },
    otherCharges: { ...otherCharges.default },
    transactionInformation: { ...transactionInformation.default },
    services: { ...services.default },
    rooms: { ...rooms.default },
  },
  state: {
    loaded: false,
    buildingId: null,
    address: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    fetch: (context, buildingId) => fetchFromApi(context, buildingId),
  },
};
