import axios from 'axios';
import store from '../store';

const errorStatuses = [400, 404, 500, 503];

export default () => {
  axios.interceptors.response.use(
    (response) => response,
    async function (error) {
      if (error.config?.noRetry !== true && errorStatuses.includes(error?.response?.status)) {
        for (let i = 0; i < 5; i++) {
          await new Promise((r) => setTimeout(r, 3000));
          let result = await axios.request({ ...error.config, noRetry: true }).catch(({ response }) => response);
          if (result.status >= 200 && result.status < 300) {
            return result;
          }
        }
        // Display modal with unexpected error info
        store.commit('setUnexpectedError', true);
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );
};
