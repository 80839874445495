import getBuilding from '@/api/buildings/getBuilding';
import * as boundariesActions from '@/store/boundaries/actions';
import * as mutations from '@/store/building/mutations';
import * as disputesActions from '@/store/disputes/actions';
import * as noticesActions from '@/store/notices/actions';
import * as alterationsActions from '@/store/alterations/actions';
import * as guaranteesActions from '@/store/guarantees/actions';
import * as complaintsActions from '@/store/complaints/actions';
import * as consentsActions from '@/store/consents/actions';
import * as contactActions from '@/store/contact/actions';
import * as enfranchisementActions from '@/store/enfranchisement/actions';
import * as environmentalMatterActions from '@/store/environmentalMatter/actions';
import * as rightsActions from '@/store/rights/actions';
import * as leaseholdInformationActions from '@/store/leaseholdInformation/actions';
import * as leaseholdAlterationsActions from '@/store/leaseholdAlterations/actions';
import * as leaseholdNoticesActions from '@/store/leasehold-notices/actions';
import * as leaseholdSafetyActions from '@/store/leaseholdSafety/actions';
import * as maintenanceActions from '@/store/maintenance/actions';
import * as insuranceActions from '@/store/insurance/actions';
import * as relevantDocumentsActions from '@/store/relevantDocuments/actions';
import * as ownershipActions from '@/store/ownership/actions';
import * as servicesActions from '@/store/services/actions';
import * as occupiersActions from '@/store/occupiers/actions';
import * as utilityConnectionsActions from '@/store/utility-connections/actions';
import * as parkingDetailsActions from '@/store/parkingDetails/actions';
import * as otherChargesActions from '@/store/otherCharges/actions';
import * as transactionInformationActions from '@/store/transactionInformation/actions';
import * as roomsActions from '@/store/rooms/actions';
import sortBy from 'lodash/sortBy';

export const BUILDING_FETCH = 'building/fetch';

export const fetchFromApi = ({ commit, dispatch }, buildingId) =>
  new Promise((resolve) => {
    getBuilding(buildingId).then((response) => {
      dispatch(boundariesActions.BOUNDARIES_UPDATE, response.data.boundary);
      dispatch(leaseholdInformationActions.LEASEHOLD_INFORMATION_UPDATE, response.data.leasehold_information);
      dispatch(relevantDocumentsActions.RELEVANT_DOCUMENTS_UPDATE, response.data.relevant_document);
      dispatch(ownershipActions.OWNERSHIP_UPDATE, response.data.ownership ?? {});
      dispatch(disputesActions.DISPUTES_UPDATE, response.data.dispute);
      dispatch(noticesActions.NOTICES_UPDATE, response.data.notice);
      dispatch(alterationsActions.ALTERATIONS_UPDATE, response.data.alteration);
      dispatch(guaranteesActions.GUARANTEES_UPDATE, response.data.guarantee);
      dispatch(complaintsActions.COMPLAINTS_UPDATE, response.data.complaint);
      dispatch(consentsActions.CONSENTS_UPDATE, response.data.consent);
      dispatch(contactActions.CONTACT_UPDATE, response.data.contact);
      dispatch(enfranchisementActions.ENFRANCHISEMENT_UPDATE, response.data.enfranchisement);
      dispatch(environmentalMatterActions.ENVIRONMENTAL_MATTERS_UPDATE, response.data.environmental_matter);
      dispatch(rightsActions.RIGHTS_UPDATE, response.data.right);
      dispatch(insuranceActions.INSURANCE_UPDATE, response.data.insurance);
      dispatch(leaseholdAlterationsActions.LEASEHOLD_ALTERATIONS_UPDATE, response.data.leasehold_alteration);
      dispatch(leaseholdNoticesActions.LEASEHOLD_NOTICES_UPDATE, response.data.leasehold_notice);
      dispatch(leaseholdSafetyActions.LEASEHOLD_SAFETY_UPDATE, response.data.leasehold_safety);
      dispatch(maintenanceActions.MAINTENANCE_UPDATE, response.data.maintenance);
      dispatch(occupiersActions.OCCUPIERS_UPDATE, response.data.occupier);
      dispatch(servicesActions.SERVICES_UPDATE, response.data.service);
      dispatch(utilityConnectionsActions.UTILITY_CONNECTIONS_UPDATE, response.data.utility_connection);
      dispatch(parkingDetailsActions.PARKING_DETAILS_UPDATE, response.data.parking);
      dispatch(otherChargesActions.OTHER_CHARGES_UPDATE, response.data.other_charges);
      dispatch(transactionInformationActions.TRANSACTION_INFORMATION_UPDATE, response.data.transaction);
      dispatch(roomsActions.ROOMS_UPDATE, sortBy(response.data.rooms, 'title'));

      commit(mutations.BUILDING_SET_ADDRESS, response.data.address);
      commit(mutations.BUILDING_SET_ID, response.data.building_id);
      commit(mutations.BUILDING_LOADED);
      resolve();
    });
  });
