import * as mutations from '@/store/leaseholdSafety/mutations';
import { updateLeaseholdSafety } from '@/store/leaseholdSafety/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    remediationWorks: null,
    remediationWorksCopy: null,
    qualifyingLease: null,
    deedOfCertificate: null,
    deedOfCertificateCompletion: null,
    deedOfCertificateCopy: null,
    notifiedOfSellIntention: null,
    landlordsCertificateReceived: null,
    landlordsCertificateCopy: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, leaseholdSafetyData) => updateLeaseholdSafety(commit, leaseholdSafetyData),
  },
};
