import * as mutations from '@/store/contact/mutations';

export const CONTACT_UPDATE = 'contact/update';

export const updateContact = (commit, contactData) =>
  new Promise((resolve) => {
    if (contactData) {
      commit(mutations.CONTACT_LANDLORD_NAME, contactData.landlord_name);
      commit(mutations.CONTACT_LANDLORD_ADDRESS, contactData.landlord_address);
      commit(mutations.CONTACT_LANDLORD_TEL, contactData.landlord_tel);
      commit(mutations.CONTACT_LANDLORD_EMAIL, contactData.landlord_email);
      commit(mutations.CONTACT_LANDLORD_AGENT_NAME, contactData.landlord_agent_name);
      commit(mutations.CONTACT_LANDLORD_AGENT_ADDRESS, contactData.landlord_agent_address);
      commit(mutations.CONTACT_LANDLORD_AGENT_TEL, contactData.landlord_agent_tel);
      commit(mutations.CONTACT_LANDLORD_AGENT_EMAIL, contactData.landlord_agent_email);
      commit(mutations.CONTACT_TENANT_AGENT_NAME, contactData.tenant_agent_name);
      commit(mutations.CONTACT_TENANT_AGENT_ADDRESS, contactData.tenant_agent_address);
      commit(mutations.CONTACT_TENANT_AGENT_TEL, contactData.tenant_agent_tel);
      commit(mutations.CONTACT_TENANT_AGENT_EMAIL, contactData.tenant_agent_email);
    }

    resolve();
  });
