import getAllLenders from '@/api/lenders/getAllLenders';
import * as mutations from '@/store/lenders/mutations';

export const LENDERS_FETCH_ALL = 'lenders/fetch';

export const fetchFromApi = (commit) =>
  new Promise((resolve) => {
    getAllLenders()
      .then(async (response) => {
        const lenders = response.data || [];

        // Map the raw result to a format suitable for use
        // in a dropdown list.
        let lenderNames = lenders.map((lender) => {
          // eslint-disable-line
          return { id: lender.sortrefer_id, label: lender.name };
        });

        // Add the 'Other' option if not present
        if (!lenderNames.some((l) => l.id === 0)) {
          lenderNames.push({ id: 0, label: 'Other (please specify)' });
        }

        // Sort the list
        const sortedLenderNames = lenderNames.sort((a, b) => {
          return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
        });

        commit(mutations.LENDERS_SET_ALL, sortedLenderNames);

        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
