import * as mutations from '@/store/complaints/mutations';

export const COMPLAINTS_UPDATE = 'complaints/update';

export const updateComplaints = (commit, complaintsData) =>
  new Promise((resolve) => {
    if (complaintsData) {
      commit(mutations.COMPLAINTS_SET_LANDLORD, complaintsData.landlord_complaint);
      commit(mutations.COMPLAINTS_SET_LANDLORD_DESCRIPTION, complaintsData.landlord_complaint_description);
      commit(mutations.COMPLAINTS_SET_SELLER, complaintsData.seller_complaint);
      commit(mutations.COMPLAINTS_SET_SELLER_DESCRIPTION, complaintsData.seller_complaint_description);
    }

    resolve();
  });
