import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

// Modules
import auth from '@/store/auth/module';
import persons from '@/store/persons/module';
import personsOccupiers from '@/store/personsOccupiers/module';
import sale from '@/store/sale/module';
import purchase from '@/store/purchase/module';
import remortgage from '@/store/remortgage/module';
import building from '@/store/building/module';
import firm from '@/store/firm/module';
import paymentDetails from '@/store/payment/module';
import documents from '@/store/documents/module';
import lenders from '@/store/lenders/module';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
    loading: state.loading,
    buildingId: state.buildingId,
    saleId: state.saleId,
    purchaseId: state.purchaseId,
    remortgageId: state.remortgageId,
    firmId: state.firmId,
    sortReferId: state.sortReferId,
    paymentDetails: state.paymentDetails,
    unexpectedError: state.unexpectedError,
  }),
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production', // This slows Vue down in production but helps us write good code
  modules: {
    auth,
    persons,
    personsOccupiers,
    sale,
    purchase,
    remortgage,
    firm,
    building,
    paymentDetails,
    documents,
    lenders,
  },
  state: {
    loading: false,
    buildingId: null,
    saleId: null,
    purchaseId: null,
    remortgageId: null,
    firmId: null,
    personId: null,
    sortReferId: null,
    paymentDetails: null,
    unexpectedError: false,
  },
  mutations: {
    ...paymentDetails.mutations,
    setLoading(state, loading) {
      state.loading = loading;
    },
    setBuildingId(state, id) {
      state.buildingId = id;
    },
    setSaleId(state, id) {
      state.saleId = id;
    },
    setPurchaseId(state, id) {
      state.purchaseId = id;
    },
    setRemortgageId(state, id) {
      state.remortgageId = id;
    },
    setFirmId(state, id) {
      state.firmId = id;
    },
    setSortReferId(state, id) {
      state.sortReferId = id;
    },
    setUnexpectedError(state, unexpectedError) {
      state.unexpectedError = unexpectedError;
    },
  },
  actions: {
    ...paymentDetails.actions,
  },
  plugins: [vuexLocalStorage.plugin],
});
