import getSale from '@/api/sales/getSale';
import getSaleQuestionnaireSubmissionStatus from '@/api/sales/getSaleQuestionnaireSubmissionStatus';
import postTrySubmitSaleQuestionnaire from '@/api/sales/postTrySubmitSaleQuestionnaire';
import { SUBMISSION_NOT_INITIATED } from '@/values/cqSubmissionStatuses';
import * as mutations from '@/store/sale/mutations';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';

export const SALE_FETCH = 'sale/fetch';
export const SALE_FETCH_SUBMISSION_STATUS = 'sale/fetchSubmissionStatus';
export const SALE_RESET_SUBMISSION_STATUS = 'sale/resetSubmissionStatus';
export const SALE_TRY_SUBMIT = 'sale/trySubmitSaleQuestionnaire';

export const refreshTrackingEvents = async ({ commit }, id) => {
  const trackingEvents = (await getTrackingEvents(id, [])).data;
  if (trackingEvents?.results) {
    commit(mutations.REMORTGAGE_SET_TRACKING_EVENTS, trackingEvents?.results);
  }
};

export const setTrackingEvents = (commit, trackingEvents) => {
  commit(mutations.SALE_SET_TRACKING_EVENTS, trackingEvents);
};

export const addTrackingEvent = (commit, trackingEvent) => {
  commit(mutations.SALE_ADD_TRACKING_EVENT, trackingEvent);
};

export const setEventToHandle = (commit, eventToHandle) => {
  commit(mutations.SALE_SET_EVENT_TO_HANDLE, eventToHandle);
};

const processSubmissionStatus = (commit, response) => {
  const status = response.data.status;
  commit(mutations.SALE_SET_SUBMISSION_STATUS, status);
  if (status === 'name_mismatch') {
    commit(mutations.SALE_SET_CLIENT_MATCH_DETAILS, response.data.match_details);
    if (response.data.hmlr_title_document) {
      commit(mutations.SALE_SET_HMLR_TITLE_DOCUMENT_ID, response.data.hmlr_title_document_id);
    }
  }
};

export const fetchSubmissionStatus = (commit, saleId) => {
  return new Promise((resolve) => {
    getSaleQuestionnaireSubmissionStatus(saleId)
      .then(async (response) => {
        processSubmissionStatus(commit, response);
        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const resetSubmissionStatus = (commit) => {
  return new Promise((resolve) => {
    commit(mutations.SALE_SET_SUBMISSION_STATUS, SUBMISSION_NOT_INITIATED);
    resolve();
  });
};

export const trySubmitSaleQuestionnaire = (commit, payload) => {
  const { saleId, reason, additionalInfo } = payload;
  return new Promise((resolve) => {
    postTrySubmitSaleQuestionnaire(saleId, reason, additionalInfo)
      .then(async (response) => {
        processSubmissionStatus(commit, response);
        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const fetchFromApi = (commit, saleId) =>
  new Promise((resolve) => {
    getSale(saleId)
      .then(async (response) => {
        commit(mutations.SALE_SET_SALE_ID, response.data.sale_id);
        commit(mutations.SALE_SET_AUTHORITY_PURCHASE, response.data.authority_purchase);
        commit(mutations.SALE_SET_AUTHORITY_PURCHASE_DETAILS, response.data.authority_purchase_details);
        commit(mutations.SALE_SET_PROPERTY_TYPE, response.data.property_type);
        commit(mutations.SALE_SET_PROPERTY_TYPE_OTHER, response.data.property_type_other);
        commit(mutations.SALE_SET_PURCHASE_TYPE, response.data.purchase_type);
        commit(mutations.SALE_SET_COUNCIL_TAX_BAND, response.data.council_tax_band);
        commit(mutations.SALE_SET_BUYER_NAME, response.data.buyer_name);
        commit(mutations.SALE_SET_PRICE, Number(response.data.sale_price));
        commit(mutations.SALE_SET_POST_COMPLETION_PHONE, response.data.post_completion_phone);
        commit(mutations.SALE_SET_ESTATE_AGENT_ID, response.data.estate_agent_id);
        commit(mutations.SALE_SET_FIXTURE_PRICE, Number(response.data.fixture_price));
        commit(mutations.SALE_SET_AGENT_FEE, Number(response.data.agent_fee));
        commit(mutations.SALE_SET_AGENT_FEE_TYPE, response.data.agent_fee_type);
        commit(mutations.SALE_SET_AGENT_FEE_PAYMENT_PERMISSION, response.data.agent_fee_payment_permission);
        commit(mutations.SALE_SET_RELATED_CASE, response.data.related_case);
        commit(mutations.SALE_SET_RELATED_CASES, response.data.related_cases || []);
        commit(mutations.SALE_SET_POST_COMPLETION_ADDRESS, response.data.post_completion_address);
        commit(mutations.SALE_SET_SPECIAL_PURCHASE, response.data.special_purchase);
        commit(mutations.SALE_SET_SPECIAL_PURCHASE_DETAILS, response.data.special_purchase_details);
        commit(mutations.SALE_SET_MORTGAGES_OR_CHARGES, response.data.mortgages_or_charges || []);
        commit(mutations.SALE_SET_LEASEHOLD_TO_FREEHOLD, response.data.leasehold_to_freehold);
        commit(mutations.SALE_SET_TITLE_NUMBERS, response.data.title_numbers);
        commit(mutations.SALE_SET_DESIRED_COMPLETION_DATE, response.data.desired_completion_date);
        commit(mutations.SALE_SET_EXCHANGE_DEADLINE, response.data.exchange_deadline);
        commit(mutations.SALE_SET_COMPLETION_DEADLINE, response.data.completion_deadline);
        commit(mutations.SALE_SET_SURPLUS_PAYMENT_METHOD, response.data.surplus_payment_method);
        commit(mutations.SALE_SET_BUILDING_ID, response.data.building_id);
        commit(mutations.SALE_SET_SORT_REFER_ID, response.data.sort_refer_id);
        commit(mutations.SALE_SET_SUPPLIER_INFORMATION, response.data.supplier_information);
        commit(mutations.SALE_SET_SAGEPAY_TRANSACTION_ID, response.data.sagepay_transaction_id);
        commit(mutations.SALE_SET_CHIEF_OR_GROUND_RENT, response.data.chief_or_ground_rent);
        commit(mutations.SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS, response.data.chief_or_ground_rent_address);
        commit(mutations.SALE_SET_CHIEF_OR_GROUND_RENT_PHONE, response.data.chief_or_ground_rent_phone);
        commit(mutations.SALE_SET_PRODUCT_CODE, response.data.product_code);
        commit(mutations.SALE_SET_SOLD_BY_ESTATE_AGENT, response.data.sold_by_estate_agent);
        commit(mutations.SALE_SET_CONFIG, response.data.config);
        commit(mutations.SALE_SET_PASS_STATEMENTS_TO_BROKER, response.data.pass_statements_to_broker);
        commit(mutations.SALE_SET_ADDITIONAL_INFO, response.data.additional_info);

        if (response.data.hasOwnProperty('bank_details') && response.data.bank_details) {
          const bank_details = response.data.bank_details;
          commit(mutations.SALE_SET_BANK_NAME, bank_details.bank_name);
          commit(mutations.SALE_SET_BANK_ACCOUNT_NAME, bank_details.account_name);
          commit(mutations.SALE_SET_BANK_ACCOUNT_NUMBER, bank_details.account_number);
          commit(mutations.SALE_SET_BANK_BACS_OR_CHAPS, bank_details.bacs_or_chaps);
          commit(mutations.SALE_SET_BANK_SORT_CODE, bank_details.sort_code);
        } else {
          commit(mutations.SALE_RESET_BANK_DETAILS);
        }

        commit(mutations.SALE_SET_SOLD_BY_COMPANY, response.data.sold_by_company);
        commit(mutations.SALE_SET_SOLD_BY_COMPANY_DETAILS, response.data.sold_by_company_details);
        commit(mutations.SALE_SET_TITLE_DEED_DECLARED, response.data.title_deed_declared);
        commit(mutations.SALE_SET_TITLE_DEED_ALTERNATIVE, response.data.title_deed_alternative);
        commit(mutations.SALE_SET_TITLE_DEED_ALTERNATIVES, response.data.title_deed_alternatives || []);

        const clientIds = response.data.client_ids || [];
        commit(mutations.SALE_SET_CLIENT_IDS, clientIds);

        const occupierIds = response.data.occupier_ids || [];
        commit(mutations.SALE_SET_OCCUPIER_IDS, occupierIds);

        commit(mutations.SALE_LOADED);

        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
