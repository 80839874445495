import * as mutations from '@/store/leasehold-notices/mutations';
import { updateLeaseholdNotices } from '@/store/leasehold-notices/actions';

export default {
  namespaced: true,
  state: {
    loaded: false,
    saleNotice: null,
    saleNoticeCopy: null,
    generalNotice: null,
    generalNoticeCopy: null,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, disputesData) => updateLeaseholdNotices(commit, disputesData),
  },
};
