const hooyuRedirect = async (to, from, next) => {
  if (to.query?.link) {
    window.location.href = to.query?.link;
    return;
  }
  if (to.query?.reference && to.query.hasOwnProperty('expired')) {
    alert('Link has expired.');
    close();
    return;
  }
  return next();
};

export default hooyuRedirect;
