import * as mutations from '@/store/documents/mutations';
import getDocuments from '@/api/documentManager/getDocuments';
import deleteDocumentById from '@/api/documentManager/deleteDocumentById';
import sortBy from 'lodash/sortBy';

export const addDocument = (commit, document) => {
  commit(mutations.DOCUMENT_ADD, document);
};

export const removeDocument = (commit, documentId) =>
  new Promise(async (resolve, reject) => {
    try {
      await deleteDocumentById(documentId);
      commit(mutations.DOCUMENT_REMOVE, documentId);
      resolve();
    } catch (e) {
      reject(e);
    }
  });

export const fetchDocuments = (commit, entityId) => {
  commit(mutations.DOCUMENT_CLEAR_ALL);
  commit(mutations.DOCUMENT_SET_SHOW_ERROR, false);
  commit(mutations.DOCUMENT_SET_LOADING_STATUS, true);
  getDocuments(entityId)
    .then((documents) => {
      if (documents.status !== 200) {
        console.error('Cannot access documents');
      }
      commit(mutations.DOCUMENT_CLEAR_ALL);
      commit(mutations.DOCUMENT_SET_SHOW_ERROR, false);
      commit(mutations.DOCUMENT_SET_LOADING_STATUS, true);

      documents = sortBy(documents.data.results, 'document_name');
      documents.forEach((document) => {
        if (typeof document.metadata.hidden === 'undefined' || !document.metadata.hidden) {
          commit(mutations.DOCUMENT_ADD, document);
        }
      });
      commit(mutations.DOCUMENT_SET_LOADING_STATUS, false);
    })
    .catch((err) => {
      console.debug(err);
      commit(mutations.DOCUMENT_SET_LOADING_STATUS, false);
      commit(mutations.DOCUMENT_SET_SHOW_ERROR, true);
    });
};

export const clearDocuments = (commit) => {
  commit(mutations.DOCUMENT_ADD);
};
