import * as mutations from '@/store/parkingDetails/mutations';

export const PARKING_DETAILS_UPDATE = 'parkingDetails/update';

export const updateParkingDetails = (commit, parkingDetailsData) =>
  new Promise((resolve) => {
    if (parkingDetailsData) {
      commit(mutations.PARKING_DETAILS_SET_ARRANGEMENTS_DESCRIPTION, parkingDetailsData.arrangements_description);
      commit(mutations.PARKING_DETAILS_SET_CONTROLLED_PARKING_ZONE, parkingDetailsData.controlled_parking_zone);
    }

    resolve();
  });
