import getRemortgage from '@/api/remortgages/getRemortgage';
import getRemortgageQuestionnaireSubmissionStatus from '@/api/remortgages/getRemortgageQuestionnaireSubmissionStatus';
import postTrySubmitRemortgageQuestionnaire from '@/api/remortgages/postTrySubmitRemortgageQuestionnaire';
import { SUBMISSION_NOT_INITIATED } from '@/values/cqSubmissionStatuses';
import * as mutations from '@/store/remortgage/mutations';
import getTrackingEvents from '@/api/trackingEvents/getTrackingEvents';

export const REMORTGAGE_FETCH = 'remortgage/fetch';
export const REMORTGAGE_FETCH_SUBMISSION_STATUS = 'remortgage/fetchSubmissionStatus';
export const REMORTGAGE_RESET_SUBMISSION_STATUS = 'remortgage/resetSubmissionStatus';
export const REMORTGAGE_TRY_SUBMIT = 'remortgage/trySubmitRemortgageQuestionnaire';

export const refreshTrackingEvents = async ({ commit }, id) => {
  const trackingEvents = (await getTrackingEvents(id, [])).data;
  if (trackingEvents?.results) {
    commit(mutations.REMORTGAGE_SET_TRACKING_EVENTS, trackingEvents?.results);
  }
};

export const setTrackingEvents = (commit, trackingEvents) => {
  commit(mutations.REMORTGAGE_SET_TRACKING_EVENTS, trackingEvents);
};

export const addTrackingEvent = (commit, trackingEvent) => {
  commit(mutations.REMORTGAGE_ADD_TRACKING_EVENT, trackingEvent);
};

export const setEventToHandle = (commit, eventToHandle) => {
  commit(mutations.REMORTGAGE_SET_EVENT_TO_HANDLE, eventToHandle);
};

const processSubmissionStatus = (commit, response) => {
  const status = response.data.status;
  commit(mutations.REMORTGAGE_SET_SUBMISSION_STATUS, status);
  if (status === 'name_mismatch') {
    commit(mutations.REMORTGAGE_SET_CLIENT_MATCH_DETAILS, response.data.match_details);
    if (response.data.hmlr_title_document_id) {
      commit(mutations.REMORTGAGE_SET_HMLR_TITLE_DOCUMENT_ID, response.data.hmlr_title_document_id);
    }
  }
};

export const fetchSubmissionStatus = (commit, remortgageId) => {
  return new Promise((resolve) => {
    getRemortgageQuestionnaireSubmissionStatus(remortgageId)
      .then(async (response) => {
        processSubmissionStatus(commit, response);
        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const resetSubmissionStatus = (commit) => {
  return new Promise((resolve) => {
    commit(mutations.REMORTGAGE_SET_SUBMISSION_STATUS, SUBMISSION_NOT_INITIATED);
    resolve();
  });
};

export const trySubmitRemortgageQuestionnaire = (commit, payload) => {
  const { remortgageId, reason, additionalInfo } = payload;
  return new Promise((resolve) => {
    postTrySubmitRemortgageQuestionnaire(remortgageId, reason, additionalInfo)
      .then(async (response) => {
        processSubmissionStatus(commit, response);
        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const fetchFromApi = (commit, remortgageId) =>
  new Promise((resolve) => {
    getRemortgage(remortgageId)
      .then(async (response) => {
        commit(mutations.REMORTGAGE_SET_REMORTGAGE_ID, response.data.remortgage_id);
        commit(mutations.REMORTGAGE_SET_ADDITIONAL_INFO, response.data.additional_info);
        commit(mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_KEEP, response.data.additional_lender_keep);
        commit(mutations.REMORTGAGE_SET_CONFIG, response.data.config);
        commit(mutations.REMORTGAGE_SET_ADDRESS, response.data.address);
        commit(mutations.REMORTGAGE_SET_LEASEHOLD_ADDITIONAL_INFO, response.data.leasehold_additional_info);
        commit(mutations.REMORTGAGE_SET_PASS_STATEMENTS_TO_BROKER, response.data.pass_statements_to_broker);
        commit(mutations.REMORTGAGE_SET_SORT_REFER_ID, response.data.sort_refer_id);
        commit(mutations.REMORTGAGE_SET_SUPPLIER_INFORMATION, response.data.supplier_information);
        commit(mutations.REMORTGAGE_SET_TENURE_TYPE, response.data.tenure_type);
        commit(mutations.REMORTGAGE_SET_STANDALONE_TOE, response.data.standalone_toe);
        commit(mutations.REMORTGAGE_SET_EXISTING_MORTGAGE, response.data.existing_mortgage);

        if (response.data.hasOwnProperty('current_lender') && response.data.current_lender) {
          const current_lender = response.data.current_lender;
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_NAME, current_lender.name);
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_ACCOUNT_NUMBER, current_lender.account_number);
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_AMOUNT_OWED, current_lender.amount_owed);
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_MAKING_OVERPAYMENT, current_lender.making_overpayment);
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_OVERPAYMENT_AMOUNT, current_lender.overpayment_amount);
          commit(
            mutations.REMORTGAGE_SET_CURRENT_LENDER_BANK_STATEMENT_DOCUMENTS,
            current_lender.bank_statement_documents,
          );
          commit(mutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING, current_lender.additional_borrowing);
          commit(
            mutations.REMORTGAGE_SET_CURRENT_LENDER_ADDITIONAL_BORROWING_AMOUNT,
            current_lender.additional_borrowing_amount,
          );
        } else {
          commit(mutations.REMORTGAGE_RESET_CURRENT_LENDER_DETAILS);
        }

        if (response.data.hasOwnProperty('additional_lender') && response.data.additional_lender) {
          const additional_lender = response.data.additional_lender;
          commit(mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_NAME, additional_lender.name);
          commit(mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_ACCOUNT_NUMBER, additional_lender.account_number);
          commit(mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_AMOUNT_OWED, additional_lender.amount_owed);
          commit(mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE, additional_lender.help_to_buy_charge);
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_HELP_TO_BUY_CHARGE_PAYING_OFF,
            additional_lender.help_to_buy_charge_paying_off,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_PAY_OFF,
            additional_lender.target_hca_to_pay_off,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_TARGET_HCA_TO_REMORTGAGE,
            additional_lender.target_hca_to_remortgage,
          );
        } else {
          commit(mutations.REMORTGAGE_RESET_ADDITIONAL_LENDER_DETAILS);
        }

        if (
          response.data.hasOwnProperty('early_repayment_charge_additional_lender') &&
          response.data.early_repayment_charge_additional_lender
        ) {
          const early_repayment_charge_additional_lender = response.data.early_repayment_charge_additional_lender;
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE,
            early_repayment_charge_additional_lender.erc_penalty,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_END_DATE,
            early_repayment_charge_additional_lender.erc_end_date,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_EXISTS,
            early_repayment_charge_additional_lender.erc_exists,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION,
            early_repayment_charge_additional_lender.erc_postpone_completion,
          );
          commit(
            mutations.REMORTGAGE_SET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_UNKNOWN,
            early_repayment_charge_additional_lender.erc_penalty_unknown,
          );
        } else {
          commit(mutations.REMORTGAGE_RESET_ADDITIONAL_LENDER_EARLY_REPAYMENT_CHARGE_DETAILS);
        }

        if (response.data.hasOwnProperty('new_lender') && response.data.new_lender) {
          const new_lender = response.data.new_lender;
          commit(mutations.REMORTGAGE_SET_NEW_LENDER_NAME, new_lender.name);
          commit(mutations.REMORTGAGE_SET_NEW_LENDER_AMOUNT_OWED, new_lender.amount_owed);
        } else {
          commit(mutations.REMORTGAGE_RESET_NEW_LENDER_DETAILS);
        }

        if (response.data.hasOwnProperty('early_repayment_charge') && response.data.early_repayment_charge) {
          const early_repayment_charge = response.data.early_repayment_charge;
          commit(mutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE, early_repayment_charge.erc_penalty);
          commit(mutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_END_DATE, early_repayment_charge.erc_end_date);
          commit(mutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_EXISTS, early_repayment_charge.erc_exists);
          commit(
            mutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_POSTPONE_COMPLETION,
            early_repayment_charge.erc_postpone_completion,
          );
          commit(mutations.REMORTGAGE_SET_EARLY_REPAYMENT_CHARGE_UNKNOWN, early_repayment_charge.erc_penalty_unknown);
        } else {
          commit(mutations.REMORTGAGE_RESET_EARLY_REPAYMENT_CHARGE_DETAILS);
        }

        if (response.data.hasOwnProperty('bank_details') && response.data.bank_details) {
          const bank_details = response.data.bank_details;
          commit(mutations.REMORTGAGE_SET_BANK_NAME, bank_details.bank_name);
          commit(mutations.REMORTGAGE_SET_BANK_ACCOUNT_NAME, bank_details.account_name);
          commit(mutations.REMORTGAGE_SET_BANK_ACCOUNT_NUMBER, bank_details.account_number);
          commit(mutations.REMORTGAGE_SET_BANK_BACS_OR_CHAPS, bank_details.bacs_or_chaps);
          commit(mutations.REMORTGAGE_SET_BANK_SORT_CODE, bank_details.sort_code);
        } else {
          commit(mutations.REMORTGAGE_RESET_BANK_DETAILS);
        }

        const clientIds = response.data.client_ids || [];
        commit(mutations.REMORTGAGE_SET_CLIENT_IDS, clientIds);

        const occupierIds = response.data.occupier_ids || [];
        commit(mutations.REMORTGAGE_SET_OCCUPIER_IDS, occupierIds);

        const personsToBeAddedToTitleIds = response.data.persons_to_be_added_to_title_ids || [];
        commit(mutations.REMORTGAGE_SET_PERSONS_TO_BE_ADDED_TO_TITLE_IDS, personsToBeAddedToTitleIds);

        const personsToBeRemovedFromTitleIds = response.data.persons_to_be_removed_from_title_ids || [];
        commit(mutations.REMORTGAGE_SET_PERSONS_TO_BE_REMOVED_FROM_TITLE_IDS, personsToBeRemovedFromTitleIds);

        const anyToePersonChanges = Boolean(personsToBeAddedToTitleIds.length || personsToBeRemovedFromTitleIds.length);
        commit(mutations.REMORTGAGE_SET_ANY_TOE_PERSON_CHANGES, anyToePersonChanges);

        commit(mutations.REMORTGAGE_LOADED);

        resolve();
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
