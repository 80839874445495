export const BUILDING_PREFIX = 'building/';

export const BUILDING_SET_ID = 'BUILDING_SET_ID';
export const BUILDING_SET_ADDRESS = 'BUILDING_SET_ADDRESS';
export const BUILDING_LOADED = 'BUILDING_LOADED';

export const mutations = {
  [BUILDING_SET_ID](state, value) {
    state.buildingId = value;
  },
  [BUILDING_SET_ADDRESS](state, value) {
    state.address = value;
  },
  [BUILDING_LOADED](state) {
    state.loaded = true;
  },
};
