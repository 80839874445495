import { DEFAULT_AUTH_STATE } from './module';

export const AUTH_SET_EMAIL = 'AUTH_SET_EMAIL';
export const AUTH_SET_USER_DETAILS = 'AUTH_SET_USER_DETAILS';
export const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN';
export const AUTH_SET_MASQUERADED_COGNITO_ID = 'AUTH_SET_MASQUERADED_COGNITO_ID';
export const AUTH_SET_MASQUERADED_DISPLAY_NAME = 'AUTH_SET_MASQUERADED_DISPLAY_NAME';
export const AUTH_SET_IS_DEVELOPER = 'AUTH_SET_IS_DEVELOPER';
export const AUTH_SET_IS_ADMIN = 'AUTH_SET_IS_ADMIN';
export const AUTH_SET_COGNITO_USER_ID = 'AUTH_SET_COGNITO_USER_ID';
export const AUTH_SET_COGNITO_USER = 'AUTH_SET_COGNITO_USER';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export const mutations = {
  [AUTH_SET_EMAIL](state, displayName) {
    state.displayName = displayName;
  },
  [AUTH_SET_USER_DETAILS](state, details) {
    state.userDetails = details;
  },
  [AUTH_SET_LOGGED_IN](state, value) {
    state.loggedIn = value;
  },
  [AUTH_SET_MASQUERADED_COGNITO_ID](state, value) {
    state.masqueradedCognitoId = value;
  },
  [AUTH_SET_MASQUERADED_DISPLAY_NAME](state, value) {
    state.masqueradedDisplayName = value;
  },
  [AUTH_SET_IS_DEVELOPER](state, value) {
    state.isDeveloper = value;
  },
  [AUTH_SET_IS_ADMIN](state, value) {
    state.isAdmin = value;
  },
  [AUTH_SET_COGNITO_USER_ID](state, value) {
    state.cognitoUserId = value;
  },
  [AUTH_SET_COGNITO_USER](state, value) {
    state.user = value;
    const rolePermissions = (value?.roles ?? []).flatMap(({ permissions }) => permissions);
    state.permissions = [...rolePermissions, ...(value?.permissions ?? [])];
  },
  [AUTH_LOG_OUT](state) {
    Object.keys(DEFAULT_AUTH_STATE).forEach((k) => {
      state[k] = DEFAULT_AUTH_STATE[k];
    });
  },
};
