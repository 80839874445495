import * as mutations from '@/store/leaseholdAlterations/mutations';
import { updateLeaseholdAlterations } from '@/store/leaseholdAlterations/actions';

export default {
  namespaced: true,
  state: {
    alterationsSinceLeaseGranted: null,
    alterationsSinceLeaseGrantedDescription: null,
    consentObtained: null,
    consentObtainedDocument: null,
    loaded: false,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    update: ({ commit }, disputesData) => updateLeaseholdAlterations(commit, disputesData),
  },
};
