import * as mutations from '@/store/services/mutations';

export const SERVICES_UPDATE = 'services/update';

export const updateServices = (commit, servicesData) =>
  new Promise((resolve) => {
    if (servicesData) {
      commit(mutations.SERVICES_SET_ELECTRICS_TESTED, servicesData.electrics_tested);
      commit(mutations.SERVICES_SET_ELECTRICS_TESTED_YEAR, servicesData.electrics_tested_year);
      commit(mutations.SERVICES_SET_ELECTRICS_TESTED_CERTIFICATE, servicesData.electrics_tested_certificate);
      commit(mutations.SERVICES_SET_ELECTRICS_REWIRED, servicesData.electrics_rewired);
      commit(mutations.SERVICES_SET_ELECTRICAL_SAFETY_CERTIFICATE, servicesData.electrical_safety_certificate);
      commit(mutations.SERVICES_SET_COMPLIANCE_CERTIFICATE, servicesData.compliance_certificate);
      commit(mutations.SERVICES_SET_COMPLETION_CERTIFICATE, servicesData.completion_certificate);
      commit(mutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM, servicesData.central_heating_system);
      commit(mutations.SERVICES_SET_CENTRAL_HEATING_SYSTEM_TYPE, servicesData.central_heating_system_type);
      commit(mutations.SERVICES_SET_CENTRAL_HEATING_INSTALLATION_DATE, servicesData.central_heating_installation_date);
      commit(mutations.SERVICES_SET_SEPTIC_TANK_REPLACED, servicesData.septic_tank_replaced);
      commit(
        mutations.SERVICES_SET_CENTRAL_HEATING_BEFORE_SPECIFIED_DATE,
        servicesData.central_heating_before_specified_date,
      );
      commit(mutations.SERVICES_SET_HEATING_SYSTEM_IN_WORKING_ORDER, servicesData.heating_system_in_working_order);
      commit(mutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_YEAR, servicesData.heating_system_serviced_year);
      commit(
        mutations.SERVICES_SET_HEATING_SYSTEM_SERVICED_INSPECTION_REPORT,
        servicesData.heating_system_serviced_inspection_report,
      );
      commit(mutations.SERVICES_SET_FOUL_WATER_DRAINAGE, servicesData.foul_water_drainage);
      commit(mutations.SERVICES_SET_SURFACE_WATER_DRAINAGE, servicesData.surface_water_drainage);
      commit(mutations.SERVICES_SET_SEWERAGE_SEPTIC_TANK, servicesData.sewerage_septic_tank);
      commit(mutations.SERVICES_SET_SEWERAGE_TREATMENT_PLANT, servicesData.sewerage_treatment_plant);
      commit(mutations.SERVICES_SET_SEWERAGE_CESSPOOL, servicesData.sewerage_cesspool);
      commit(mutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED, servicesData.sewerage_system_shared);
      commit(mutations.SERVICES_SET_SEWERAGE_SYSTEM_SHARED_NUMBER, servicesData.sewerage_system_shared_number);
      commit(mutations.SERVICES_SET_SEWERAGE_SYSTEM_LAST_EMPTIED, servicesData.sewerage_system_last_emptied);
      commit(mutations.SERVICES_SET_SEWERAGE_SYSTEM_SERVICED_YEAR, servicesData.sewerage_system_serviced_year);
      commit(mutations.SERVICES_SET_SEWERAGE_SYSTEM_INSTALLED_YEAR, servicesData.sewerage_system_installed_year);
      commit(
        mutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY,
        servicesData.sewerage_system_outside_of_property,
      );
      commit(
        mutations.SERVICES_SET_SEWERAGE_SYSTEM_OUTSIDE_OF_PROPERTY_DOCUMENTS,
        servicesData.sewerage_system_outside_of_property_documents,
      );
    }

    resolve();
  });
