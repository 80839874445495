import * as mutations from '@/store/auth/mutations';
import { userLoggedIn, userLoggedOut } from '@/store/auth/actions';

export const DEFAULT_AUTH_STATE = {
  displayName: null,
  userDetails: null,
  loggedIn: false,
  masqueradedCognitoId: null,
  masqueradedDisplayName: null,
  isDeveloper: false,
  isAdmin: false,
  cognitoUserId: null,
  user: null,
  permissions: [],
};

export default {
  namespaced: true,
  state: DEFAULT_AUTH_STATE,
  getters: {
    loggedIn: (state) => state.loggedIn,
    displayName: (state) => state.masqueradedDisplayName ?? state.displayName,
    username: (state) => {
      if (state.userDetails !== null) {
        return state.userDetails.username;
      }
      return '';
    },
    isMasqueraded: (state) => !!state.masqueradedDisplayName,
    isDeveloper: (state) => state.isDeveloper,
    isAdmin: (state) => state.isAdmin,
    hasPermission: (state) => (permission) => {
      if (typeof permission === 'string') {
        return state.permissions.includes(permission);
      }
      return state.permissions.find((v) => permission.test(v)) !== undefined;
    },
    cognitoUserId: (state) => state.masqueradedCognitoId ?? state.cognitoUserId,
  },
  mutations: {
    ...mutations.mutations,
  },
  actions: {
    userLoggedIn: ({ commit }, auth) => userLoggedIn(commit, auth),
    userLoggedOut: ({ commit }) => userLoggedOut(commit),
    setMasqueradedCognitoId: ({ commit }, id) => commit(mutations.AUTH_SET_MASQUERADED_COGNITO_ID, id),
    setMasqueradedDisplayName: ({ commit }, name) => commit(mutations.AUTH_SET_MASQUERADED_DISPLAY_NAME, name),
  },
};
