import axios from 'axios';
import Logger from '@/functions/logger';
import Auth from './amplify';
import { request, requestError } from './awsAxiosInterceptor';
import forbiddenInterceptor from './forbiddenInterceptor';

export default {
  install: (Vue) => {
    Vue.prototype.$auth = Auth;
    Vue.prototype.$logger = new Logger();
    axios.interceptors.request.use(request, requestError);
    axios.interceptors.response.use((v) => v, forbiddenInterceptor);
  },
};
